import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.alarm');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-microbeadmin-alarm',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.alarm/view.scss */
.page-header {
  margin: 0 auto !important;
  max-width: 1187px;
  width: 95%;
}
.page-header .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 280px;
  margin-bottom: 0;
}
.page-header .btn {
  font: normal normal normal 14px/18px SUIT;
  border-radius: 7px;
  height: 48px;
  padding: 1.5rem;
}

.container {
  max-width: 1187px;
  height: 85%;
  overflow: auto;
  background: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  opacity: 0.9;
}

.card {
  border-left: none;
  border-right: none;
  border-radius: 0;
  max-width: 1127px;
}

.day-box {
  border: 1px solid #D1D1D1;
  border-radius: 7px;
  margin-bottom: 10px;
  width: 1127px;
  justify-content: space-between;
  height: 72px;
  padding: 1rem;
  font: normal normal normal 16px/20px SUIT;
}

h3 {
  border-bottom: 1px solid #D1D1D1;
  font: normal normal bold 14px/18px SUIT;
  color: #ADADAD;
  width: 1127px;
  height: 30px;
}

.alarm-box {
  gap: 1rem;
  align-items: center;
}
.alarm-box .icon {
  background: #2547F4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 10px;
}`],
})
export class PortalMicrobeadminAlarmComponent implements OnInit {
    public search: any = {
        page: 1,
        text: ''
    };
    public pagenation: any = {
        end: -1,
        start: -1
    };

    public locations = ['외부', '완충 공간 1', '완충 공간 2', '완충 공간 3', '연도', '묘실']
    public list = [];
    public selectedValue: string | null = null;
    public objectKeys = Object.keys;
    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/main");
        await this.load();
        await this.groupDataByDate();
    }

    public async load(page = 1) {
        const { code, data } = await wiz.call("load", { page: page, text: this.search.text });
        if (code !== 200) {
            await this.alert("로드 실패! 시스템 관리자에게 문의하세요.");
            return;
        }
        this.data = data;
        console.log(this.data)
        const { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public selectValue(status: string, role: string, index: number): void {
        this.list[index].status = status;
        this.list[index].role = role;
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async groupDataByDate() {
        const grouped = this.data.reduce((acc, item) => {
            const date = Math.floor(item.date / 10000);
            const dateKey = date.toString();
            if (!acc[dateKey]) {
                acc[dateKey] = { date: new Date(date.toString().replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3')), items: [] };
            }
            acc[dateKey].items.push(item);
            return acc;
        }, {});

        this.groupedData = Object.values(grouped).sort((a, b) => b.date.getTime() - a.date.getTime());


        console.log(this.groupedData, '데이타')
        await this.service.render();
    }

    public async getAlertText(item: any): string {
        let status = 'green';  // 기본 상태는 'green' (안전)

        // Temperature1부터 Temperature6까지 확인
        for (let i = 1; i <= 6; i++) {
            const temp = item[`Temperature${i}`];
            console.log(item[`Temperature${i}`])
            if (temp === 'red') {
                status = 'red';  // 위험 상태가 하나라도 있으면 'red'
                break;  // 위험이 가장 우선이므로 바로 반환
            } else if (temp === 'yellow' && status !== 'red') {
                status = 'yellow';  // 경고 상태가 있으면 'yellow'
            }
        }

        // 최종 상태에 따라 텍스트 반환
        if (status === 'green') {
            return '안전';
        } else if (status === 'yellow') {
            return '경고';
        } else if (status === 'red') {
            return '위험';
        }

        return '';
    }

    public async changeAdmin(row) {
        console.log(this.service.auth.session);
        if (this.service.auth.session.id === row.id) {
            await this.alert("본인의 권한은 수정할 수 없습니다.");
            return;
        }
        const text = row.role === "admin" ? "관리자 -> 유저" : "유저 -> 관리자";
        const res = await this.alert(`"${row.name}"의 권한을 ${text} 로 변경하시겠습니까?`, {
            cancel: "취소",
        });
        if (!res) return;
        const { code } = await wiz.call("change_admin", {
            user_id: row.id,
            role: row.role,
        });
        if (code !== 200) {
            await this.alert("변경 실패! 시스템 관리자에게 문의하세요.");
            return;
        }
        await this.alert(`"${row.name}"의 권한을 변경하였습니다.`);
        await this.load();
    }
}

export default PortalMicrobeadminAlarmComponent;