import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.admin');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-nav-admin',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.nav.admin/view.scss */
.navbar-vertical {
  overflow: auto;
  position: relative;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .navbar-vertical {
    padding: 0;
  }
  .navbar-vertical .navbar-collapse {
    padding: 12px 16px;
  }
}

li.nav-item.active .nav-link {
  color: var(--wc-blue) !important;
  font-weight: 1000;
}
li.nav-item.active i {
  color: var(--wc-blue);
}
li.nav-item.active::after {
  border-left: none;
}

.li.nav-item .nav-link {
  font: normal normal medium 18px/24px SUIT;
}

.dropdown-menu .dropdown-item {
  padding-left: 2rem !important;
  font: normal normal normal 16px/24px SUIT;
  color: #707070;
}
.dropdown-menu .dropdown-item.active {
  padding-left: 2rem !important;
  font: normal normal normal 16px/24px SUIT;
  color: #000000;
  background: #EEF7FF !important;
}

.navbar-brand-image {
  width: 200px;
  margin-top: 16px;
  margin-bottom: 4px;
}
@media screen and (max-width: 768px) {
  .navbar-brand-image {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 8px;
    height: 36px;
  }
}

.nav-item {
  padding: 12px 0;
}
.nav-item:first-child {
  border-top: 1px solid var(--wc-border);
}
@media screen and (max-width: 768px) {
  .nav-item:last-child {
    border-bottom: none;
  }
}

.badge {
  margin-bottom: auto;
  width: 4px;
  height: 4px;
}`],
})
export class ComponentNavAdminComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    public hasNotifications: boolean = false;

    public async ngOnInit() {
        await this.service.init();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }
}

export default ComponentNavAdminComponent;