import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.data.microbe.plus.info');
import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
    selector: 'wiz-portal-microbeadmin-data-microbe-plus-info',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.data.microbe.plus.info/view.scss */
@charset "UTF-8";
.card-header {
  font: normal normal bold 18px/23px SUIT;
  max-width: 1127px;
  width: 96%;
  margin: auto;
  padding-left: 0;
  border-bottom: none;
}

.area-round .form-control {
  border-radius: 10px;
  text-align: left;
  border: 1px solid #D1D1D1;
  background: #FFFFFF;
  width: 528px;
  height: 40px;
  margin-bottom: 0;
}

.image-container {
  position: relative;
  /* 부모 요소를 기준으로 절대 위치 지정 */
  display: inline-block;
  /* 이미지 크기에 맞게 컨테이너 크기 조정 */
}
.image-container img {
  display: block;
  /* 이미지 아래의 여백 제거 */
  width: 285px;
  height: 285px;
  /* 이미지 크기를 조정할 경우 이 값 조절 */
}
.image-container .btn-red {
  position: absolute;
  top: 10%;
  left: 88%;
  transform: translate(-50%, -50%);
  background-color: #EF2B2A;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 7px;
  font-size: 16px;
}

.col-lg-4 {
  justify-content: center;
}

.page-header {
  margin: 0 auto !important;
  max-width: 1187px;
  width: 95%;
}
.page-header .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 280px;
  margin-bottom: 0;
}
.page-header .btn {
  font: normal normal normal 14px/18px SUIT;
  border-radius: 7px;
  height: 48px;
  padding: 1.5rem;
}
.page-header .btn-cancel {
  background: #EF2B2A !important;
  font: normal normal normal 14px/18px SUIT;
  color: #FFFFFF;
  border: none;
}

.form-select {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D1D1D1;
  border-radius: 10px;
  width: 528px;
  height: 40px;
}

.card {
  width: 1187px;
  height: 83%;
  margin: auto;
  overflow: auto;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
}

.wiz-form {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  max-width: 1127px;
  margin: auto;
}
.wiz-form .wiz-form-label {
  background: #F4F4F4;
  font: normal normal medium 16px/20px SUIT;
  color: #707070;
}

.card-header {
  font: normal normal bold 18px/23px SUIT;
  max-width: 1127px;
  width: 96%;
  margin: auto;
  padding-left: 0;
  border-bottom: none;
}

.card-search {
  max-width: 1187px;
  width: 95%;
  height: 130px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  background: white;
  border-radius: 10px;
  border: none;
}
.card-search span {
  display: flex;
  align-items: center;
}
.card-search .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 786px;
  margin-bottom: 0;
}
.card-search .steps {
  width: 55%;
  margin: auto;
}
.card-search h2 {
  width: 30%;
  height: 100%;
}

.img-btn {
  width: 282px;
  height: 282px;
  background: #EEF7FF 0% 0% no-repeat padding-box;
  border: 1px dashed #2547F4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-btn .span-box {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.img-btn .plus-icon {
  text-align: center;
  font: normal normal bold 39px/49px SUIT;
  margin: auto;
  letter-spacing: 0px;
  color: #2547F4;
}
.img-btn .plus-text {
  text-align: center;
  font: normal normal bold 16px/20px SUIT;
  letter-spacing: 0px;
  color: #2547F4;
}
.img-btn .btn-container {
  display: none;
}
.img-btn:hover .btn-container {
  display: block;
}

.cover-image {
  width: 282px;
  height: 282px;
  background: #EEF7FF 0% 0% no-repeat padding-box;
  border: 1px dashed #2547F4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover-image .span-box {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.cover-image .plus-icon {
  text-align: center;
  font: normal normal bold 39px/49px SUIT;
  margin: auto;
  letter-spacing: 0px;
  color: #2547F4;
}
.cover-image .plus-text {
  text-align: center;
  font: normal normal bold 16px/20px SUIT;
  letter-spacing: 0px;
  color: #2547F4;
}
.cover-image .btn-container {
  display: none;
}
.cover-image:hover .btn-container {
  display: block;
}`],
})
export class PortalMicrobeadminDataMicrobePlusInfoComponent implements OnInit {
    public sidebar: any = null;
    public listloaded: boolean = false;
    public mcate = "전체";
    public isRed: boolean = false;
    public list: any = [];
    public selected_status = false;
    public cache: any = {};
    public allSelected: boolean = false;
    public selectedItems: Set<number> = new Set();
    public loading = true;
    public post_id = 'desc';
    public category = '';
    public data: any = {
        growth_desc: []
    };
    @Output() loadUpdate = new EventEmitter<boolean>();
    @Input() microbe = '';
    @Input() microbe_id = '';
    @Input() dataset_id = '';
    @Input() item_id = '';
    public microbe = {};
    public search: any = {
        page: 1,
        text: ''
    };
    public logdata = {};
    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public pictureFile: any = {};

    // public logdata = {
    //     microbe: this.microbe,
    //     microbe_id: this.microbe_id
    // };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();
    }

    public async alert(message: string, title: any = "", status: any = "success", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    private async searchText() {
        if (this.search.text.length === 0) {
            await this.alert("검색어를 입력해주세요.");

        }
        this.search.page = 1;
        await this.load();
    }

    public async loadChange() {
        this.loadUpdate.emit(true)
    }
    public async load() {
        this.listloaded = false;
        console.log(this.microbe_id, '마이크로브 아이디')
        console.log(this.item_id, '아이템 아이디')
        let { code, data } = await wiz.call("get", { id: this.item_id, microbe_id: this.microbe_id });
        if (code != 200) return;
        if (this.item_id != 'new') {
            let { item, microbe } = data;
            this.logdata = item;
            this.microbe = microbe;
        } else {
            this.microbe = data.microbe;
        }

        this.listloaded = true;
        await this.service.render();
    }

    public async sidebarClose() {
        this.sidebar = null;
        await this.service.render();
    }

    public async updateImage(item: string) {
        let img = await this.service.file.read({ type: 'image', accept: 'image/*', width: 512, quality: 1 });
        console.log(item, '아이템')
        if (item == 'picture_front') {
            this.logdata.picture_front = img;
        } else {
            this.logdata.picture_back = img;
        }

        await this.service.render();
    }
    public async updateImage(item: string) {
        let img = await this.service.file.read({ type: 'image', accept: 'image/*', width: 512, quality: 1 });
        console.log(item, '아이템')
        if (item == 'picture_front') {
            this.logdata.picture_front = img;
        } else {
            this.logdata.picture_back = img;
        }

        await this.service.render();
    }

    public async removeImage(item: string) {
        if (item == 'picture_front') {
            this.logdata.picture_front = '';
        } else {
            this.logdata.picture_back = '';
        }

        await this.service.render();
    }

    public async frontSelector(target: string) {
        let res = await this.service.alert.show({
            title: '',
            message: '특성 이미지를 변경하시겠습니까?',
            cancel: '닫기',
            actionBtn: 'warning',
            action: '확인',
            status: 'warning'
        });

        if (!res) return;

        let file = await this.service.file.select({ accept: '.png,.jpg,.jpeg' });

        this.pictureFile = file[0];
        let img = await this.service.file.resize(file[0], 960, 1)
        this.logdata[target] = img;

        await this.service.render();
    }
    public async delete() {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await wiz.call("delete", { id: this.item.id });
        await this.loadChange()
    }

    public async update() {
        let data = this.data;
        console.log(data);
        const { code } = await wiz.call("update", { data: JSON.stringify(data) });
        if (code == 200) {
            await this.alert("저장되었습니다", "", 'success');
            await this.loadChange();
            // await this.load(this.search.page);
        } else {
            await this.alert("저장 중 오류가 발생했습니다.");
        }
        await this.service.render();
    }


}

export default PortalMicrobeadminDataMicrobePlusInfoComponent;