import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.data.name');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
    selector: 'wiz-portal-microbeadmin-data-name',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.data.name/view.scss */
li .d-flex {
  align-items: center;
}

.container {
  max-width: 1187px;
  width: 95%;
  height: 68%;
  overflow: auto;
  padding: 0;
}

.card-container {
  margin-top: 0;
  padding: 0;
  width: auto;
}

.card {
  width: 1187px;
  height: 83%;
  margin: auto;
  overflow: auto;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
}

.steps {
  width: 55%;
  margin: auto;
}

.btn-blue {
  width: 77px;
  height: 164px;
  margin-top: auto;
  border-radius: 7px;
}

select {
  width: 7%;
  height: 41px;
  box-shadow: none;
  border-radius: 10px !important;
  font: normal normal normal 16px/18px NanumSquareOTF;
  text-align: left !important;
}

select.form-select {
  width: 8%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background: url("/assets/main/down.png") no-repeat right 12px center;
  background-size: 14px;
  padding-right: 30px;
  border: none;
}

.dropdown-btn .dropdown-toggle {
  border-radius: 7px;
  color: #707070;
  box-shadow: none;
  border: none;
  background: none;
  font: normal normal bold 16px/20px SUIT;
}
.dropdown-btn .dropdown-menu {
  width: 91px;
  min-width: 0;
}
.dropdown-btn .dropdown-item {
  min-width: 0;
}
.dropdown-btn ::after {
  display: none;
}

.cover-image {
  height: 240px;
  background: #666 no-repeat center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover-image .btn-container {
  display: none;
}
.cover-image:hover .btn-container {
  display: block;
}

.v-line {
  border-left: thick solid #32a1ce;
  height: 18px;
}

.dropdown .dropdown-toggle {
  padding-left: 0.5rem;
  border-radius: 7px;
  height: 48px;
  width: 343px;
  color: #707070;
  justify-content: space-between;
  border: 1px solid #C7C7C7;
  font: normal normal bold 16px/20px SUIT;
}
.dropdown .dropdown-menu {
  width: 100%;
  min-width: 8%;
  transform: translate3d(455.5px, 202px, 0px);
}
.dropdown .dropdown-item {
  min-width: 0;
}

.dropdown1 .dropdown-toggle {
  height: 48px;
  width: 343px;
  border: 1px solid #C7C7C7;
}

.dropdown2 .dropdown-toggle {
  width: 138px;
  height: 48px;
  border: 1px solid #C7C7C7;
}

.blue-color {
  color: #2547F4;
}

.dropdown3 .dropdown-toggle {
  padding-left: 0.5rem;
  padding-bottom: 0;
  border-radius: 7px;
  border: none;
  background: none;
  box-shadow: none;
  font: normal normal normal 16px/18px SUIT;
  gap: 15px;
}
.dropdown3 .dropdown-menu {
  width: 80px;
  min-width: 0;
}
.dropdown3 .dropdown-item {
  min-width: 0;
}
.dropdown3 ::after {
  margin-bottom: 0.4rem;
}

.text-secondary {
  padding: 0.5rem;
}

.rows {
  width: 91%;
}

.row-search {
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  margin: 0 auto;
  height: 50px;
  display: flex;
  width: 95%;
  max-width: 1187px;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal normal 16px/18px SUIT;
}

.search-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 99%;
}
@media (max-width: 1200px) {
  .search-box {
    width: 97%;
  }
}

.page-header {
  margin: 0 auto !important;
  max-width: 1187px;
  width: 95%;
}
.page-header .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 280px;
  margin-bottom: 0;
}
.page-header .btn {
  font: normal normal normal 14px/18px SUIT;
  border-radius: 7px;
  height: 48px;
  padding: 1.5rem;
}

.wiz-form {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  max-width: 1127px;
  margin: auto;
}
.wiz-form .wiz-form-label {
  background: #F4F4F4;
  font: normal normal medium 16px/20px SUIT;
  color: #707070;
}

.blue-border {
  border: 2px solid #2547F4;
  border-radius: 20px;
}

.card-header {
  font: normal normal bold 18px/23px SUIT;
  max-width: 1127px;
  width: 96%;
  margin: auto;
  padding-left: 0;
  border-bottom: none;
}

.card-search {
  max-width: 1187px;
  width: 95%;
  height: 263px;
  margin: 0 auto;
  align-items: center;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  background: white;
  border-radius: 10px;
  border: none;
}
.card-search span {
  display: flex;
  align-items: center;
}
.card-search .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 786px;
  margin-bottom: 0;
}
.card-search h2 {
  width: 30%;
  height: 100%;
}

.card-link {
  width: 274px;
  height: 398px;
  border-radius: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
  cursor: pointer;
}
.card-link .card-body {
  height: 126px;
  text-align: left;
  padding: 0.5rem;
}
.card-link .card-body .card-title {
  font: normal normal bold 18px/21px SUIT;
  padding-left: 0.5rem;
  margin-bottom: 0;
}
.card-link .check-box {
  height: 0px;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}
.card-link .check-box img {
  filter: invert(33%) sepia(28%) saturate(6221%) hue-rotate(226deg) brightness(104%) contrast(101%);
}
.card-link .img-box {
  width: 33px;
  height: 32px;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  background: #FFFFFF;
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1490196078);
}
.card-link .empty-box {
  height: 0px;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}
.card-link .empty-box img {
  filter: invert(44%) sepia(3%) saturate(0%) hue-rotate(65deg) brightness(91%) contrast(91%);
}

.red-color {
  color: #EF2B2A;
}

.red-color-img {
  filter: invert(25%) sepia(99%) saturate(1681%) hue-rotate(347deg) brightness(109%) contrast(88%);
}

.text-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.btn-detail {
  border: none;
  background: none;
}

.btn-detail img {
  transition: filter 0.3s ease;
}

.btn-detail img.red-color {
  filter: invert(46%) sepia(94%) saturate(5996%) hue-rotate(349deg) brightness(107%) contrast(89%);
}

.row-user {
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  margin: 0 auto;
  border-bottom: 1px solid #D1D1D1;
  height: 50px;
  display: flex;
  width: 95%;
  max-width: 1187px;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal normal 16px/18px SUIT;
}
.row-user .btn-delete {
  width: 83px;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background: none;
  border: none;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  box-shadow: none;
}
.row-user .btn-delete:hover {
  background: none;
}

.row-cards {
  max-width: 1187px;
  margin: 0 auto;
  gap: 1.5rem;
}

.card-covered {
  height: 272px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #E4E4E4 no-repeat center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
.card-covered span {
  word-break: keep-all;
  font: normal normal normal 18px/33px SUIT;
  color: #939393;
  padding-top: 1rem;
}

.card-table .table {
  position: absolute;
}
.card-table .table th {
  font-size: 14px;
  background: #EFF4FE;
  padding: 8px 12px;
}
.card-table .table thead {
  position: sticky;
  top: 0;
}
.card-table .table tbody {
  background: #fff;
}
.card-table .table tbody tr {
  cursor: pointer;
}
.card-table .table tbody tr:hover {
  background: var(--wc-background);
}
.card-table .table tbody td {
  white-space: nowrap;
}
.card-table .table tbody .user-status-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
  line-height: 1;
  padding: 4px 0;
  font-size: 12px;
  border-radius: 10px;
  background: var(--wc-blue);
  color: #fff;
}
.card-table .table tbody .btn i {
  width: 16px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 640px;
  background: #fff;
  border-left: 1px solid var(--wc-border);
  overflow: auto;
}
.sidebar .card {
  border-radius: 0;
  border: none;
}
.sidebar .card > * {
  border-radius: 0;
}

.menu-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px;
}

.box {
  display: flex;
  position: relative;
  margin: 0px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
}
.box span {
  margin-top: 10px;
}
.box h3 {
  font: normal normal bold 18px/22px Montserrat;
  margin: 0;
  padding: 24px 17px;
}
.box button {
  padding: 0px;
  width: 100px;
  height: 60px;
  border: none;
  border-radius: 15px;
}
.box button.active {
  background-color: #2547F4;
  color: white;
  cursor: pointer;
  border-radius: 15px;
}`],
})
export class PortalMicrobeadminDataNameComponent implements OnInit {
    public sidebar = '';
    public listloaded: boolean = false;
    public mcate = "전체";
    public sort1 = [];
    public sorted_list = [];
    public isRed: boolean = false;
    public isBlue: boolean = false;
    public isSelect: boolean = false;
    public list: any = [];
    public selected_status = false;
    public cache: any = {};
    public allSelected: boolean = false;
    public selectedItems: Set<number> = new Set();
    public loading = true;
    public post_id = '';
    public plus_id = '';
    public category = '';
    private mode = "default";
    public cate_rows = {};
    public data: any = {
        growth_desc: []
    };
    public cateDrops = ['문(Ascomycota)', '강(Class)', '목(Order)', '과(Family)', '속(Genus)', '종(Species)']
    public microbeEvent: any = {};
    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    categories = {
        cate1: [] as string[],
        cate2: [] as string[],
        cate3: [] as string[],
        cate4: [] as string[],
        cate5: [] as string[],
        cate6: [] as string[]
    };

    selected = {
        cate1: null as string | null,
        cate2: null as string | null,
        cate3: null as string | null,
        cate4: null as string | null,
        cate5: null as string | null,
        cate6: null as string | null
    };


    public pictureFile: any = {};

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();

        this.microbeEvent.selected = this.microbeSelected.bind(this);
    }
    public async microbeSelected(item: any) {
        this.logdata = {};
        this.logdata.microbe = item;
        this.logdata.microbe_id = item.id;
        this.logdata.dataset_id = this.dataset;
        this.logdata.type_location = [];
        this.logdata.type_innerlocation = [];
        this.sidebar = 'log';
        await this.service.render();
    }
    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    private async searchText() {
        // if (this.search.text.length === 0) {
        //     await this.alert("검색어를 입력해주세요.");

        // }
        this.search.page = 1;
        await this.load();
    }

    public async excelCall() {
        console.log('thi')
        let select_list = [];
        for (const index of this.selectedItems) {
            if (index >= 0 && index < this.list.length) {
                const id = this.list[index].id;
                select_list.push(id)
            }
        }
        if (select_list.length) {
            await this.excelDownload();

        } else {
            await this.alert('추출할 파일을 선택해주세요');
        }

        this.selectedItems.clear();
    }

    public async excelDownload() {
        const filteredData = this.list.filter((_, index) => this.selectedItems.has(index));
        // 워크북 생성
        console.log(this.list, '디스 데이타')
        console.log(this.filteredData, '필터 데이타')
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        // 워크북에 시트 추가
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // 엑셀 파일 생성 및 다운로드
        const wbout: ArrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });
        saveAs(blob, 'data.xlsx');
    }
    public async loadChange(loading: boolean) {
        this.loading = loading;
        this.sidebar = '';
        console.log(this.loading, '디스 로딩')
        await this.load();
    }

    public async load() {
        this.listloaded = false;
        console.log(this.selected, '셀렉티드 로드')
        let { code, data } = await wiz.call("load", { id: this.item, page: this.search.page, text: this.search.text, mcate: this.mcate, selected: JSON.stringify(this.selected), mode: this.mode });
        if (code != 200) return;
        let { rows, lastpage, sort1, cate_list } = data;
        this.sort1 = sort1.sort();
        this.sorted_list = cate_list;
        console.log(this.cate_list)
        const startpage = Math.floor((this.search.page - 1) / 10) * 10 + 1;
        this.list = rows;
        console.log(this.list, '리스트 올')
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.listloaded = true;
        this.initializeCategories();
        // this.setInitialValues();
        await this.service.render();
    }

    public async pageMove(page: number) {
        this.search.page = page;
        await this.load();
    }
    public async plusDetail(id: string) {
        this.plus_id = id;
        console.log(this.post_id, '포스트 아이디')
        this.loading = !this.loading;
    }
    public async detail(id: string) {
        this.post_id = id;
        this.loading = !this.loading;
        await this.service.render();
    }

    // public onCategoryChange(level: number): void {
    //     // 선택된 카테고리 값에 따라 필터링
    //     console.log(level, '카테고리 레벨')
    //     this.updateCategories(level);
    // }

    public onCategoryChange(cate: number, sort: string) {
        console.log(cate, '카테')
        console.log(sort, '소트')
        console.log(this.selected['cate'])
        this.selected['cate' + cate] = sort; // 선택한 값 업데이트
        this.updateCategories(cate);
        // 추가적인 로직이 필요하면 여기서 처리
    }

    private clearSelectionsBelow(level: number): void {
        const categoryKeys = ['cate1', 'cate2', 'cate3', 'cate4', 'cate5', 'cate6'];
        for (let i = level; i < categoryKeys.length; i++) {
            this.selected[categoryKeys[i]] = null;
        }
    }
    private async initializeCategories(): void {
        // 첫 번째 카테고리 데이터 설정
        this.categories.cate1 = [...this.sort1];
        console.log(this.categories.cate1, '카테1')
        this.updateCategories();
    }

    private setInitialValues(): void {
        // selected 값을 data의 값으로 초기화
        this.selected.cate1 = this.data.a_cate_1;
        this.selected.cate2 = this.data.a_cate_2;
        this.selected.cate3 = this.data.a_cate_3;
        this.selected.cate4 = this.data.a_cate_4;
        this.selected.cate5 = this.data.a_cate_5;
        this.selected.cate6 = this.data.a_cate_6;
    }


    private updateCategories(level: number): void {
        // 각 카테고리 레벨을 필터링하여 업데이트
        let filteredData = this.sorted_list;
        console.log(this.data, '디스 데이타')
        console.log(this.selected, '셀렉티드')
        // 선택된 카테고리 값에 따라 필터링
        for (let i = 1; i <= level; i++) {
            const selectedValue = this.selected[`cate${i}`];
            if (selectedValue) {
                filteredData = filteredData.filter(item => item[`a_cate_${i}`] === selectedValue);
            }
        }

        // 현재 레벨과 그 이후의 카테고리 업데이트
        for (let i = level + 1; i <= 6; i++) {
            this.categories[`cate${i}`] = this.getUniqueValues(filteredData, `a_cate_${i}`);
            console.log(this.categories, '카테고리 전체')
            // 선택된 값이 없으면 초기화
            if (i > level) {
                this.selected[`cate${i}`] = null;
            }
        }
        this.service.render();
    }
    private getUniqueValues(data: any[], category: string): string[] {
        if (!Array.isArray(data)) {
            console.error(`Data provided is not an array:`, data);
            return [];
        }
        return [...new Set(data.map(item => item[category]).filter(value => value !== null && value !== '-'))];
    }

    public async addLog() {
        this.sidebar = 'microbe';
        this.loading = false;
        await this.service.render();
    }

    public async sidebarClose() {
        this.sidebar = null;
        await this.service.render();
    }

    public onDropdownClick(event: MouseEvent) {
        event.stopPropagation();
    }

    public async create() {
        this.data = { growth_desc: [] };
        this.sidebar = 'new';
        await this.service.render();
    }
    public fileChange() {
        this.isSelect = !this.isSelect;
        this.isBlue = !this.isBlue;
    }
    public async toggleColor() {
        this.isRed = !this.isRed;
        console.log(this.isRed, '이스레드')
        this.isSelect = !this.isSelect;
        console.log(this.isSelect, '이스셀렉트')
    }

    public async addGrowth(growth_desc: any) {
        if (this.data.growth_desc.includes(growth_desc)) return;
        this.data.growth_desc.push(growth_desc);
        this.cache.growth_desc = "";
        await this.service.render();
    }

    public async removeGrowth(growth_desc: any) {
        this.data.growth_desc.remove(growth_desc);
        await this.service.render();
    }

    public async selectValue(category: string): void {
        this.mcate = category;
        // this.search.page = 1;
        // await this.load()
    }


    public async update() {
        let data = this.data;
        console.log(data);
        const { code } = await wiz.call("update", { data: JSON.stringify(data) });
        if (code == 200) {
            await this.alert("저장되었습니다", 'success');
            await this.sidebarClose();
            await this.load(this.search.page);
        } else {
            await this.alert("저장 중 오류가 발생했습니다.");
        }
        await this.service.render();
    }

    private toggleSelection(index: number) {
        console.log(index, '인덱스 콘솔')
        console.log(this.selectedItems, '셀렉티드 아이템스')
        if (this.selectedItems.has(index)) {
            this.selectedItems.delete(index); // 이미 선택된 카드라면 상태를 해제
        } else {
            this.selectedItems.add(index); // 카드 선택
        }
    }

    public isSelected(index: number): boolean {
        return this.selectedItems.has(index); // 카드 선택 여부 확인
    }
    public cardSelect(index: number): void {
        this.toggleSelection(index);
    }

    public async updateFeatured() {
        let res = await this.service.alert.show({
            title: '',
            message: '특성 이미지를 변경하시겠습니까?',
            cancel: '닫기',
            actionBtn: 'warning',
            action: '확인',
            status: 'warning'
        });

        if (!res) return;

        let file = await this.service.file.select({ accept: '.png,.jpg,.jpeg' });
        this.pictureFile = file[0];
        let img = await this.service.file.resize(file[0], 960, 1)

        this.data.featured_image = img;
        await this.service.render();
    }

    public async deleteFeatured() {
        this.data.featured_image = '';
        this.pictureFile = {};
        await this.service.render();
    }

    public async upload(dataID) {
        let fd = new FormData();
        fd.append('file[]', this.pictureFile, this.pictureFile.name);
        fd.append("data", dataID);
        let url = wiz.url('upload');
        let { code, data } = await this.service.file.upload(url, fd);
        if (code != 200) return;
    }
    public async changeStatus() {
        this.selectedStatus = !this.selectedStatus;
    }


    public async delete(item: string) {
        event.preventDefault();

        if (item) {
            const confirmed = await this.alert('선택한 데이터를 삭제하시겠습니까?');
            if (!confirmed) {
                return;
            }
            const { code } = await wiz.call("delete", { select_item: item.id });
            if (code == 200) {
                await this.alert('삭제되었습니다.', 'success');
                await this.load();
            } else {
                await this.alert('삭제 중 오류가 발생했습니다.');
            }

        } else {
            let select_list = [];
            for (const index of this.selectedItems) {
                if (index >= 0 && index < this.list.length) {
                    const id = this.list[index].id;
                    select_list.push(id)
                }
            }
            if (select_list.length) {
                const confirmed = await this.alert('선택한 데이터를 삭제하시겠습니까?');
                if (!confirmed) {
                    this.selectedItems.clear();
                    return;
                }
                const { code } = await wiz.call("delete", { select_list: JSON.stringify(select_list) });

                if (code == 200) {
                    await this.alert('삭제되었습니다.', 'success');
                    await this.load();
                } else {
                    await this.alert('삭제 중 오류가 발생했습니다.');
                }

            }
            this.selectedItems.clear();
        }
    }
}

export default PortalMicrobeadminDataNameComponent;