import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.report');
import { OnInit, OnDestroy, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-report',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.admin.report/view.scss */
/* src/app/page.admin.report/view.scss: no such file or directory */`],
})
export class PageAdminReportComponent implements OnInit, OnDestroy, DoCheck {
    public ITEM: any;

    constructor(@Inject( Service) public service: Service) {
        this.ITEM = WizRoute.segment.item;
    }

    public async ngDoCheck() {
        if (this.ITEM != WizRoute.segment.item) {
            this.ITEM = WizRoute.segment.item;
            await this.service.render();
        }
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.service.render();
    }

}

export default PageAdminReportComponent;