import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.admin.enter.detail');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router } from '@angular/router';
import moment from "moment";

@Component({
    selector: 'wiz-portal-microbeadmin-admin-enter-detail',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.admin.enter.detail/view.scss */
.container {
  max-width: 1187px;
  height: 85%;
  background: white;
  overflow: auto;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  opacity: 0.9;
}

.table-card {
  border: none;
  border-radius: 0;
  max-width: 1127px;
}
.table-card tr {
  font: normal normal bold 14px/18px SUIT;
}

.table-card2 {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-left: none;
  border-right: none;
  border-radius: 0;
  max-width: 1127px;
}
.table-card2 tr {
  font: normal normal bold 14px/18px SUIT;
}

.dropdown .dropdown-toggle {
  border-radius: 7px;
  color: #707070;
  border: 1px solid #C7C7C7;
  font: normal normal bold 16px/20px SUIT;
}
.dropdown .dropdown-menu {
  max-width: 91px;
  min-width: 80%;
}
.dropdown .dropdown-item {
  min-width: 0;
}

.btn-files {
  width: 24px;
  height: 24px;
  background: #ECF5FF;
  border-radius: 3px;
  border: none;
  justify-content: center;
}
.btn-files img {
  width: 20px;
  max-width: none;
}

.date-time {
  width: 210px;
}

.check-box img {
  filter: invert(33%) sepia(28%) saturate(6221%) hue-rotate(226deg) brightness(104%) contrast(101%);
}

.empty-box img {
  filter: invert(44%) sepia(3%) saturate(0%) hue-rotate(65deg) brightness(91%) contrast(91%);
}

.btn-red {
  width: 32px;
  height: 32px;
  background: #EF2B2A;
  border-radius: 7px;
}

.btn-plus {
  background: #EEF7FF 0% 0% no-repeat padding-box;
  border: 1px dashed #2547F4;
  width: 1097px;
  height: 32px;
  border-radius: 7px;
}

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.d-block {
  height: 241px;
}

.file-list {
  display: flex;
  justify-content: flex-end;
}

li {
  background: #ECF5FF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  width: 511px;
  height: 32px;
  display: flex;
  align-items: center;
}
li a {
  padding-left: 0.5rem;
  color: #5263FF;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  font: normal normal normal 16px/20px SUIT;
  text-decoration: underline;
}
li .btn-remove-file {
  height: 30px !important;
  width: 30px !important;
  border: none;
  background-color: #ECF5FF;
  margin-right: 5px;
}

.btn-file {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  color: #6D6D6D;
  border: none;
  font: normal normal bold 18px/21px NanumSquareOTF;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-file img {
  filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(70deg) brightness(94%) contrast(88%);
}
.btn-file:hover {
  background: #5263FF;
  color: #fff;
}

#file {
  display: none;
}

.file-box {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  font: normal normal medium 18px/23px SUIT;
  color: #000000;
}
.file-box .btn-blue {
  border-radius: 10px;
  width: 94px;
  height: 40px;
  font: normal normal normal 16px/21px SUIT;
}

ngx-dropzone {
  width: 500px;
  height: 241px;
  background: #EEF7FF;
  border: 1px dashed #2547F4;
}
ngx-dropzone ngx-dropzone-label {
  font: normal normal medium 18px/23px SUIT !important;
}

.images-li a {
  color: #00C500;
}
.images-li .btn-remove-file {
  height: 30px !important;
  width: 30px !important;
  border: none;
  background: #E5FCE3;
  margin-right: 5px;
}

.file-card {
  display: flex;
  width: 1122px;
  height: 302px;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
}

.form-control {
  width: 100%;
  height: 32px;
  border-radius: 7px;
  font: normal normal bold 18px/24px SUIT;
}

.wiz-form {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  max-width: 1127px;
  margin: auto;
}
.wiz-form .wiz-form-label {
  background: #F4F4F4;
  font: normal normal medium 16px/20px SUIT;
  color: #707070;
}
.wiz-form .form-control {
  width: 477px;
  height: 32px;
  border-radius: 7px;
  font: normal normal normal 14px/18px SUIT;
}

.vertical {
  border: 0.5px solid #808080;
  height: 241px;
  margin-top: auto;
  margin-bottom: auto;
}

.row-user {
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  margin: 0 auto;
  margin-top: 1rem;
  height: 50px;
  display: flex;
  width: 100%;
  max-width: 1127px;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal normal 16px/18px SUIT;
}
.row-user .btn-delete {
  width: 83px;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background: none;
  border: none;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  box-shadow: none;
}
.row-user .btn-delete:hover {
  background: none;
}

.position-absolute {
  display: flex;
  align-items: center;
}

.btn-common.active {
  color: white;
  background-color: #6184D6;
}

td,
th {
  text-align: center;
}

.table-responsive {
  overflow-y: auto;
  border-radius: 0;
}

.page-header {
  margin: 0 auto !important;
  max-width: 1187px;
}
.page-header .btn {
  font: normal normal normal 14px/18px SUIT;
  border-radius: 7px;
  max-width: 77px;
  max-height: 48px;
  padding: 1.5rem;
}
.page-header .btn .btn-blue {
  background: #2547F4;
}
.page-header .btn-cancel {
  background: #EF2B2A !important;
  font: normal normal normal 14px/18px SUIT;
  color: #FFFFFF;
  border: none;
}

.table-last {
  max-width: 1127px;
  margin: 0 auto;
}
.table-last input {
  border: 1px solid #E2E2E2;
  border-radius: 7px;
  height: 32px;
  font: normal normal normal 16px/21px SUIT;
  color: #000000;
}
.table-last thead {
  text-align: left;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  height: 32px;
}
.table-last thead th {
  font: normal normal bold 14px/18px SUIT;
  background: none;
  vertical-align: middle;
  color: #707070;
}
.table-last tbody tr {
  width: 1127px;
  height: 72px;
  border-bottom: solid 0.5px #D1D1D1;
  vertical-align: middle;
}
.table-last tbody tr td {
  height: 30px;
  padding: 0.5rem;
  font: normal normal normal 16px/20px SUIT;
  color: #000000 !important;
}`],
})
export class PortalMicrobeadminAdminEnterDetailComponent implements OnInit {
    public search: any = {
        page: 1,
        text: ''
    };
    public pagenation: any = {
        end: -1,
        start: -1
    };
    public uploadedFiles = [];
    public uploadedImages = [];
    public item: any = {
        title: '',
        description: '',
        person: '',
        grade: '',
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
        inner_start: moment().format("YYYY-MM-DD"),
        inner_end: moment().format("YYYY-MM-DD"),
        permission: moment().format("YYYY-MM-DD"),
        images: [],
        files: [],
        person_list: []

    };
    public isSelect: boolean = false;
    public fd = new FormData();
    public selectedValue: string | null = null;
    public files = [];


    public newParticipant = {
        name: '',
        conference: '',
        department: '',
        check: "unchecked",
        grade_list: ''
    };
    public selectedItems: Set<number> = new Set();
    public participants = [
        // {
        //     id: "1",
        //     name: "홍길동",
        //     conference: "2023 학술 대회",
        //     department: "컴퓨터공학과",
        //     participation: "연구원",
        //     grade_list: "A"
        // },
        // {
        //     id: "2",
        //     name: "김철수",
        //     conference: "2023 학술 대회",
        //     department: "물리학과",
        //     participation: "주임",
        //     grade_list: "B"
        // },
        // {
        //     id: "3",
        //     name: "이영희",
        //     conference: "2023 학술 대회",
        //     department: "수학과",
        //     participation: "대리",
        //     grade_list: "A"
        // }
    ];

    @Input() post_id = '';

    constructor(@Inject( Service) public service: Service,@Inject( Router)  private router: Router) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/main");
        await this.load();
    }

    public async load(page = 1) {
        if (this.post_id != 'new') {
            const { code, data } = await wiz.call("get", { id: this.post_id });
            this.item = data;
            console.log(this.item, '아이템')
            this.item.permission = moment(this.item.permission).format("YYYY-MM");
            console.log(data.files, '아이템 데이트')
            this.item.files = JSON.parse(data.files.replace(/'/g, '"'));
            this.item.images = JSON.parse(data.images.replace(/'/g, '"'));
            this.item.person_list = JSON.parse(data.person_list.replace(/'/g, '"'));
            console.log(typeof (this.item.person_list))
            this.participants = this.item.person_list;
        }
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public async preview(item) {
        console.log(item, '아이템')
        if (!item.endsWith('.pdf')) return;
        let download = wiz.url('pdf?id=' + this.item.id + "&title=" + item)
        console.log(download)
        let res = await fetch(download, {
            method: "post",
        });

        const blob = await res.blob();
        const a = document.createElement('a');
        a.style.display = 'none';
        const download_url = window.URL.createObjectURL(blob);
        a.href = download_url;
        a.target = "_blank";

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(download_url);
    }


    public selectValue(status: string, role: string, index: number): void {
        this.list[index].status = status;
        this.list[index].role = role;
    }
    public toggleCheck(item: any): void {
        // check 값을 true 또는 false로 토글
        item.check = (item.check === 'checked') ? 'unchecked' : 'checked';
    }
    public async update() {
        if (this.item.title.length == 0) {
            await this.alert('제목을 입력해주세요');
            return
        }

        const [year, month] = this.item.permission.split('-');
        this.item.permission = `${year}-${month}-01`;

        this.item.files = [...new Set([...this.item.files, ...this.uploadedFiles])];
        this.uploadedFiles = [];

        this.item.images = [...new Set([...this.item.images, ...this.uploadedImages])];
        this.uploadedImages = [];

        this.item.person_list = this.participants;
        this.fd.append('data', JSON.stringify(this.item))

        let url = wiz.url('update')

        const { code, data } = await this.service.file.upload(url, this.fd);
        if (code == 200) {
            await this.alert('저장되었습니다', '', 'success', '확인');
        }

        this.router.navigateByUrl('/admin/enter')
    }

    public async fileUpload(e) {
        console.log(e.addedFiles, '이벤트')
        for (let i = 0; i < e.addedFiles.length; i++) {
            let file = e.addedFiles[i];
            if (!file.type.startsWith('image/')) {
                this.filename = file.name;
                if (!file.filepath) file.filepath = file.name;
                this.fd.append('file[]', file);  // files 배열에 추가
                this.uploadedFiles.push(file.filepath);
            } else {
                await this.alert('파일 확장자를 확인해주세요');
                // 사용자에게 경고 메시지 표시 (예: alert, toast 등)
            }
        }
        await this.service.render();
    }
    public async removeFile(index: number, isUploaded: boolean = false) {
        if (isUploaded) {
            this.uploadedFiles.splice(index, 1); // 업로드한 파일 삭제
        } else {
            this.item.files.splice(index, 1); // 저장된 파일 삭제
        }
    }
    public async deleteParticipant(i) {
        this.participants.splice(i, 1)
    }
    public async select() {
        isSelect = !isSelect;
    }
    private toggleSelection(index: number) {
        console.log(index, '인덱스 콘솔')
        console.log(this.selectedItems, '셀렉티드 아이템스')
        if (this.selectedItems.has(index)) {
            this.selectedItems.delete(index); // 이미 선택된 카드라면 상태를 해제
        } else {
            this.selectedItems.add(index); // 카드 선택
        }
    }

    public async addParticipant() {

        this.participants.push({ ...this.newParticipant });

        this.newParticipant = {
            name: '',
            conference: '',
            department: '',
            participation: '',
            grade_list: ''
        };
    }

    public isSelected(index: number): boolean {
        return this.selectedItems.has(index);
    }


    public async imgUpload(e) {
        console.log(e.addedFiles, '이미지 업로드 이벤트');
        for (let i = 0; i < e.addedFiles.length; i++) {
            let file = e.addedFiles[i];

            // 이미지 유형 검사
            if (file.type.startsWith('image/')) {
                this.filename = file.name;
                if (!file.filepath) file.filepath = file.name;
                this.fd.append('file[]', file);  // images 배열에 추가
                this.uploadedImages.push(file.filepath);
            } else {
                await this.alert('파일 확장자를 확인해주세요');
                // 사용자에게 경고 메시지 표시 (예: alert, toast 등)
            }
        }
        await this.service.render();
    }

    public async removeImages(index: number, isUploaded: boolean) {
        if (isUploaded) {
            this.uploadedFiles.splice(index, 1);
        } else {
            this.item.images.splice(index, 1);
        }
    }

    public async download(item, type) {
        if (!item[type] && !item[type + "x"]) return;
        if (await this.service.auth.allow(false)) {
            await this.alert("로그인 후 이용해주세요.");
            return 0;
        }
        type = item[type] ? type : type + "x";
        let download = wiz.url('download?id=' + item.id + "&title=" + item.title + "&type=" + type)
        window.open(download, '_blank');
    }

    public onRemove(event) {
        console.log(event);
        this.files.splice(this.files.indexOf(event), 1);
    }
    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    public async delete() {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await wiz.call("delete", { id: this.item.id });
        this.router.navigateByUrl('/admin/enter')
    }
}

export default PortalMicrobeadminAdminEnterDetailComponent;