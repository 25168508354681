import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.data.dataset.log');
import { OnInit, DoCheck, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from "moment";

@Component({
    selector: 'wiz-portal-microbeadmin-data-dataset-log',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.data.dataset.log/view.scss */
li .d-flex {
  align-items: center;
}

.container {
  max-width: 1187px;
  width: 95%;
  height: 80%;
  overflow: auto;
  padding: 0;
}

.card-container {
  margin-top: 0;
  padding: 0;
  width: auto;
}

.btn-blue {
  border-radius: 7px;
  width: 77px;
  height: 48px;
}

.col-lg-4 {
  justify-content: center;
}

.card-search2 {
  max-width: 1187px;
  width: 95%;
  height: 130px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  background: white;
  border-radius: 10px;
  border: none;
}
.card-search2 span {
  display: flex;
  align-items: center;
}
.card-search2 .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 786px;
  margin-bottom: 0;
}
.card-search2 .steps {
  width: 55%;
  margin: auto;
}
.card-search2 h2 {
  width: 30%;
  height: 100%;
}

.cover-image {
  width: 282px;
  height: 282px;
  background: #EEF7FF 0% 0% no-repeat padding-box;
  border: 1px dashed #2547F4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover-image .span-box {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.cover-image .plus-icon {
  text-align: center;
  font: normal normal bold 39px/49px SUIT;
  margin: auto;
  letter-spacing: 0px;
  color: #2547F4;
}
.cover-image .plus-text {
  text-align: center;
  font: normal normal bold 16px/20px SUIT;
  letter-spacing: 0px;
  color: #2547F4;
}
.cover-image .btn-container {
  display: none;
}
.cover-image:hover .btn-container {
  display: block;
}

.form-select2 {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D1D1D1;
  border-radius: 10px;
  width: 528px;
  height: 40px;
}

.card {
  width: 1187px;
  max-width: 96%;
  height: 83%;
  margin: auto;
  overflow: auto;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
}

.wiz-form {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  max-width: 1127px;
  margin: auto;
}
.wiz-form .wiz-form-label {
  background: #F4F4F4;
  font: normal normal medium 16px/20px SUIT;
  color: #707070;
}

.card-header {
  font: normal normal bold 18px/23px SUIT;
  max-width: 1127px;
  width: 96%;
  margin: auto;
  padding-left: 0;
  border-bottom: none;
}

.area-round .form-control {
  border-radius: 10px;
  text-align: left;
  border: 1px solid #D1D1D1;
  background: #FFFFFF;
  width: 528px;
  height: 40px;
  margin-bottom: 0;
}

select {
  width: 7%;
  height: 41px;
  box-shadow: none;
  border-radius: 10px !important;
  font: normal normal normal 16px/18px NanumSquareOTF;
  text-align: left !important;
}

select.form-select {
  width: 8%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background: url("/assets/main/down.png") no-repeat right 12px center;
  background-size: 14px;
  padding-right: 30px;
  border: none;
}

.v-line {
  border-left: thick solid #32a1ce;
  height: 18px;
}

.card-search {
  max-width: 1187px;
  width: 95%;
  height: 130px;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  background: white;
  border-radius: 10px;
  border: none;
}
.card-search span {
  display: flex;
  align-items: center;
}
.card-search .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 786px;
  margin-bottom: 0;
}
.card-search h2 {
  width: 30%;
  height: 100%;
}

.search-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 99%;
}
@media (max-width: 1200px) {
  .search-box {
    width: 97%;
  }
}

.dropdown1 .dropdown-toggle {
  padding-left: 0.5rem;
  border-radius: 7px;
  justify-content: space-between;
  height: 48px;
  width: 138px;
  color: #707070;
  border: 1px solid #C7C7C7;
  font: normal normal bold 16px/20px SUIT;
}
.dropdown1 .dropdown-menu {
  max-width: 91px;
  min-width: 8%;
  transform: translate3d(455.5px, 202px, 0px);
}
.dropdown1 .dropdown-item {
  min-width: 0;
}

.dropdown2 .dropdown-toggle {
  padding-left: 0.5rem;
  padding-bottom: 0;
  border-radius: 7px;
  border: none;
  background: none;
  box-shadow: none;
  font: normal normal normal 16px/18px SUIT;
  gap: 15px;
}
.dropdown2 .dropdown-menu {
  width: 80px;
  min-width: 0;
}
.dropdown2 .dropdown-item {
  min-width: 0;
}
.dropdown2 ::after {
  margin-bottom: 0.4rem;
}

.text-secondary {
  padding: 0.5rem;
}

.page-header {
  margin: 0 auto !important;
  max-width: 1187px;
  width: 95%;
}
.page-header .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 280px;
  margin-bottom: 0;
}
.page-header .btn {
  font: normal normal normal 14px/18px SUIT;
  border-radius: 7px;
  height: 48px;
  padding: 1.5rem;
}

.blue-border {
  border: 2px solid #2547F4;
  border-radius: 20px;
}

.card-link {
  width: 274px;
  height: 398px;
  border-radius: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
  cursor: pointer;
}
.card-link .card-body {
  height: 126px;
  text-align: left;
  padding: 0.5rem;
}
.card-link .card-body .card-title {
  font: normal normal bold 18px/21px SUIT;
  padding-left: 0.5rem;
  margin-bottom: 0;
}
.card-link .check-box {
  height: 0px;
  z-index: 10;
}
.card-link .check-box img {
  filter: invert(33%) sepia(28%) saturate(6221%) hue-rotate(226deg) brightness(104%) contrast(101%);
}
.card-link .img-box {
  width: 33px;
  height: 32px;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  background: #FFFFFF;
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1490196078);
}
.card-link .empty-box {
  height: 0px;
  z-index: 10;
}
.card-link .empty-box img {
  filter: invert(44%) sepia(3%) saturate(0%) hue-rotate(65deg) brightness(91%) contrast(91%);
}

.red-color {
  color: #EF2B2A;
}

.red-color-img {
  filter: invert(25%) sepia(99%) saturate(1681%) hue-rotate(347deg) brightness(109%) contrast(88%);
}

.text-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.btn-detail {
  border: none;
  background: none;
}

.btn-detail img {
  transition: filter 0.3s ease;
}

.btn-detail img.red-color {
  filter: invert(46%) sepia(94%) saturate(5996%) hue-rotate(349deg) brightness(107%) contrast(89%);
}

.row-search {
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  margin: 0 auto;
  height: 50px;
  display: flex;
  width: 95%;
  max-width: 1187px;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal normal 16px/18px SUIT;
}

.row-user {
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  margin: 0 auto;
  border-bottom: 1px solid #D1D1D1;
  height: 50px;
  display: flex;
  width: 95%;
  max-width: 1187px;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal normal 16px/18px SUIT;
}
.row-user .btn-delete {
  width: 83px;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background: none;
  border: none;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  box-shadow: none;
}
.row-user .btn-delete:hover {
  background: none;
}

.row-cards {
  max-width: 1187px;
  margin: 0 auto;
  gap: 1.5rem;
}

.card-covered {
  height: 272px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #E4E4E4 no-repeat center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card-covered span {
  word-break: keep-all;
  color: #7B7B7B;
  padding-top: 1rem;
}

.card-table .table {
  position: absolute;
}
.card-table .table th {
  font-size: 14px;
  background: #EFF4FE;
  padding: 8px 12px;
}
.card-table .table thead {
  position: sticky;
  top: 0;
}
.card-table .table tbody {
  background: #fff;
}
.card-table .table tbody tr {
  cursor: pointer;
}
.card-table .table tbody tr:hover {
  background: var(--wc-background);
}
.card-table .table tbody td {
  white-space: nowrap;
}
.card-table .table tbody .user-status-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
  line-height: 1;
  padding: 4px 0;
  font-size: 12px;
  border-radius: 10px;
  background: var(--wc-blue);
  color: #fff;
}
.card-table .table tbody .btn i {
  width: 16px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 640px;
  background: #fff;
  border-left: 1px solid var(--wc-border);
  overflow: auto;
}
.sidebar .card {
  border-radius: 0;
  border: none;
}
.sidebar .card > * {
  border-radius: 0;
}

.menu-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px;
}

.box {
  display: flex;
  position: relative;
  margin: 0px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
}
.box span {
  margin-top: 10px;
}
.box h3 {
  font: normal normal bold 18px/22px Montserrat;
  margin: 0;
  padding: 24px 17px;
}
.box button {
  padding: 0px;
  width: 100px;
  height: 60px;
  border: none;
  border-radius: 15px;
}
.box button.active {
  background-color: #2547F4;
  color: white;
  cursor: pointer;
  border-radius: 15px;
}`],
})
export class PortalMicrobeadminDataDatasetLogComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) { }

    public microbeEvent: any = {};

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.loadDataset();
        await this.load();

        this.microbeEvent.selected = this.microbeSelected.bind(this);
    }
    private mode = "default";
    @Input() dataset: string = '';
    public loading = true;
    public sidebar: any = null;
    public data: any = {};
    public cache: any = {};
    public mcate = "전체";
    public logdata: any = {};
    public sidebar = ''
    public listloaded: boolean = false;
    public list: any = [];
    public isRed: boolean = false;
    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public pictureFile: any = {};

    public async detail(id: string) {
        this.post_id = id;
        this.loading = !this.loading;
    }
    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    public async selectValue(category: string): void {
        this.mcate = category;
        this.search.page = 1;
        await this.load()
    }

    public async loadDataset() {
        this.loaded = false;
        let { code, data } = await wiz.call("dataset", { id: this.dataset });
        if (code != 200) return;
        this.data = data;
        this.loaded = true;
        await this.service.render();
    }

    public async editDataset() {
        this.sidebar = 'dataset';
        await this.service.render();
    }

    public async updateDataset() {
        let data = this.data;
        const { code } = await wiz.call("update", data);
        if (code == 200) {
            await this.alert("저장되었습니다", 'success');
            await this.sidebarClose();
            await this.load();
        } else {
            await this.alert("저장 중 오류가 발생했습니다.");
        }
        await this.service.render();
    }
    public async loadChange(loading: boolean) {
        this.loading = loading;
        this.sidebar = '';
        console.log(this.loading, '디스 로딩')
        await this.load();
    }
    public async updateDatasetFeatured() {
        let res = await this.service.alert.show({
            title: '',
            message: '특성 이미지를 변경하시겠습니까?',
            cancel: '닫기',
            actionBtn: 'warning',
            action: '확인',
            status: 'warning'
        });

        if (!res) return;

        let img = await this.service.file.read({ type: 'image', accept: 'image/*', width: 960, quality: 1 });
        this.data.featured_image = img;
        await this.service.render();
    }

    public async deleteDatasetFeatured() {
        this.data.featured_image = '';
        await this.service.render();
    }

    public async pageMove(page: number) {
        this.search.page = page;
        await this.load();
    }

    public async load() {
        this.loaded = false;
        console.log(this.search.page)
        let { code, data } = await wiz.call("load", { dataset_id: this.dataset, page: this.search.page, text: this.search.text, mcate: this.mcate, mode: this.mode });
        if (code != 200) return;
        console.log(data, '데이타')
        let { rows, lastpage } = data;
        const startpage = Math.floor((this.search.page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public filtered(item: any) {
        if (!this.search.text) return true;
        let text: any = this.search.text.toLowerCase();
        if (item.microbe.name.toLowerCase().indexOf(text) >= 0) return true;
        return false;
    }

    public async sidebarClose() {
        this.sidebar = null;
        await this.service.render();
    }

    public async addLog() {
        this.sidebar = 'microbe';
        await this.service.render();
    }

    public async updateLog() {
        if (this.pictureFile.name) {
            await this.upload();
            await this.service.render();
        }
        console.log("logdata : ", this.logdata)
        if (!this.logdata.namespace) {
            await this.alert("구분을 입력해주세요.", 'error');
            return;
        }

        let data: any = this.logdata;
        const { code } = await wiz.call("update_log", { data: JSON.stringify(data) });
        if (code == 200) {
            await this.alert("저장되었습니다", 'success');
            await this.sidebarClose();
            await this.load();
        } else {
            await this.alert("저장 중 오류가 발생했습니다.");
        }
        await this.service.render();
    }

    public async addLocation(text: any) {
        if (this.logdata.type_location.includes(text)) return;
        this.logdata.type_location.push(text);
        this.cache.type_location = "";
        console.log(this.logdata)
        await this.service.render();
    }

    public async removeLocation(text: any) {
        this.logdata.type_location.remove(text);
        await this.service.render();
    }

    public async addInnerLocation(text: any) {
        if (this.logdata.type_innerlocation.includes(text)) return;
        this.logdata.type_innerlocation.push(text);
        this.cache.type_innerlocation = "";
        await this.service.render();
    }

    public async removeInnerLocation(text: any) {
        this.logdata.type_innerlocation.remove(text);
        await this.service.render();
    }

    public async microbeSelected(item: any) {
        this.logdata = {};
        this.logdata.microbe = item;
        this.logdata.microbe_id = item.id;
        this.logdata.dataset_id = this.dataset;
        this.logdata.type_location = [];
        this.logdata.type_innerlocation = [];
        this.sidebar = 'log';
        await this.service.render();
    }

    public async editLog(item: any) {
        console.log(item, '아이템 정보')
        this.item_id = item.id;
        this.microbe_id = item.microbe.id
        this.microbe = item.microbe.name;
        this.loading = true;
        this.logdata = item;
        this.logdata.collect_date = moment(this.logdata.collect_date).format("YYYY-MM-DD");
        console.log('log')
        this.sidebar = 'log';
        await this.service.render();
    }

    public async imageSelector(target: string) {
        let res = await this.service.alert.show({
            title: '',
            message: '특성 이미지를 변경하시겠습니까?',
            cancel: '닫기',
            actionBtn: 'warning',
            action: '확인',
            status: 'warning'
        });

        if (!res) return;

        let img = await this.service.file.read({ type: 'image', accept: 'image/*', width: 960, quality: 1 });
        this.logdata[target] = img;
        await this.service.render();
    }

    public async imageDelete(target: string) {
        this.logdata[target] = '';
        await this.service.render();
    }

    // picture_front upload
    public async frontSelector(target: string) {
        let res = await this.service.alert.show({
            title: '',
            message: '특성 이미지를 변경하시겠습니까?',
            cancel: '닫기',
            actionBtn: 'warning',
            action: '확인',
            status: 'warning'
        });

        if (!res) return;

        let file = await this.service.file.select({ accept: '.png,.jpg,.jpeg' });

        this.pictureFile = file[0];
        let img = await this.service.file.resize(file[0], 960, 1)
        this.logdata[target] = img;

        await this.service.render();
    }

    public async upload() {
        let fd = new FormData();
        fd.append('file[]', this.pictureFile, this.pictureFile.name);
        fd.append("data", JSON.stringify({ data: this.logdata }));
        let url = wiz.url('upload');
        let { code, data } = await this.service.file.upload(url, fd);
        if (code != 200) return;
        this.logdata.picture_front = data;
    }
}

export default PortalMicrobeadminDataDatasetLogComponent;