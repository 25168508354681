import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.data.microbe');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-microbeadmin-data-microbe',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.data.microbe/view.scss */
li .d-flex {
  align-items: center;
}

.container {
  height: max-content;
}

.cover-image {
  height: 240px;
  background: #666 no-repeat center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover-image .btn-container {
  display: none;
}
.cover-image:hover .btn-container {
  display: block;
}

.card-covered {
  height: 180px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #E4E4E4 no-repeat center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card-covered span {
  word-break: keep-all;
  color: #7B7B7B;
  padding-top: 1rem;
}

.card-table .table {
  position: absolute;
}
.card-table .table th {
  font-size: 14px;
  background: #EFF4FE;
  padding: 8px 12px;
}
.card-table .table thead {
  position: sticky;
  top: 0;
}
.card-table .table tbody {
  background: #fff;
}
.card-table .table tbody tr {
  cursor: pointer;
}
.card-table .table tbody tr:hover {
  background: var(--wc-background);
}
.card-table .table tbody td {
  white-space: nowrap;
}
.card-table .table tbody .user-status-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
  line-height: 1;
  padding: 4px 0;
  font-size: 12px;
  border-radius: 10px;
  background: var(--wc-blue);
  color: #fff;
}
.card-table .table tbody .btn i {
  width: 16px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 640px;
  background: #fff;
  border-left: 1px solid var(--wc-border);
  overflow: auto;
}
.sidebar .card {
  border-radius: 0;
  border: none;
}
.sidebar .card > * {
  border-radius: 0;
}

.menu-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px;
}

.box {
  display: flex;
  position: relative;
  margin: 0px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
}
.box span {
  margin-top: 10px;
}
.box h3 {
  font: normal normal bold 18px/22px Montserrat;
  margin: 0;
  padding: 24px 17px;
}
.box button {
  padding: 0px;
  width: 100px;
  height: 60px;
  border: none;
  border-radius: 15px;
}
.box button.active {
  background-color: #2547F4;
  color: white;
  cursor: pointer;
  border-radius: 15px;
}`],
})
export class PortalMicrobeadminDataMicrobeComponent implements OnInit {
    public sidebar: any = null;
    public listloaded: boolean = false;
    public mcate = "전체";
    public list: any = [];

    public cache: any = {};

    public data: any = {
        growth_desc: []
    };

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public pictureFile: any = {};

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();
    }

    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load(page: number = 1) {
        this.listloaded = false;
        let { code, data } = await wiz.call("list", { id: this.item, page: page, text: this.search.text, mcate: this.mcate });
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.listloaded = true;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public async pageLoad(page: number, mcate: string) {
        this.mcate = mcate;
        await this.load(page);
    }

    public async sidebarClose() {
        this.sidebar = null;
        await this.service.render();
    }

    public async create() {
        this.data = { growth_desc: [] };
        this.sidebar = 'new';
        await this.service.render();
    }

    public async addGrowth(growth_desc: any) {
        if (this.data.growth_desc.includes(growth_desc)) return;
        this.data.growth_desc.push(growth_desc);
        this.cache.growth_desc = "";
        await this.service.render();
    }

    public async removeGrowth(growth_desc: any) {
        this.data.growth_desc.remove(growth_desc);
        await this.service.render();
    }

    public async update() {
        let data = this.data;
        console.log(data);
        const { code } = await wiz.call("update", { data: JSON.stringify(data) });
        if (code == 200) {
            await this.alert("저장되었습니다", 'success');
            await this.sidebarClose();
            await this.load(this.search.page);
        } else {
            await this.alert("저장 중 오류가 발생했습니다.");
        }
        await this.service.render();
    }

    public async updateFeatured() {
        let res = await this.service.alert.show({
            title: '',
            message: '특성 이미지를 변경하시겠습니까?',
            cancel: '닫기',
            actionBtn: 'warning',
            action: '확인',
            status: 'warning'
        });

        if (!res) return;

        let file = await this.service.file.select({ accept: '.png,.jpg,.jpeg' });
        this.pictureFile = file[0];
        let img = await this.service.file.resize(file[0], 960, 1)

        this.data.featured_image = img;
        await this.service.render();
    }

    public async deleteFeatured() {
        this.data.featured_image = '';
        this.pictureFile = {};
        await this.service.render();
    }

    public async upload(dataID) {
        let fd = new FormData();
        fd.append('file[]', this.pictureFile, this.pictureFile.name);
        fd.append("data", dataID);
        let url = wiz.url('upload');
        let { code, data } = await this.service.file.upload(url, fd);
        if (code != 200) return;
    }
}

export default PortalMicrobeadminDataMicrobeComponent;