import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.data.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-microbeadmin-data-user',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.data.user/view.scss */
.container {
  max-width: 1187px;
  height: 95%;
  background: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  opacity: 0.9;
}

.table-card {
  border-left: none;
  border-right: none;
  border-radius: 0;
  max-width: 1127px;
}
.table-card tr {
  font: normal normal bold 14px/18px SUIT;
}

.dropdown .dropdown-toggle {
  border-radius: 7px;
  color: #707070;
  border: 1px solid #C7C7C7;
  font: normal normal bold 16px/20px SUIT;
}
.dropdown .dropdown-menu {
  max-width: 91px;
  min-width: 80%;
}
.dropdown .dropdown-item {
  min-width: 0;
}

.btn-delete {
  width: 24px;
  height: 24px !important;
  background: #FFF6F5;
  border: 1px solid #EF2B2A;
  border-radius: 3px !important;
  padding: 0;
  color: #EF2B2A;
}

.page-header {
  margin: 0 auto !important;
  max-width: 1187px;
  width: 95%;
}
.page-header .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 280px;
  margin-bottom: 0;
}
.page-header .btn {
  font: normal normal normal 14px/18px SUIT;
  border-radius: 7px;
  height: 48px;
  padding: 1.5rem;
}

.position-absolute {
  display: flex;
  align-items: center;
}

.btn-common.active {
  color: white;
  background-color: #6184D6;
}

td,
th {
  text-align: center;
}

.table-responsive {
  overflow-y: auto;
  border-radius: 0;
}

.table-last {
  max-width: 1127px;
  margin: 0 auto;
}
.table-last thead {
  text-align: left;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  height: 32px;
}
.table-last thead th {
  font: normal normal bold 14px/18px SUIT;
  background: none;
  vertical-align: middle;
  color: #707070;
}
.table-last tbody tr {
  width: 1440px;
  height: 50px;
  border-bottom: solid 0.5px #D1D1D1;
  vertical-align: middle;
}
.table-last tbody tr td {
  height: 30px;
  padding: 0;
  font: normal normal normal 16px/20px SUIT;
  color: #000000 !important;
}`],
})
export class PortalMicrobeadminDataUserComponent implements OnInit {
    public search: any = {
        page: 1,
        text: ''
    };
    public pagenation: any = {
        end: -1,
        start: -1
    };
    public list = [];
    public selectedValue: string | null = null;

    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    public change = {
        status: '',
        role: ''
    }

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/main");
        await this.load();
    }

    public async load(page = 1) {
        const { code, data } = await wiz.call("list", { page: page, text: this.search.text });
        if (code !== 200) {
            await this.alert("로드 실패! 시스템 관리자에게 문의하세요.");
            return;
        }
        const { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public selectValue(status: string, role: string, index: number): void {
        this.list[index].status = status;
        this.list[index].role = role;
        console.log(this.list, '리스트')
        this.update();
    }
    public async update() {

        const { code, data } = await wiz.call("update", { data: JSON.stringify(this.list) })
        // if (code == 200) {
        //     await this.alert('저장되었습니다', '', 'success', '확인');
        // }

    }

    public async changeAdmin(row) {
        console.log(this.service.auth.session);
        if (this.service.auth.session.id === row.id) {
            await this.alert("본인의 권한은 수정할 수 없습니다.");
            return;
        }
        const text = row.role === "admin" ? "관리자 -> 유저" : "유저 -> 관리자";
        const res = await this.alert(`"${row.name}"의 권한을 ${text} 로 변경하시겠습니까?`, {
            cancel: "취소",
        });
        if (!res) return;
        const { code } = await wiz.call("change_admin", {
            user_id: row.id,
            role: row.role,
        });
        if (code !== 200) {
            await this.alert("변경 실패! 시스템 관리자에게 문의하세요.");
            return;
        }
        await this.alert(`"${row.name}"의 권한을 변경하였습니다.`);
        await this.load();
    }

    public async deleteUser(user: string) {
        const confirmed = await this.alert('사용자를 삭제하시겠습니까?');
        if (!confirmed) return;

        const { code } = await wiz.call("delete", { user })
        if (code == 200) {
            await this.alert('삭제되었습니다.', 'success')
        } else {
            await this.alert('삭제에 실패했습니다.', 'error')
        }
        this.load();
    }
}

export default PortalMicrobeadminDataUserComponent;