import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbe.main.location');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-microbe-main-location',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbe.main.location/view.scss */
.image-container {
  border: 1px solid #C3C3C3;
  border-radius: 10px;
}

.card-body {
  padding: 20px 30px;
}
.card-body h3 {
  margin: 20px 0;
  padding-left: 0;
  font: normal normal bold 18px/24px Source Han Sans K;
}
.card-body .col-md-6 {
  padding: 0;
}
.card-body img {
  padding: 0;
  border-radius: 10px;
  border-color: #C7CACE;
}
.card-body .max {
  margin: 16px 0 0;
  max-width: 532px;
}
.card-body .max .col {
  padding: 0;
}
.card-body .max .small-image {
  width: 113px;
  height: 60px;
}
.card-body .shadow {
  opacity: 0.5;
}
.card-body image-container {
  position: relative;
  display: inline-block;
}
.card-body .image {
  width: 512px;
  height: 256px;
}
.card-body .image-source {
  position: absolute;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  bottom: 83%;
  left: 15px;
  width: 150px;
  height: 33px;
  font: normal normal normal 14px/24px SUIT;
  padding: 7px;
  border-radius: 7px;
}
.card-body .location {
  max-width: 667px;
  height: 289px;
}
.card-body .location img {
  width: 100%;
  height: 100%;
}
.card-body .size {
  width: 603px;
}
.card-body .size .col {
  padding: 0;
}
.card-body .size img {
  width: 100%;
  height: 100%;
}

.row {
  margin: 0;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image {
  width: 100%;
  height: auto;
}

.images {
  width: 50%;
}

.image-source {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  font-size: 12px;
}`],
})
export class PortalMicrobeMainLocationComponent implements OnInit {
    public image = "bjheritage_0205.jpg";
    public img = "location_6.JPG";
    public locations = ['외부', '완충 공간 1', '완충 공간 2', '완충 공간 3', '연도', '묘실']
    public index = 0;
    public images = [
        { name: "bjheritage_0205.jpg" },
        { name: "bjheritage_2.png" },
        { name: "bjheritage_0205_winter.jpg" },
        { name: "bjheritage_4.png" }
    ]
    public imgs = [
        { name: "location_6.JPG" },
        { name: "location_5.JPG" },
        { name: "location_4.JPG" },
        { name: "location_3.JPG" },
        { name: "location_2.JPG" },
        { name: "location_1.JPG" }
    ]
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
    }

    public async changeImage(img) {
        this.image = img;
    }
    public async changeImg(img: string, index: number) {
        this.img = img;
        this.index = index;
    }
}

export default PortalMicrobeMainLocationComponent;