import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbe.main.piechart');
import { OnInit, ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ApexCharts from 'apexcharts';
@Component({
    selector: 'wiz-portal-microbe-main-piechart',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbe.main.piechart/view.scss */
.card-header {
  border-bottom: none;
  padding-bottom: 0;
}

.card-body {
  display: flex;
  align-items: center;
}

span {
  font-size: 14px;
  color: #747474;
  border-radius: 50%;
}

h3 {
  font: normal normal bold 18px/24px Source Han Sans K;
}

h1 {
  font-size: 30px;
  font-weight: bold;
  color: black;
}

.progress {
  width: 50%;
}

.bg-one {
  background: #D9CCF7;
}

.bg-two {
  background: #2547F4;
}`],
})
export class PortalMicrobeMainPiechartComponent implements OnInit {
    @ViewChild('chart')
    public chartInstance: any;
    public labels: any = [];
    public list: any = [];

    public percent1: any;
    public percent2: any;
    public total: any;

    constructor(@Inject( Service) public service: Service,@Inject( ElementRef)  public element: ElementRef) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.renderChart();
        await this.service.render();
    }

    public async load(){
        const { code, data } = await wiz.call("load");
        if(code != 200) return;

        for(const key in data){
            if(data.hasOwnProperty(key)){
                this.labels.push(key);
                this.list.push(data[key]);
            }
        }

        this.total = this.list.reduce((a, b) => a + b, 0);
        this.percent1 = this.list[0] / this.total * 100;
        this.percent2 = this.list[1] / this.total * 100;

        await this.service.render();
    }

    public async renderChart() {
        var option = {
            series: this.list, // 데이터
            chart: {
                type: 'pie',
                background: '#FFFFFF',
                height: 200
            },
            labels: this.labels,// 레이블
            colors: ['#D9CCF7', '#2547F4'],
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: -25
                    }
                }
            },
            legend: {
                show: false
            },
        };

        this.chartInstance = new ApexCharts(document.getElementById('piechart'), option);
        this.chartInstance.render();
    }
}

export default PortalMicrobeMainPiechartComponent;