import '@angular/compiler';
import { PortalMicrobeadminWidgetMicrobesComponent } from './portal.microbeadmin.widget.microbes/portal.microbeadmin.widget.microbes.component';
import { PageMicrobeComponent } from './page.microbe/page.microbe.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { LayoutMypageComponent } from './layout.mypage/layout.mypage.component';
import { PageAdminReportComponent } from './page.admin.report/page.admin.report.component';
import { PortalMicrobeMainPiechartComponent } from './portal.microbe.main.piechart/portal.microbe.main.piechart.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalDizestEditorUimodeComponent } from './portal.dizest.editor.uimode/portal.dizest.editor.uimode.component';
import { PortalMicrobeadminAdminEnterDetailComponent } from './portal.microbeadmin.admin.enter.detail/portal.microbeadmin.admin.enter.detail.component';
import { PortalDizestEditorTextComponent } from './portal.dizest.editor.text/portal.dizest.editor.text.component';
import { PortalMicrobeuserDetailComponent } from './portal.microbeuser.detail/portal.microbeuser.detail.component';
import { PageAdminDataDatasetComponent } from './page.admin.data.dataset/page.admin.data.dataset.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalMicrobeadminAdminReportDetailComponent } from './portal.microbeadmin.admin.report.detail/portal.microbeadmin.admin.report.detail.component';
import { PortalDizestCodeflowNoteComponent } from './portal.dizest.codeflow.note/portal.dizest.codeflow.note.component';
import { PortalMicrobeadminDataMicrobeComponent } from './portal.microbeadmin.data.microbe/portal.microbeadmin.data.microbe.component';
import { ComponentNavUserComponent } from './component.nav.user/component.nav.user.component';
import { PageAdminInfoUsersComponent } from './page.admin.info.users/page.admin.info.users.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PageSearchComponent } from './page.search/page.search.component';
import { PortalMicrobeadminDataMicrobePlusComponent } from './portal.microbeadmin.data.microbe.plus/portal.microbeadmin.data.microbe.plus.component';
import { PortalMicrobeMainLocationComponent } from './portal.microbe.main.location/portal.microbe.main.location.component';
import { PageAdminEnterComponent } from './page.admin.enter/page.admin.enter.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PortalDizestSidebarTimerComponent } from './portal.dizest.sidebar.timer/portal.dizest.sidebar.timer.component';
import { PortalDizestEditorNotsupportedComponent } from './portal.dizest.editor.notsupported/portal.dizest.editor.notsupported.component';
import { PortalMicrobeadminDataMicrobePlusInfoComponent } from './portal.microbeadmin.data.microbe.plus.info/portal.microbeadmin.data.microbe.plus.info.component';
import { SearchComponent } from './search/search.component';
import { PageAdminAlarmComponent } from './page.admin.alarm/page.admin.alarm.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PortalMicrobeadminDataDatasetComponent } from './portal.microbeadmin.data.dataset/portal.microbeadmin.data.dataset.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PageAdminDataLocationComponent } from './page.admin.data.location/page.admin.data.location.component';
import { ComponentNavMypageComponent } from './component.nav.mypage/component.nav.mypage.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalMicrobeuserGraphComponent } from './portal.microbeuser.graph/portal.microbeuser.graph.component';
import { PortalMicrobeadminAdminReportComponent } from './portal.microbeadmin.admin.report/portal.microbeadmin.admin.report.component';
import { PageGraphComponent } from './page.graph/page.graph.component';
import { PortalMicrobeMainTimesComponent } from './portal.microbe.main.times/portal.microbe.main.times.component';
import { LayoutUserComponent } from './layout.user/layout.user.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalDizestCodeflowAppinfoComponent } from './portal.dizest.codeflow.appinfo/portal.dizest.codeflow.appinfo.component';
import { PortalSeasonDataDatasetComponent } from './portal.season.data.dataset/portal.season.data.dataset.component';
import { PageAdminDataImageComponent } from './page.admin.data.image/page.admin.data.image.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalDizestWidgetWorkflowNodeComponent } from './portal.dizest.widget.workflow.node/portal.dizest.widget.workflow.node.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalMicrobeMainTimeseriesComponent } from './portal.microbe.main.timeseries/portal.microbe.main.timeseries.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalDizestSidebarCodeflowComponent } from './portal.dizest.sidebar.codeflow/portal.dizest.sidebar.codeflow.component';
import { PortalMicrobeuserSearchComponent } from './portal.microbeuser.search/portal.microbeuser.search.component';
import { PortalMicrobeadminAlarmComponent } from './portal.microbeadmin.alarm/portal.microbeadmin.alarm.component';
import { PortalMicrobeadminDataAllComponent } from './portal.microbeadmin.data.all/portal.microbeadmin.data.all.component';
import { PortalDizestHealthComponent } from './portal.dizest.health/portal.dizest.health.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PageAdminDataReportComponent } from './page.admin.data.report/page.admin.data.report.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalMicrobeadminDataMicrobeItemDetailComponent } from './portal.microbeadmin.data.microbe.item.detail/portal.microbeadmin.data.microbe.item.detail.component';
import { PortalMicrobeadminDataDatasetLogComponent } from './portal.microbeadmin.data.dataset.log/portal.microbeadmin.data.dataset.log.component';
import { PortalMicrobeMainGraphComponent } from './portal.microbe.main.graph/portal.microbe.main.graph.component';
import { PortalDizestAppsComponent } from './portal.dizest.apps/portal.dizest.apps.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PortalDizestSidebarPackagesComponent } from './portal.dizest.sidebar.packages/portal.dizest.sidebar.packages.component';
import { PortalMicrobeadminAdminEnterComponent } from './portal.microbeadmin.admin.enter/portal.microbeadmin.admin.enter.component';
import { PageAdminDataDateComponent } from './page.admin.data.date/page.admin.data.date.component';
import { PageDetailComponent } from './page.detail/page.detail.component';
import { PortalMicrobeadminDataMicrobeItemComponent } from './portal.microbeadmin.data.microbe.item/portal.microbeadmin.data.microbe.item.component';
import { PortalDizestSidebarWorkflowinfoComponent } from './portal.dizest.sidebar.workflowinfo/portal.dizest.sidebar.workflowinfo.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { PortalMicrobeadminDataNameComponent } from './portal.microbeadmin.data.name/portal.microbeadmin.data.name.component';
import { PageAdminDataNameComponent } from './page.admin.data.name/page.admin.data.name.component';
import { PortalMicrobeadminDataUserComponent } from './portal.microbeadmin.data.user/portal.microbeadmin.data.user.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalDizestStatuscardComponent } from './portal.dizest.statuscard/portal.dizest.statuscard.component';
import { PageAdminDataMicrobeComponent } from './page.admin.data.microbe/page.admin.data.microbe.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalMicrobeadminDataLocationComponent } from './portal.microbeadmin.data.location/portal.microbeadmin.data.location.component';
import { PageAdminDataAllComponent } from './page.admin.data.all/page.admin.data.all.component';
import { PortalMicrobeuserMicrobeComponent } from './portal.microbeuser.microbe/portal.microbeuser.microbe.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
    declarations: [
        AppComponent,
        PortalMicrobeadminWidgetMicrobesComponent,
        PageMicrobeComponent,
        LayoutNavbarComponent,
        LayoutMypageComponent,
        PageAdminReportComponent,
        PortalMicrobeMainPiechartComponent,
        ComponentNavAdminComponent,
        PortalSeasonLoadingComponent,
        PortalDizestEditorUimodeComponent,
        PortalMicrobeadminAdminEnterDetailComponent,
        PortalDizestEditorTextComponent,
        PortalMicrobeuserDetailComponent,
        PageAdminDataDatasetComponent,
        PortalSeasonPagenationComponent,
        PortalMicrobeadminAdminReportDetailComponent,
        PortalDizestCodeflowNoteComponent,
        PortalMicrobeadminDataMicrobeComponent,
        ComponentNavUserComponent,
        PageAdminInfoUsersComponent,
        PageMypageComponent,
        PortalSeasonViewerTreeComponent,
        PageSearchComponent,
        PortalMicrobeadminDataMicrobePlusComponent,
        PortalMicrobeMainLocationComponent,
        PageAdminEnterComponent,
        PageAuthenticateComponent,
        PortalDizestSidebarTimerComponent,
        PortalDizestEditorNotsupportedComponent,
        PortalMicrobeadminDataMicrobePlusInfoComponent,
        SearchComponent,
        PageAdminAlarmComponent,
        PortalDizestEditorWorkflowComponent,
        PortalMicrobeadminDataDatasetComponent,
        LayoutEmptyComponent,
        PageAdminDataLocationComponent,
        ComponentNavMypageComponent,
        PortalSeasonLoadingHexaComponent,
        PortalMicrobeuserGraphComponent,
        PortalMicrobeadminAdminReportComponent,
        PageGraphComponent,
        PortalMicrobeMainTimesComponent,
        LayoutUserComponent,
        PageAdminComponent,
        PortalDizestCodeflowAppinfoComponent,
        PortalSeasonDataDatasetComponent,
        PageAdminDataImageComponent,
        PortalSeasonAlertComponent,
        PortalDizestWidgetWorkflowNodeComponent,
        PageMainComponent,
        PortalMicrobeMainTimeseriesComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalSeasonTabComponent,
        PortalDizestSidebarCodeflowComponent,
        PortalMicrobeuserSearchComponent,
        PortalMicrobeadminAlarmComponent,
        PortalMicrobeadminDataAllComponent,
        PortalDizestHealthComponent,
        LayoutAdminComponent,
        PageAdminDataReportComponent,
        PortalSeasonStatusbarComponent,
        PortalMicrobeadminDataMicrobeItemDetailComponent,
        PortalMicrobeadminDataDatasetLogComponent,
        PortalMicrobeMainGraphComponent,
        PortalDizestAppsComponent,
        PortalDizestEditorTerminalComponent,
        PortalDizestSidebarPackagesComponent,
        PortalMicrobeadminAdminEnterComponent,
        PageAdminDataDateComponent,
        PageDetailComponent,
        PortalMicrobeadminDataMicrobeItemComponent,
        PortalDizestSidebarWorkflowinfoComponent,
        PortalDizestEditorSettingComponent,
        PortalMicrobeadminDataNameComponent,
        PageAdminDataNameComponent,
        PortalMicrobeadminDataUserComponent,
        PortalSeasonUiDropdownComponent,
        PortalDizestStatuscardComponent,
        PageAdminDataMicrobeComponent,
        PortalDizestDriveComponent,
        PortalMicrobeadminDataLocationComponent,
        PageAdminDataAllComponent,
        PortalMicrobeuserMicrobeComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        NgxDropzoneModule,
        HttpClientModule,
        
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }