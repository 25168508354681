import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-user',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/layout.user/view.scss */
.wiz-page {
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.wiz-view {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.wiz-view::-webkit-scrollbar {
  display: none;
}

.wiz-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  min-height: 1680px;
  max-height: 1760px;
  display: flex;
  justify-content: center;
  background: transparent linear-gradient(180deg, #D5E1F6 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  overflow: hidden;
}
.wiz-background img {
  width: 100%;
  margin-top: 108px;
}

.loading-zone {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
}
.loading-zone .loading-zone-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.footer {
  border-top: 1px solid #B1C2E0;
  background-color: transparent;
  display: flex;
  margin-top: 96px;
}
.footer img {
  width: 200px;
  margin-right: 32px;
}`],
})
export class LayoutUserComponent implements OnInit {
    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default LayoutUserComponent;