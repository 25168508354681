import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.detail');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-detail',
template: templateSource || '',
    styles: [``],
})
export class PageDetailComponent implements OnInit, DoCheck {
    public ID: any;

    constructor(@Inject( Service) public service: Service) {
        this.ID = WizRoute.segment.id;
        console.log(this.ID)
    }

    public async ngDoCheck() {
        if (this.ID != WizRoute.segment.id) {
            this.ID = WizRoute.segment.id;
            await this.service.render();
        }
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        await this.service.render();
    }
}

export default PageDetailComponent;