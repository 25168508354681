import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.data.dataset');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-data-dataset',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.admin.data.dataset/view.scss */
/* src/app/page.admin.data.dataset/view.scss: no such file or directory */`],
})
export class PageAdminDataDatasetComponent implements OnInit, DoCheck {
    public DATASET: any;
    public ITEM: any;

    constructor(@Inject( Service) public service: Service) {
        this.DATASET = WizRoute.segment.dataset;
        this.ITEM = WizRoute.segment.item;
    }

    public async ngDoCheck() {
        if (this.DATASET != WizRoute.segment.dataset) {
            this.DATASET = WizRoute.segment.dataset;
            await this.service.render();
        }

        if (this.ITEM != WizRoute.segment.item) {
            this.ITEM = WizRoute.segment.item;
            await this.service.render();
        }
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        await this.service.render();
    }
}

export default PageAdminDataDatasetComponent;