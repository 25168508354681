import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.data.microbe.item');
import { OnInit, DoCheck, Component, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'wiz-portal-microbeadmin-data-microbe-item',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.data.microbe.item/view.scss */
@charset "UTF-8";
body {
  width: 100%;
}

.wiz-page {
  width: 100%;
  margin: 0;
  z-index: 2;
  flex-direction: column;
}

.wiz-nav {
  max-width: 1448px;
  width: 90vw;
  margin: 0px auto;
}
@media (max-width: 768px) {
  .wiz-nav {
    width: 92vw;
  }
}

h2 {
  margin-bottom: 0px;
}

.title {
  font: normal normal bold 24px/30px SUIT;
  color: #000000;
}

h3 {
  font: normal normal medium 18px/23px SUIT;
  color: #707070;
}

img {
  vertical-align: baseline;
}

body {
  display: grid;
  place-items: center;
  height: 100dvh;
}

.page-header {
  margin: 0 auto !important;
  max-width: 1187px;
}
.page-header .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 280px;
}
.page-header .btn {
  font: normal normal normal 14px/18px SUIT;
  border-radius: 7px;
  max-width: 77px;
  max-height: 48px;
  padding: 1.5rem;
}
.page-header .btn .btn-blue {
  background: #2547F4;
}
.page-header .btn .btn-red {
  background: #EF2B2A;
}

.content {
  width: 95%;
  margin: auto;
}

.btn-popover {
  background: #f381ff;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 2px 1rem;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
}

#my-popover {
  background: white;
  padding: 1rem;
  max-width: 200px;
  line-height: 1.4;
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.popover-container {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
  text-align: center;
}

/* 공통 팝오버 스타일 */
.popover-content {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1490196078);
  position: absolute;
  border: 1px solid;
  font-size: 14px;
  z-index: 1000;
  width: 133px;
  background-color: white;
  color: #333;
  border-radius: 20px;
  display: block;
  background: white;
  text-align: center;
}
.popover-content::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: currentColor transparent transparent transparent;
  z-index: -1;
}
.popover-content::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-2px);
  border-width: 10.5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  z-index: 0;
}
.popover-content .popover-header {
  padding: 3px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.popover-content .popover-body {
  padding: 10px;
  font: normal normal bold 18px/21px SUIT;
}
.popover-content .popover-body span {
  padding: 1rem;
}

/* 색상과 위치에 따른 팝오버 스타일 */
.popover-content1 {
  border-color: #EB003B;
  color: #EB003B;
  /* 위치 조정 */
  bottom: 41%;
  left: 50%;
  transform: translateX(-50%);
}
.popover-content1 .popover-header {
  background-color: #EB003B;
  color: white;
}

.popover-content2 {
  border-color: #FFB724;
  color: #FFB724;
  /* 위치 조정 */
  bottom: 31%;
  left: 66%;
  transform: translateX(-50%);
}
.popover-content2 .popover-header {
  background-color: #FFB724;
  color: white;
}

.popover-content2 {
  border-color: #FFB724;
  color: #FFB724;
  /* 위치 조정 */
  bottom: 31%;
  left: 66%;
  transform: translateX(-50%);
}
.popover-content2 .popover-header {
  background-color: #FFB724;
  color: white;
}

.popover-content3 {
  border-color: #253BAE;
  color: #253BAE;
  /* 위치 조정 */
  bottom: 25%;
  left: 75%;
  transform: translateX(-50%);
}
.popover-content3 .popover-header {
  background-color: #253BAE;
  color: white;
}

.popover-content4 {
  border-color: #253BAE;
  color: #253BAE;
  /* 위치 조정 */
  bottom: 25%;
  left: 81%;
  transform: translateX(-50%);
}
.popover-content4 .popover-header {
  background-color: #253BAE;
  color: white;
}

.popover-content5 {
  border-color: #2547F4;
  color: #2547F4;
  /* 위치 조정 */
  bottom: 27%;
  left: 92%;
  transform: translateX(-50%);
}
.popover-content5 .popover-header {
  background-color: #2547F4;
  color: white;
}

.popover-content6 {
  border-color: #EB003B;
  color: #EB003B;
  /* 위치 조정 */
  bottom: 41%;
  left: 29%;
  transform: translateX(-50%);
}
.popover-content6 .popover-header {
  background-color: #EB003B;
  color: white;
}

.location-icon {
  width: 30px;
  height: 30px;
  background-color: #d32f2f;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
  border: none;
  /* 버튼의 기본 테두리 제거 */
}

.img-box {
  background: white;
  width: 340px;
}

.text-red {
  color: red;
}

.custom-list li.red-bullet::marker {
  color: #EB003B;
  /* 빨간색 불렛 */
}

.custom-list li.yellow-bullet::marker {
  color: #FFB724;
  /* 노란색 불렛 */
}

.custom-list li.blue-bullet::marker {
  color: #2547F4;
  /* 파란색 불렛 */
}

/* Custom CSS for popover content */
.custom-popover-content {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1490196078);
  position: absolute;
  bottom: 70px;
  left: 20px;
  border: 1px solid #363636;
  font-size: 14px;
  z-index: 1500;
  width: 325px;
  height: 150px;
  background-color: white;
  color: #333;
  padding: 10px;
  border-radius: 10px;
  display: none;
  vertical-align: middle;
}
.custom-popover-content h4 {
  margin-top: 10px;
  margin-left: 15px;
  font: normal normal bold 18px/21px SUIT;
}
.custom-popover-content p {
  margin: 0.2rem;
  font: normal normal bold 15px/18px SUIT;
}

.img-container {
  position: relative;
  display: inline-block;
}

.custom-popover-content.show {
  display: block;
}

#ngb-popover-1 {
  background-color: #fff;
  border: 1px solid #E62C2C;
  border-radius: 10px;
  max-width: 200px;
  /* 필요에 따라 조정 */
  padding: 0;
}

.wiz-view {
  width: 90% !important;
  max-width: 1187px;
  background: white;
  height: 95% !important;
  overflow-y: auto;
  z-index: 1;
  margin: auto;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
}

.wiz-view::-webkit-scrollbar {
  display: none;
}

.season-page {
  position: relative;
  display: flex;
  height: auto;
  width: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 1440px;
  margin: 0px auto;
}
@media (max-width: 768px) {
  .season-page {
    width: 92vw;
  }
}

.season-page::-webkit-scrollbar {
  display: none;
}

.nav-toggler {
  display: none;
}

.nav-collapse {
  display: block;
}

@media (max-width: 768px) {
  .nav-toggler {
    display: block;
  }
  .nav-collapse {
    display: none;
  }
}
.nav-menus {
  display: none;
  color: #fff;
}
@media (max-width: 768px) {
  .nav-menus {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    overflow: auto;
  }
  .nav-menus.closed {
    display: none;
  }
}
.nav-menus .close-btn {
  width: 100%;
  padding-top: 24px;
  padding-right: 36px;
  text-align: right;
  font-size: 36px;
}
.nav-menus .menu-item-container {
  padding: 24px 48px;
}
.nav-menus .menu-item-container .menu-item {
  color: #fff;
  padding: 24px 12px;
  padding-top: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  font-size: 18px;
}
.nav-menus .lang {
  padding: 24px 48px;
  font-size: 18px;
}
.nav-menus .lang .col {
  text-align: center;
}

.img-container {
  position: relative;
  display: inline-block;
  background: white;
  background-size: cover;
}

.btn-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  border: none;
  cursor: pointer;
  background: #363636;
  border-radius: round;
  font-size: 14px;
  z-index: 1;
}

.btn-location6 {
  position: absolute;
  top: 220px;
  right: 800px;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location6-text {
  position: absolute;
  top: 185px;
  right: 800px;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location1 {
  position: absolute;
  top: 220px;
  right: 550px;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location1-text {
  position: absolute;
  top: 185px;
  right: 520px;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location2 {
  position: absolute;
  top: 260px;
  right: 360px;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location2-text {
  position: absolute;
  top: 225px;
  right: 330px;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location3 {
  position: absolute;
  top: 275px;
  right: 265px;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location3-text {
  position: absolute;
  top: 243px;
  right: 235px;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location4 {
  position: absolute;
  top: 275px;
  right: 190px;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location4-text {
  position: absolute;
  top: 245px;
  right: 190px;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location5 {
  position: absolute;
  top: 265px;
  right: 61px;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location5-text {
  position: absolute;
  top: 230px;
  right: 62px;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn {
  border-radius: 20px;
  height: 40px;
}

.gray {
  background-color: #E9E8EE !important;
}

.dark-gray {
  background-color: #2b2c2e !important;
}
.dark-gray .border-line {
  color: #fff;
}

.light-gray {
  background-color: #F8F8F8 !important;
}

.black {
  background-color: black !important;
}
.black .border-line {
  color: #fff;
}

.blue {
  background-color: blue !important;
  width: 100%;
  color: white;
}
.blue .border-line {
  color: #fff;
}

.sticky-menu {
  z-index: 9;
}

.apex-chart-rounded {
  min-height: 360px;
}

.content-fee {
  background-repeat: no-repeat;
  background-position: center;
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), url(/assets/background/fee.jpg);
  background-size: cover;
}
.content-fee .border-line {
  color: #fff;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 640px;
  background: #fff;
  border-left: 1px solid var(--wc-border);
  z-index: 10;
  overflow: auto;
}
.sidebar .card {
  border-radius: 0;
  border: none;
}
.sidebar .card > * {
  border-radius: 0;
}

.device-editor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.wiz-form {
  background-color: #fff;
}

.bottom-line {
  border-bottom: 1px solid #9DA5B4;
  margin-bottom: 30px;
}

.image-table {
  color: #707070;
  margin-top: 20px;
}
.image-table tbody {
  border: none;
}
.image-table tbody tr {
  height: 561px;
}
.image-table tbody th {
  background: #E1E3EA !important;
  vertical-align: top;
  width: 25%;
}
.image-table tbody th .btn-years {
  border: none;
  border-radius: 0;
  background: #E1E3EA;
  box-shadow: none;
  width: 101%;
  font: normal normal normal 18px/23px SUIT;
  color: #707070;
}
.image-table tbody th .btn-years.active {
  background-color: white;
  border-radius: 0;
  font: normal normal bold 18px/23px SUIT;
  color: black;
}
.image-table tbody td {
  width: 25%;
}

/* CSS */
.image-container {
  position: relative;
  padding: 0.1rem 0rem 0rem 0rem;
  display: inline-block;
}

.image-container2 {
  position: relative;
  display: inline-block;
  height: 561px;
}

.btn-tabs {
  width: 100%;
}

ul.nav {
  border-bottom: none;
  width: 95%;
  margin: auto;
}

button.nav-link {
  font-size: 18px;
  color: black;
  border-top: 1px solid #9DA5B4;
  border-left: 1px solid #9DA5B4;
  border-right: 1px solid #9DA5B4;
  border-bottom: 2px solid black;
  border-radius: 0;
}
button.nav-link:hover {
  font-weight: bold;
  border-top: 1px solid #9DA5B4;
  border-left: 1px solid #9DA5B4;
  border-right: 1px solid #9DA5B4;
  border-bottom: 2px solid black;
}

button.nav-link.active {
  font-weight: bold;
  border: 2px solid black !important;
  border-bottom: none !important;
  background-color: transparent !important;
}

.board-table {
  color: #707070;
}
.board-table tbody {
  border-color: #9DA5B4;
  border-top: 1px solid #9DA5B4;
}
.board-table tbody th {
  background: #E1E3EA !important;
  width: 25%;
}
.board-table tbody td {
  width: 25%;
}

.scroll-content {
  overflow-x: auto;
}

.collect-table {
  color: #707070;
  min-width: 100%;
}
.collect-table tbody {
  border-color: #9DA5B4;
  border-top: 1px solid #9DA5B4;
}
.collect-table tbody th {
  background: #E1E3EA !important;
  border-right: 1px solid #9DA5B4;
  white-space: nowrap;
}
.collect-table tbody td {
  border-right: 1px solid #9DA5B4;
  word-break: keep-all;
}
.collect-table tbody tr:first-child,
.collect-table tbody td:first-child {
  border-left: 1px solid #9DA5B4;
}

.image-container .overlay-section {
  position: absolute;
  background-color: transparent;
  /* 초기 배경색은 투명 */
  cursor: pointer;
}
.image-container .overlay-section.active-color {
  background-color: #2547F4;
  opacity: 0.25;
}
.image-container .overlay-section.active {
  background-color: #2b2c2e;
  opacity: 0.25;
}
.image-container .info-card {
  position: absolute;
  top: 0;
  right: -60px important;
  z-index: 10;
  width: 150px;
}

.text-container {
  width: 100%;
}

.card-covered {
  width: 69rem;
  border-radius: 0;
  background: #E4E4E4 no-repeat center/cover;
  display: flex;
  font-size: x-large;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card-covered span {
  word-break: keep-all;
  color: #7B7B7B;
  padding-top: 1rem;
}`],
})
export class PortalMicrobeadminDataMicrobeItemComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) { }
    @Input() item: string = '';
    @Output() loadUpdate = new EventEmitter<boolean>();
    public loading: boolean = true;
    public data: any = {};
    public log: any = {};
    public cache: any = {};
    public activeTab: string = 'microbe';
    public logs: any;
    public years: any = [];
    public activeYear: any;
    public activeSections: any = [];
    public selectedYearInfo: any;
    public showModal: boolean = false;
    public yearLocations: any = [];
    public cardPosition = { right: '0px', top: '0px' };
    public selectedArea: any = null;
    public showPopover = false; // 팝오버 보임 여부
    title = 'angular-pug-popover';
    public isPopoverVisible: boolean[] = [false, false, false, false, false, false];



    public north: any = [
        { id: '북측 1-1', x: 352, y: 33, width: 29, height: 27.5, isActive: false },
        { id: '북측 1-2', x: 352, y: 62, width: 29, height: 27.5, isActive: false },
        { id: '북측 1-3', x: 352, y: 90, width: 29, height: 27.5, isActive: false },
        { id: '북측 1-4', x: 352, y: 115, width: 29, height: 27.5, isActive: false },
        { id: '북측 2-1', x: 381, y: 33, width: 29, height: 27.5, isActive: false },
        { id: '북측 2-2', x: 381, y: 62, width: 29, height: 27.5, isActive: false },
        { id: '북측 2-3', x: 381, y: 90, width: 29, height: 27.5, isActive: false },
        { id: '북측 2-4', x: 381, y: 115, width: 29, height: 27.5, isActive: false },
        { id: '북측 3-1', x: 408, y: 33, width: 29, height: 27.5, isActive: false },
        { id: '북측 3-2', x: 408, y: 62, width: 29, height: 27.5, isActive: false },
        { id: '북측 3-3', x: 408, y: 90, width: 29, height: 27.5, isActive: false },
        { id: '북측 3-4', x: 408, y: 115, width: 29, height: 27.5, isActive: false },
    ];

    public south: any = [
        { id: '남측 1-1', x: 344, y: 189, width: 29, height: 25, isActive: false },
        { id: '남측 1-2', x: 344, y: 213, width: 29, height: 25, isActive: false },
        { id: '남측 1-3', x: 344, y: 238, width: 29, height: 25, isActive: false },
        { id: '남측 1-4', x: 344, y: 262, width: 29, height: 25, isActive: false },
        { id: '남측 2-1', x: 374, y: 189, width: 29, height: 25, isActive: false },
        { id: '남측 2-2', x: 374, y: 213, width: 29, height: 25, isActive: false },
        { id: '남측 3-1', x: 403, y: 189, width: 29, height: 25, isActive: false },
        { id: '남측 3-2', x: 403, y: 213, width: 29, height: 25, isActive: false },
        { id: '남측 3-3', x: 403, y: 238, width: 29, height: 25, isActive: false },
        { id: '남측 3-4', x: 403, y: 262, width: 29, height: 25, isActive: false },
    ];

    public west: any = [
        { id: '서측 1-1', x: 193, y: 473, width: 29, height: 31.67, isActive: false },
        { id: '서측 1-2', x: 223, y: 473, width: 29, height: 31.67, isActive: false },
        { id: '서측 1-3', x: 249, y: 473, width: 29, height: 31.67, isActive: false },
        { id: '서측 1-4', x: 276, y: 473, width: 29, height: 31.67, isActive: false },
        { id: '서측 2-1', x: 193, y: 440, width: 29, height: 31.67, isActive: false },
        { id: '서측 2-2', x: 223, y: 440, width: 29, height: 31.67, isActive: false },
        { id: '서측 2-3', x: 249, y: 440, width: 29, height: 31.67, isActive: false },
        { id: '서측 2-4', x: 276, y: 440, width: 29, height: 31.67, isActive: false },
        { id: '서측 3-1', x: 193, y: 410, width: 29, height: 31.67, isActive: false },
        { id: '서측 3-2', x: 223, y: 410, width: 29, height: 31.67, isActive: false },
        { id: '서측 3-3', x: 249, y: 410, width: 29, height: 31.67, isActive: false },
        { id: '서측 3-4', x: 276, y: 410, width: 29, height: 31.67, isActive: false },
        { id: '서측 4-1', x: 193, y: 380, width: 29, height: 31.67, isActive: false },
        { id: '서측 4-2', x: 223, y: 380, width: 29, height: 31.67, isActive: false },
        { id: '서측 4-3', x: 249, y: 380, width: 29, height: 31.67, isActive: false },
        { id: '서측 4-4', x: 276, y: 380, width: 29, height: 31.67, isActive: false },
        { id: '서측 5-1', x: 193, y: 348, width: 29, height: 31.67, isActive: false },
        { id: '서측 5-2', x: 223, y: 348, width: 29, height: 31.67, isActive: false },
        { id: '서측 5-3', x: 249, y: 348, width: 29, height: 31.67, isActive: false },
        { id: '서측 5-4', x: 276, y: 348, width: 29, height: 31.67, isActive: false },
        { id: '서측 6-1', x: 193, y: 317, width: 29, height: 31.67, isActive: false },
        { id: '서측 6-2', x: 223, y: 317, width: 29, height: 31.67, isActive: false },
        { id: '서측 6-3', x: 249, y: 317, width: 29, height: 31.67, isActive: false },
        { id: '서측 6-4', x: 276, y: 317, width: 29, height: 31.67, isActive: false },
    ];

    public east: any = [
        { id: '동측 1-1', x: 703, y: 319, width: 29, height: 31.67, isActive: false },
        { id: '동측 1-2', x: 676, y: 319, width: 29, height: 31.67, isActive: false },
        { id: '동측 1-3', x: 649, y: 319, width: 29, height: 31.67, isActive: false },
        { id: '동측 1-4', x: 618, y: 319, width: 29, height: 31.67, isActive: false },
        { id: '동측 2-1', x: 703, y: 349, width: 29, height: 31.67, isActive: false },
        { id: '동측 2-2', x: 676, y: 349, width: 29, height: 31.67, isActive: false },
        { id: '동측 2-3', x: 649, y: 349, width: 29, height: 31.67, isActive: false },
        { id: '동측 2-4', x: 618, y: 349, width: 29, height: 31.67, isActive: false },
        { id: '동측 3-1', x: 703, y: 380, width: 29, height: 31.67, isActive: false },
        { id: '동측 3-2', x: 676, y: 380, width: 29, height: 31.67, isActive: false },
        { id: '동측 3-3', x: 649, y: 380, width: 29, height: 31.67, isActive: false },
        { id: '동측 3-4', x: 618, y: 380, width: 29, height: 31.67, isActive: false },
        { id: '동측 4-1', x: 703, y: 411, width: 29, height: 31.67, isActive: false },
        { id: '동측 4-2', x: 676, y: 411, width: 29, height: 31.67, isActive: false },
        { id: '동측 4-3', x: 649, y: 411, width: 29, height: 31.67, isActive: false },
        { id: '동측 4-4', x: 618, y: 411, width: 29, height: 31.67, isActive: false },
        { id: '동측 5-1', x: 703, y: 442, width: 29, height: 31.67, isActive: false },
        { id: '동측 5-2', x: 676, y: 442, width: 29, height: 31.67, isActive: false },
        { id: '동측 5-3', x: 649, y: 442, width: 29, height: 31.67, isActive: false },
        { id: '동측 5-4', x: 618, y: 442, width: 29, height: 31.67, isActive: false },
        { id: '동측 6-1', x: 703, y: 473, width: 29, height: 31.67, isActive: false },
        { id: '동측 6-2', x: 676, y: 473, width: 29, height: 31.67, isActive: false },
        { id: '동측 6-3', x: 649, y: 473, width: 29, height: 31.67, isActive: false },
        { id: '동측 6-4', x: 618, y: 473, width: 29, height: 31.67, isActive: false },
    ];

    public ceiling: any = [
        { id: '천장 1-1', x: 483, y: 319, width: 29, height: 31.67, isActive: false },
        { id: '천장 1-2', x: 483, y: 348, width: 29, height: 31.67, isActive: false },
        { id: '천장 1-3', x: 483, y: 379, width: 29, height: 31.67, isActive: false },
        { id: '천장 1-4', x: 483, y: 409, width: 29, height: 31.67, isActive: false },
        { id: '천장 1-5', x: 483, y: 441, width: 29, height: 31.67, isActive: false },
        { id: '천장 1-6', x: 483, y: 472, width: 29, height: 31.67, isActive: false },
        { id: '천장 2-1', x: 510, y: 319, width: 29, height: 31.67, isActive: false },
        { id: '천장 2-2', x: 510, y: 348, width: 29, height: 31.67, isActive: false },
        { id: '천장 2-3', x: 510, y: 379, width: 29, height: 31.67, isActive: false },
        { id: '천장 2-4', x: 510, y: 409, width: 29, height: 31.67, isActive: false },
        { id: '천장 2-5', x: 510, y: 441, width: 29, height: 31.67, isActive: false },
        { id: '천장 2-6', x: 510, y: 472, width: 29, height: 31.67, isActive: false },
        { id: '천장 3-1', x: 538, y: 319, width: 29, height: 31.67, isActive: false },
        { id: '천장 3-2', x: 538, y: 348, width: 29, height: 31.67, isActive: false },
        { id: '천장 3-3', x: 538, y: 379, width: 29, height: 31.67, isActive: false },
        { id: '천장 3-4', x: 538, y: 409, width: 29, height: 31.67, isActive: false },
        { id: '천장 3-5', x: 538, y: 441, width: 29, height: 31.67, isActive: false },
        { id: '천장 3-6', x: 538, y: 472, width: 29, height: 31.67, isActive: false },
    ];


    public async ngOnInit() {
        await this.service.init();
        // await this.service.auth.allow('admin', '/');
        await this.microData();
        await this.logData();
        await this.dataSet();
        await this.yearsData();
        await this.yearFilter();
        await this.locationMap();
    }


    public async locationMap() {
        let typeInnerLocations = this.activeSections;
        console.log(this.activeSections, '디스 액티브 섹션스')
        const allDirections = [...this.north, ...this.south, ...this.west, ...this.east, ...this.ceiling];

        allDirections.forEach(section => {
            if (typeInnerLocations.includes(section.id)) {
                section.isActive = true;
            } else {
                section.isActive = false;
            }
        });

        await this.service.render();
    }

    public async setActiveTab(tabName: string) {
        this.activeTab = tabName;
        await this.service.render();
    }

    public async edit(id: string) {
        this.post_id = id;
        console.log(this.post_id, '포스트 아이디')
        this.edit = !this.edit;
    }

    public async microData() {
        let { code, data } = await wiz.call("microbeData", { id: this.item });
        if (code != 200) return;
        this.data = data;
        console.log(this.data, '디스 데이타')
        await this.service.render();
    }
    // 마우스 엔터 이벤트 처리 함수
    public async showPop(location: number) {
        this.isPopoverVisible = this.isPopoverVisible.map((_, index) => index === location - 1);
    }

    // 마우스 리브 이벤트 처리 함수
    public async hidePop(location: number) {

        this.isPopoverVisible[location - 1] = false;
    }

    public async logData() {
        let { code, data } = await wiz.call("logData", { id: this.item });
        if (code != 200) return;
        this.log = data
        console.log(this.log, '디스로그');
        await this.service.render();
    }

    public async togglePopover() {
        this.showPopover = !this.showPopover;
    }

    public async yearsData() {
        const { code, data } = await wiz.call("yearsData");
        if (code != 200) return;
        this.years = data;
        await this.service.render();
    }

    public async dataSet() {
        const { code, data } = await wiz.call("dataLoad", { id: this.item });
        if (code != 200) return;
        this.logs = data;
        await this.service.render();
    }

    public async onAreaClick(area: any, event: MouseEvent): void {
        this.selectedYearInfo = this.findYearForArea(area);
        console.log(area, '에리아 클릭')
        if (area.isActive) {
            this.selectedArea = area;
        }
        this.showModal = true;
        this.cardPosition = {
            right: '10px',
            top: '10px'
        };
        await this.service.render();
    }

    public async yearFilter(year: any = 'all') {

        this.activeYear = year;
        console.log(year, '이어 값')

        let filterParams = { id: this.item };
        if (year !== 'all') {
            filterParams['year'] = year;
        }

        const { code, data } = await wiz.call("yearsFilter", filterParams);
        if (code != 200) return;

        this.toggleActiveSections(false);
        let yearLocations = [];
        this.yearLocations = data;

        if (year === 'all') {
            for (const year in data) {
                yearLocations = yearLocations.concat(...data[year]);
            }
        } else {
            yearLocations = data[year] ? data[year].flat() : [];
        }
        this.activeSections = yearLocations;

        this.toggleActiveSections(true);

        await this.service.render();
    }
    public async edit(id: string) {
        this.post_id = id;
        console.log(this.post_id, '포스트 아이디')
        this.loading = !this.loading;
    }

    private findYearForArea(area: any): any {
        let years = [];
        for (const year in this.yearLocations) {
            if (this.yearLocations[year].flat().includes(area.id)) {
                years.push(year);
            }
        }
        return years.length > 0 ? years.join(', ') : null;
    }
    public async loadChange() {
        this.loadUpdate.emit(true)
    }

    public async editChange(loading: boolean) {
        this.loading = loading;
        console.log(this.loading, '디스로딩')
        await this.ngOnInit();
    }
    private async toggleActiveSections(activate: boolean) {
        const allSections = [...this.north, ...this.south, ...this.west, ...this.east, ...this.ceiling];

        allSections.forEach(section => {
            if (this.activeSections.includes(section.id)) {
                section.isActive = activate;
                this.selectedYearInfo = this.findYearForArea(section);
                console.log(this.selectedYearInfo, '셀렉티드 이어 인포')
                const cnt = this.selectedYearInfo.split(",").length;
                let opacity = 0.25 * cnt;
                if (opacity > 1) opacity = 1;
                section.opacity = opacity;
            }
        });

        await this.service.render();
    }

    public async togglePopover() {
        this.showPopover = !this.showPopover;
    }
}

export default PortalMicrobeadminDataMicrobeItemComponent;