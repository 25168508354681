import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.data.dataset');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { NgxDropzoneModule } from 'ngx-dropzone';
import * as XLSX from 'xlsx'


@Component({
    selector: 'wiz-portal-microbeadmin-data-dataset',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.data.dataset/view.scss */
@charset "UTF-8";
.container {
  max-width: 1187px;
  width: 95%;
  height: 80%;
  overflow: auto;
  padding: 0;
}

.cover-image {
  height: 240px;
  background: #666 no-repeat center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover-image .btn-container {
  display: none;
}
.cover-image:hover .btn-container {
  display: block;
}

.red-color {
  color: #EF2B2A;
}

.red-color-img {
  filter: invert(25%) sepia(99%) saturate(1681%) hue-rotate(347deg) brightness(109%) contrast(88%);
}

.blue-color {
  color: #2547F4;
}

li {
  background: #ECF5FF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  width: 580px;
  height: 31px;
  display: flex;
  align-items: center;
}
li a {
  padding-left: 0.5rem;
  color: #5263FF;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  font: normal normal normal 16px/20px SUIT;
  text-decoration: underline;
}
li .btn-remove-file {
  height: 30px !important;
  width: 30px !important;
  border: none;
  background-color: #ECF5FF;
  margin-right: 5px;
}

.images-li {
  background: #E5FCE3;
}
.images-li a {
  color: #00C500;
}
.images-li .btn-remove-file {
  height: 30px !important;
  width: 30px !important;
  border: none;
  background: #E5FCE3;
  margin-right: 5px;
}

.card-covered {
  height: 272px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #E4E4E4 no-repeat center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
.card-covered span {
  word-break: keep-all;
  color: #7B7B7B;
  padding-top: 1rem;
}

.card-link .empty-box {
  height: 0px;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}
.card-link .empty-box img {
  filter: invert(44%) sepia(3%) saturate(0%) hue-rotate(65deg) brightness(91%) contrast(91%);
}
.card-link .check-box {
  height: 0px;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}
.card-link .check-box img {
  filter: invert(33%) sepia(28%) saturate(6221%) hue-rotate(226deg) brightness(104%) contrast(101%);
}

.file-box {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  font: normal normal medium 18px/23px SUIT;
  color: #000000;
}
.file-box .btn-blue {
  border-radius: 10px;
  width: 94px;
  height: 40px;
  font: normal normal normal 16px/21px SUIT;
}

ngx-dropzone {
  width: 580px;
  height: 192px;
  background: #EEF7FF;
  border: 1px dashed #2547F4;
}
ngx-dropzone ngx-dropzone-label {
  font: normal normal medium 18px/23px SUIT !important;
  background: none;
}

.file-card {
  display: flex;
  width: 1122px;
  height: 302px;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
}

.card-search {
  max-width: 1187px;
  width: 95%;
  height: 130px;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  background: white;
  border-radius: 10px;
  border: none;
}
.card-search .btn-blue {
  background: #2547F4 0% 0% no-repeat padding-box;
  border-radius: 7px;
  width: 77px;
  height: 48px;
  color: white !important;
}
.card-search span {
  display: flex;
  align-items: center;
}
.card-search .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 1016px;
  margin-bottom: 0;
}
.card-search h2 {
  width: 30%;
  height: 100%;
}

.row-cards {
  max-width: 1187px;
}

.row-search {
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  margin: 0 auto;
  height: 50px;
  display: flex;
  width: 97%;
  max-width: 1150px;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal normal 16px/18px SUIT;
}

.search-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 99%;
}
@media (max-width: 1200px) {
  .search-box {
    width: 97%;
  }
}

ngx-dropzone-preview {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.preview-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  /* 원하는 크기로 조정 */
  height: 100px;
  /* 원하는 크기로 조정 */
  object-fit: cover;
  /* 이미지가 미리보기 공간에 맞게 조정됨 */
  z-index: 1;
  /* 파일명보다 앞에 나오도록 설정 */
}

ngx-dropzone-label {
  position: relative;
  z-index: 2;
  background: rgba(255, 255, 255, 0.8);
  /* 이미지 위에 반투명 배경 */
  padding: 5px;
  display: inline-block;
}

.text-secondary {
  text-align: left;
  padding: 0.5rem;
  padding-top: 1rem;
  font: normal normal normal 14px/21px SUIT;
  color: #AAAAAA;
}

.dropdown .dropdown-toggle {
  padding-left: 0.5rem;
  padding-bottom: 0;
  border-radius: 7px;
  border: none;
  background: none;
  box-shadow: none;
  font: normal normal normal 16px/18px SUIT;
  gap: 15px;
}
.dropdown .dropdown-menu {
  width: 80px;
  min-width: 0;
}
.dropdown .dropdown-item {
  min-width: 0;
}
.dropdown ::after {
  margin-bottom: 0.4rem;
}

.dropdown-btn .dropdown-toggle {
  border-radius: 7px;
  color: #707070;
  box-shadow: none;
  border: none;
  background: none;
  font: normal normal bold 16px/20px SUIT;
}
.dropdown-btn .dropdown-menu {
  width: 91px;
  min-width: 0;
}
.dropdown-btn .dropdown-item {
  min-width: 0;
}
.dropdown-btn ::after {
  display: none;
}

.page-header {
  margin: 0 auto !important;
  max-width: 1187px;
  width: 95%;
}
.page-header .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 280px;
  margin-bottom: 0;
}
.page-header .btn {
  font: normal normal normal 14px/18px SUIT;
  border-radius: 7px;
  height: 48px;
  padding: 1.5rem;
}

.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  width: 300px;
  height: 200px;
  margin: 0 auto;
}

.row-user {
  padding: 0.5rem;
  padding-top: 1rem;
  margin: 0 auto;
  border-bottom: 1px solid #D1D1D1;
  height: 50px;
  display: flex;
  width: 95%;
  max-width: 1187px;
  flex-direction: row;
  justify-content: space-between;
}
.row-user .btn-delete {
  width: 83px;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background: none;
  border: none;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  box-shadow: none;
}
.row-user .btn-delete:hover {
  background: none;
}

.file-list {
  margin-top: 20px;
}

.text-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.btn-detail {
  border: none;
  background: none;
}

.card-covered {
  height: 180px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #666 no-repeat center/cover;
}

.card-table .table {
  position: absolute;
}
.card-table .table th {
  font-size: 14px;
  background: #EFF4FE;
  padding: 8px 12px;
}
.card-table .table thead {
  position: sticky;
  top: 0;
}
.card-table .table tbody {
  background: #fff;
}
.card-table .table tbody tr {
  cursor: pointer;
}
.card-table .table tbody tr:hover {
  background: var(--wc-background);
}
.card-table .table tbody td {
  white-space: nowrap;
}
.card-table .table tbody .user-status-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
  line-height: 1;
  padding: 4px 0;
  font-size: 12px;
  border-radius: 10px;
  background: var(--wc-blue);
  color: #fff;
}
.card-table .table tbody .btn i {
  width: 16px;
}

body {
  background: #EEE;
  font-family: "Roboto", sans-serif;
}

.dropzone {
  width: 98%;
  margin: 1%;
  border: 2px dashed #3498db !important;
  border-radius: 5px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.dropzone.dz-drag-hover {
  border: 2px solid #3498db !important;
}

.dz-message.needsclick img {
  width: 50px;
  display: block;
  margin: auto;
  opacity: 0.6;
  margin-bottom: 15px;
}

span.plus {
  display: none;
}

.dropzone.dz-started .dz-message {
  display: inline-block !important;
  width: 120px;
  float: right;
  border: 1px solid rgba(238, 238, 238, 0.36);
  border-radius: 30px;
  height: 120px;
  margin: 16px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.dropzone.dz-started .dz-message span.text {
  display: none;
}

.dropzone.dz-started .dz-message span.plus {
  display: block;
  font-size: 70px;
  color: #AAA;
  line-height: 110px;
}


.card-link {
  width: 274px;
  height: 398px;
  border-radius: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
  cursor: pointer;
}
.card-link .card-body {
  height: 126px;
  text-align: left;
  padding: 0.5rem;
}
.card-link .card-body .card-title {
  font: normal normal bold 18px/21px SUIT;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  margin-bottom: 0;
}
.card-link .check-box {
  height: 0px;
  z-index: 10;
}
.card-link .check-box img {
  filter: invert(33%) sepia(28%) saturate(6221%) hue-rotate(226deg) brightness(104%) contrast(101%);
}
.card-link .img-box {
  width: 33px;
  height: 32px;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  background: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1490196078);
}
.card-link .empty-box {
  height: 0px;
  z-index: 10;
}
.card-link .empty-box img {
  filter: invert(44%) sepia(3%) saturate(0%) hue-rotate(65deg) brightness(91%) contrast(91%);
}

.card-covered {
  height: 272px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #E4E4E4 no-repeat center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card-covered span {
  word-break: keep-all;
  color: #7B7B7B;
  padding-top: 1rem;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 640px;
  background: #fff;
  border-left: 1px solid var(--wc-border);
  z-index: 10;
  overflow: auto;
}
.sidebar .sidebar-close {
  border: none;
  box-shadow: none;
}
.sidebar .card {
  border: none;
}
.sidebar .card > * {
  border-radius: 0;
}
.sidebar .form-control {
  max-width: 580px;
  margin-bottom: 10px;
  margin: auto;
}
.sidebar .card-title {
  font: normal normal bold 20px/25px SUIT;
  color: black;
}
.sidebar .guide-box {
  background: #FFF6F5 0% 0% no-repeat padding-box;
  width: 580px;
  height: auto;
  padding: 1rem;
  margin: auto;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.sidebar .guide-box span {
  font: normal normal normal 16px/24px SUIT;
  color: #EF2B2A;
}
.sidebar .btn-blue {
  background: #2547F4 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: white !important;
}
.sidebar h4 {
  font: normal normal bold 16px/20px SUIT;
  margin-bottom: 3px;
  color: #EF2B2A;
}
.sidebar h3 {
  margin: auto;
  max-width: 580px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font: normal normal bold 18px/23px SUIT;
  color: #000000;
}
.sidebar hr {
  margin: auto;
  margin-top: 2rem;
  max-width: 580px;
}
.sidebar .btn-apply {
  margin-top: 30px;
  margin-left: 10px;
  border-radius: 7px;
  height: 48px;
  width: 285px;
  font: normal normal normal 16px/21px SUIT;
  background-color: #5263ff;
  color: white;
}
.sidebar .btn-list {
  margin-top: 30px;
  border-radius: 7px;
  height: 48px;
  width: 285px;
  font: normal normal normal 16px/21px SUIT;
  background-color: #D1D1D1;
}`],
})
export class PortalMicrobeadminDataDatasetComponent implements OnInit {
    public sidebar = false;
    public files: File[] = [];
    public jsonData: any;
    public mcate = "최신순";
    public isRed: boolean = false;
    public isBlue: boolean = false;
    public isSelect: boolean = false;
    public selectedItems: Set<number> = new Set();
    public fd = new FormData();
    public uploadedFiles = [];
    public uploadedImages = [];
    constructor(@Inject( Service) public service: Service) { }
    public data = {
        title: '',
        description: '',
        year: '',
        dataset_id: 'new',
        images: [],
        files: [],
    }
    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();
    }

    public listloaded: boolean = false;
    public list: any = [];

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async selectValue(category: string): void {
        this.mcate = category;
        this.search.page = 1;
        await this.load()
    }

    public async selectStatus(acting: string): void {
        this.acting = acting;
        // this.search.page = 1;
        await this.load()
    }

    public onSelect(event) {
        this.files.push(...event.addedFiles);
        console.log(this.files);
    }
    public onDropdownClick(event: MouseEvent) {
        event.stopPropagation();
    }
    public async excelCall() {
        if (this.selectedItems.size) {
            await this.excelDownload();
        } else {
            await this.alert('추출할 파일을 선택해주세요');
        }

        this.selectedItems.clear();
    }
    public async excelDownload() {
        // 선택된 아이템의 ID 리스트에서 년도만 추출
        const yearList = Array.from(this.selectedItems).map(index => {
            const item = this.list[index];
            return item.year; // year 속성에서 년도 추출
        });
        let years = JSON.stringify(yearList);
        const { code, data } = await wiz.call("excel", { year: years });
        const dt = data.dt// 데이터 가져오기
        console.log(dt, 'dt')
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dt); // 가져온 데이터로 워크시트 생성
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const wbout: ArrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });
        saveAs(blob, 'data.xlsx');

    }
    public fileChange() {
        this.isSelect = !this.isSelect;
        this.isBlue = !this.isBlue;
    }
    private toggleSelection(index: number) {
        console.log(index, '인덱스 콘솔')
        console.log(this.selectedItems, '셀렉티드 아이템스')
        if (this.selectedItems.has(index)) {
            this.selectedItems.delete(index); // 이미 선택된 카드라면 상태를 해제
        } else {
            this.selectedItems.add(index); // 카드 선택
        }
    }
    public cardSelect(index: number): void {
        this.toggleSelection(index);
    }

    public isSelected(index: number): boolean {
        return this.selectedItems.has(index); // 카드 선택 여부 확인
    }
    public onRemove(event) {
        console.log(event);
        this.files.splice(this.files.indexOf(event), 1);
    }
    public getFileUrl(file: File): string {
        return URL.createObjectURL(file);
    }
    public async load(page: number = 1) {
        this.listloaded = false;
        let sortOrder = this.mcate === "최신순" ? "DESC" : "ASC";
        let { code, data } = await wiz.call("load", { page: page, text: this.search.text, order: sortOrder });
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        console.log(this.list);
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.listloaded = true;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public async sidebarClose() {
        this.sidebar = null;
        await this.service.render();
    }

    public async create() {
        this.sidebar = 'new';
        this.data.dataset_id = 'new';
        await this.service.render();
    }

    public async edit(item: string) {
        event.preventDefault();
        console.log(item, '아이템 값들')
        this.sidebar = !this.sidebar;
        console.log(this.sidebar, '사이드 바')
        this.data = item;
        await this.service.render();
    }

    public async toggleColor() {
        this.isRed = !this.isRed;
        this.isSelect = !this.isSelect;
    }

    public async update(data: any) {
        data = JSON.stringify(data);
        console.log(data, '데이타 값')
        const { code } = await wiz.call("update", { data: data });
        if (code == 200) {
            await this.alert("저장되었습니다", 'success');
            await this.sidebarClose();
            await this.load(this.search.page);
        } else {
            await this.alert("저장 중 오류가 발생했습니다.");
        }
        await this.service.render();
    }

    public async onUpload() {
        // this.data.files = [...new Set([...this.data.files, ...this.uploadedFiles])];
        // this.uploadedFiles = [];

        // this.data.images = [...new Set([...this.data.images, ...this.uploadedImages])];
        // this.uploadedImages = [];
        // this.fd.append('data', JSON.stringify(this.data))
        // let url = wiz.url('update')
        // const { code } = await this.service.file.upload(url, this.fd);
        // console.log(code)
        // this.fd = new FormData();
        try {
            // 업로드할 파일 및 이미지 데이터를 준비
            this.data.files = [...new Set([...this.data.files, ...this.uploadedFiles])];
            this.uploadedFiles = [];  // 업로드된 파일들 배열 초기화

            this.data.images = [...new Set([...this.data.images, ...this.uploadedImages])];
            this.uploadedImages = [];  // 업로드된 이미지들 배열 초기화

            // FormData 객체에 데이터를 추가
            this.fd.append('data', JSON.stringify(this.data));

            // URL 설정
            let url = wiz.url('update');

            // 파일 업로드 요청
            const { code } = await this.service.file.upload(url, this.fd);

            // 업로드 결과 확인
            console.log(code);

            // 업로드 후 FormData 초기화
            this.fd = new FormData();

            // 필요시 추가 처리 (예: 성공 메시지 표시, 로딩 상태 해제 등)
            if (code === 200) {
                console.log("업로드 성공");
                // 여기서 추가적인 후처리를 할 수 있습니다.
            } else {
                console.error("업로드 실패");
            }

        } catch (error) {
            // 예외 처리 (네트워크 오류 등)
            console.error("업로드 중 오류 발생:", error);
        } finally {
            // 업로드 후 항상 배열들을 초기화하여 값 리셋
            this.uploadedFiles = [];
            this.uploadedImages = [];
        }
    }
    public async onFileUpload(e) {
        console.log(e.addedFiles, '파일 업로드 이벤트');

        // 파일 업로드 처리
        for (let i = 0; i < e.addedFiles.length; i++) {
            let file = e.addedFiles[i];

            this.filename = file.name;
            if (!file.filepath) file.filepath = file.name;

            // file[] 필드에 이미지 파일과 일반 파일 모두 추가
            this.fd.append('file[]', file);

            // 업로드된 파일이 ZIP 파일인 경우
            if (file.type === 'application/zip') {
                this.uploadedImages.push(file.filepath);
            } else if (file.type.startsWith('image/')) {
                this.uploadedImages.push(file.filepath);
            } else {
                this.uploadedFiles.push(file.filepath);
            }
        }

        // 파일 업로드 처리 후, 렌더링
        await this.service.render();
    }
    public async fileUpload(e) {
        console.log(e.addedFiles, '이벤트')
        for (let i = 0; i < e.addedFiles.length; i++) {
            let file = e.addedFiles[i];
            if (!file.type.startsWith('image/')) {
                this.filename = file.name;
                if (!file.filepath) file.filepath = file.name;
                this.fd.append('file[]', file);  // files 배열에 추가
                this.uploadedFiles.push(file.filepath);
            } else {
                await this.alert('파일 확장자를 확인해주세요');
                // 사용자에게 경고 메시지 표시 (예: alert, toast 등)
            }
        }
        await this.service.render();
    }
    public async removeFile(i) {
        this.files.splice(i, 1)
    }
    // public onFileChange() {
    //     if (this.files.length > 0) {
    //         const file = this.files[0];  // 첫 번째 파일을 가져옵니다
    //         const reader = new FileReader();

    //         reader.onload = (e: any) => {
    //             const data = new Uint8Array(e.target.result);
    //             const workbook = XLSX.read(data, { type: 'array' });

    //             // 엑셀 파일의 첫 번째 시트 가져오기
    //             const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    //             const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    //             // 데이터 확인
    //             console.log('Extracted data:', jsonData);

    //             // 데이터를 백엔드로 전송
    //             this.update(jsonData);
    //         };

    //         reader.readAsArrayBuffer(file);  // 파일을 배열 버퍼로 읽기
    //     }
    // }
    // public async imgUpload(e) {
    //     console.log(e.addedFiles, '이미지 업로드 이벤트');
    //     for (let i = 0; i < e.addedFiles.length; i++) {
    //         let file = e.addedFiles[i];

    //         this.filename = file.name;
    //         if (!file.filepath) file.filepath = file.name;
    //         this.fd.append('file[]', file);  // images 배열에 추가
    //         this.uploadedImages.push(file.filepath);

    //     }
    //     await this.service.render();
    // }

    public async imgUpload(e) {
        console.log(e.addedFiles, '이미지 업로드 이벤트');

        // 폴더 내 모든 파일을 업로드 처리
        for (let i = 0; i < e.addedFiles.length; i++) {
            let file = e.addedFiles[i];

            this.filename = file.name;
            if (!file.filepath) file.filepath = file.name;

            // 업로드된 파일이 폴더 내 파일이라면 파일 경로를 잘 지정
            this.fd.append('file[]', file);  // 파일 업로드를 위한 FormData에 추가
            this.uploadedImages.push(file.filepath);  // 업로드된 파일 경로 저장
        }

        // 파일 업로드 처리 후, 렌더링
        await this.service.render();
    }


    // 파일을 Blob으로 읽기 위한 메소드
    private readFile(file: File): Promise<ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as ArrayBuffer);
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);  // 파일을 ArrayBuffer로 읽기
        });
    }

    // 파일을 Blob으로 읽기 위한 메소드
    private readFile(file: File): Promise<ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as ArrayBuffer);
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);  // 파일을 ArrayBuffer로 읽기
        });
    }

    public async updateFeatured() {
        let res = await this.service.alert.show({
            title: '',
            message: '특성 이미지를 변경하시겠습니까?',
            cancel: '닫기',
            actionBtn: 'warning',
            action: '확인',
            status: 'warning'
        });

        if (!res) return;

        let img = await this.service.file.read({ type: 'image', accept: 'image/*', width: 960, quality: 1 });
        this.data.featured_image = img;
        await this.service.render();
    }

    public async deleteFeatured() {
        this.data.featured_image = '';
        await this.service.render();
    }

    public async delete(item: string) {
        event.preventDefault();
        console.log(item, '아이템')
        if (item) {
            const confirmed = await this.alert('선택한 데이터를 삭제하시겠습니까?');
            if (!confirmed) {
                return;
            }
            const { code } = await wiz.call("delete", { select_item: item.id });
            if (code == 200) {
                await this.alert('삭제되었습니다.', 'success');
                await this.load();
            } else {
                await this.alert('삭제 중 오류가 발생했습니다.');
            }

        } else {
            let select_list = [];
            for (const index of this.selectedItems) {
                if (index >= 0 && index < this.list.length) {
                    const id = this.list[index].id;
                    select_list.push(id)
                }
            }
            if (select_list.length) {
                const confirmed = await this.alert('선택한 데이터를 삭제하시겠습니까?');
                if (!confirmed) {
                    this.selectedItems.clear();
                    return;
                }
                const { code } = await wiz.call("delete", { select_list: JSON.stringify(select_list) });

                if (code == 200) {
                    await this.alert('삭제되었습니다.', 'success');
                    await this.load();
                } else {
                    await this.alert('삭제 중 오류가 발생했습니다.');
                }

            }
            this.selectedItems.clear();
        }
    }
}

export default PortalMicrobeadminDataDatasetComponent;