import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbe.main.times');
import { OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ApexCharts from 'apexcharts';
import { ActivatedRoute, Router } from '@angular/router';
import Handsontable from "handsontable";
import * as XLSX from 'xlsx';


@Component({
    selector: 'wiz-portal-microbe-main-times',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbe.main.times/view.scss */
@charset "UTF-8";
.chart-sort {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg-one {
  background: #D9CCF7;
}

.bg-two {
  background: #2547F4;
}

.row {
  display: flex;
  flex-direction: row;
}

.graph-name {
  display: flex;
  flex-direction: row;
}

.handsontable-container {
  width: 1260px;
  height: 480px;
  flex: auto;
  padding: 24px;
  padding-left: 0;
  margin: 0 auto;
}

.btn-outline-blue {
  width: 118px;
  height: 32px;
  border-radius: 7px;
}

.bold-text {
  font-weight: bold;
}

.dropdown2 {
  width: 100%;
}
.dropdown2 .dropdown-toggle {
  padding-left: 0.5rem;
  border-radius: 7px;
  width: 147px;
  height: 32px;
  color: #707070;
  justify-content: space-between;
  border: 1px solid #C7C7C7;
  font: normal normal bold 16px/20px SUIT;
}
.dropdown2 .dropdown-menu {
  width: 12%;
  min-width: 8%;
  transform: translate3d(455.5px, 202px, 0px);
}
.dropdown2 .dropdown-item {
  min-width: 0;
}

.select-box li:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #000000;
}

h3 {
  font: normal normal bold 18px/24px Source Han Sans K;
  color: #2A345F;
}

.text-species {
  font: normal normal medium 16px/24px SUIT;
  color: #707070;
}

.list-box {
  height: 500px;
  overflow: auto;
}

.list-box ul {
  list-style-type: none;
  /* 기본 점 제거 */
  padding: 0;
  /* 패딩 제거 */
}

.list-box li {
  padding: 10px;
  /* 여백 추가 */
  /* 마우스 포인터 변경 */
}

.list-box li.selected {
  font: normal normal bold 14px/24px SUIT;
  /* 클릭 시 배경색 변경 */
}

li {
  font: normal normal normal 16px/24px SUIT;
}

.chart4 .apexcharts-legend {
  color: red;
  /* 폰트 색상 */
  padding: 10px;
  /* 여백 */
}

.select-box {
  width: 18%;
  position: absolute;
  height: 96.5%;
  left: 83%;
  top: 0;
  background: #F5F5F5;
  border-radius: 0px 10px 10px 0px;
  font: normal normal medium 16px/24px SUIT;
}
.select-box li {
  font: normal normal normal 16px/24px SUIT;
  color: #707070;
  padding: 0.5rem;
  border-bottom: 1px solid #D3D3D3;
}

.custom-legend {
  font: normal normal bold 16px/24px SUIT;
  color: #333;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.apexcharts-toolbar {
  display: none;
}

.toggle-section {
  margin-bottom: 20px;
}

.toggle-text {
  cursor: pointer;
  color: blue;
  /* 기본 텍스트 색상 */
  text-decoration: underline;
  /* 기본 텍스트에 밑줄 추가 */
}

.toggle-text.collapsed {
  color: gray;
  /* 숨김 상태에서는 회색으로 변경 */
  text-decoration: none;
  /* 숨김 상태에서는 밑줄 제거 */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.btn-container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch .btn-color-mode-switch-inner {
  width: 130px;
  height: 35px;
  background-color: #F2F2F2;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}

label.btn-color-mode-switch-inner::before {
  content: attr(data-off);
}

input:checked + label.btn-color-mode-switch-inner::before {
  content: attr(data-on);
}

.btn-color-mode-switch .btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: #777777;
  font-size: 14px;
  line-height: 24px;
}

.btn-color-mode-switch .btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 70px;
  height: 30px;
  background: #2547F4;
  border-radius: 26px;
  position: absolute;
  left: 2px;
  top: 2px;
  text-align: center;
  line-height: 30px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  color: white;
}

.btn-color-mode-switch input[type=checkbox] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0;
}

.btn-color-mode-switch input[type=checkbox]:checked + .btn-color-mode-switch-inner {
  background-color: #F2F2F2;
}

.btn-color-mode-switch input[type=checkbox]:checked + .btn-color-mode-switch-inner:after {
  background: #2547F4;
  content: "표";
  left: calc(100% - 64px);
}

.btn-color-mode-switch input[type=checkbox]:checked + .btn-color-mode-switch-inner:before {
  content: "그래프";
  right: auto;
  left: 20px;
}`],
})
export class PortalMicrobeMainTimesComponent implements OnInit {

    @ViewChild('chart')
    public chartInstance: any;
    @ViewChild('chart')
    public chartInstance2: any;
    @ViewChild('chart')
    public chartInstance3: any;
    @ViewChild('chart')
    public chartInstance4: any;


    @Output() loadUpdate = new EventEmitter<boolean>();
    @Input() text = null;
    public year: any = [];
    public mold: any = [];
    public germ: any = [];
    public showChart = true;
    public selectedItemIndex: number | null = null; // 클릭한 항목의 인덱스

    public microbiomeDetails = {};
    public microbiomeTotal = {};
    public microbiomeAir = {};
    public microbiomeWall = {};
    public microbiomes = [];


    public dt = {};
    public microbeLabels = [];
    public placeCount = [];
    public yearPlaceCount = [];
    public dataByYear = {};
    public totalCounts = [];

    public mcate = '전체';
    public isChecked = false;
    public dataOff = '그래프';
    public dataOn = '표';
    public labelText = this.dataOn;

    public microbeSeries = [];
    public totalSeries = [];
    public airSeries = [];
    public wallSeries = [];

    public locations = [];
    public chartColors = ['#FFB3BA', '#FFDFBA', '#FFFFBA', '#BAFFC9', '#BAE1FF', '#FFC3A0', '#FF677D', '#D4A5A5', '#FFB3BA', '#FFDFBA', '#FFFFBA', '#BAFFC9', '#BAE1FF', '#FFC3A0', '#FF677D', '#D4A5A5', '#FFB3BA', '#FFDFBA', '#FFFFBA', '#BAFFC9', '#BAE1FF', '#FFC3A0', '#FF677D', '#D4A5A5'];

    // 로그 목록을 위한 배열
    public legendItems: string[] = [];
    public allMicrobiomes: string[] = [];

    @ViewChild('handsontable')
    public table: ElementRef;
    public handsontable: any = null;
    public keys = '';

    public selectYear = '2022'
    private isChartRendered = false;

    constructor(@Inject( Service) public service: Service,@Inject( ElementRef)  public element: ElementRef,@Inject( ActivatedRoute)  private route: ActivatedRoute) { }

    public async ngOnInit() {
        await this.service.init();
        this.route.queryParams.subscribe(params => {
            if (params['searchText']) {
                this.text = params['searchText']
                this.removeSearchText();
            }
        });
        await this.dataCount();
        await this.renderChart();
        await this.oneRender();
        await this.service.render();
        this.isChartRendered = true;
    }

    public async dataCount() {
        console.log(this.text, '텍스트')
        const { code, data } = await wiz.call("load", { text: this.text });
        if (code != 200) return;
        this.mold = data.list.mold;
        this.germ = data.list.germ;
        this.year = data.list.year;
        this.microbiomeDetails = data.details;
        this.dataByYear = data.details;

        this.microbeLabels = data.microbe_names;
        this.microbeSeries = data.series;
        this.placeCount = data.p_count;
        this.yearPlaceCount = data.y_count;

        // await this.service.render();
    }

    public async selectValue(year: string): void {
        this.selectYear = year;
        let microbes = [];
        console.log(this.selectYear, '셀렉트 이어')
        let { data } = await wiz.call("data", { year: this.selectYear });
        for (let microbe in data.microbe_counts) {
            let counts = data.microbe_counts[microbe];

            // 행을 생성
            let row = {
                name: microbe,
                외부: counts['외부'] || 0,
                '완충 공간 1': counts['완충 공간 1'] || 0,
                '완충 공간 2': counts['완충 공간 2'] || 0,
                '완충 공간 3': counts['완충 공간 3'] || 0,
                '연도': counts['연도'] || 0, // 연도를 추가할 수 있는 필드
                '묘실': counts['묘실'] || 0,
            };

            microbes.push(row);
        }

        // Handsontable 차트를 갱신하는 부분
        if (this.handsontable) {
            // 기존 데이터 업데이트
            this.handsontable.loadData(microbes);
            console.log('hihi')
        } else {
            // 차트가 아직 초기화되지 않았다면, 초기화 수행
            let ctx = this.table.nativeElement;
            this.handsontable = new Handsontable(ctx, {
                data: microbes,
                height: '100%',
                colWidths: [180, 165, 165, 165, 165, 165, 165],
                rowHeights: 41,
                colHeaders: [
                    '미생물명', '외부', '완충 공간 1', '완충 공간 2', '완충 공간 3', '연도', '묘실'
                ],
                columns: [
                    { data: 'name', type: "text", readOnly: true },
                    { data: '외부', type: "numeric", readOnly: true },
                    { data: '완충 공간 1', type: "numeric", readOnly: true },
                    { data: '완충 공간 2', type: "numeric", readOnly: true },
                    { data: '완충 공간 3', type: "numeric", readOnly: true },
                    { data: '연도', type: "numeric", readOnly: true },
                    { data: '묘실', type: "numeric", readOnly: true },
                ],
                dropdownMenu: true,
                hiddenColumns: {
                    indicators: true,
                },
                contextMenu: true,
                multiColumnSorting: true,
                filters: true,
                rowHeaders: true,
                manualRowMove: true,
                minSpareRows: 1,
                licenseKey: "non-commercial-and-evaluation"
            });
        }

        await this.service.render();
    }

    public selectItem(index: number) {
        this.selectedItemIndex = index;
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes['text'] && changes['text'].currentValue !== changes['text'].previousValue) {
            if (changes['text'].previousValue === undefined || changes['text'].previousValue === null) {
                return;
            }
            if (this.isChartRendered) {
                await this.dataCount();
                await this.renderChart();
            }
        }
    }
    public async renderChart() {
        if (this.chartInstance) {
            this.chartInstance.destroy();
            this.chartInstance = null;
        }
        if (this.chartInstance2) {
            this.chartInstance2.destroy();
            this.chartInstance2 = null;
        }
        if (this.chartInstance3) {
            this.chartInstance3.destroy();
            this.chartInstance3 = null;
        }
        this.chartInstance = null;
        this.chartInstance2 = null;
        this.chartInstance3 = null;
        console.log(this.text, '디스 텍스트')
        let { data } = await wiz.call("data", { year: this.selectYear });

        this.dt = data;
        this.totalCounts = data.total_counts;
        console.log(this.totalCounts, '토탈 카운츠')

        let option = {
            series: [{
                name: '곰팡이',
                data: this.mold
            }, {
                name: '세균',
                data: this.germ
            }],

            chart: {
                type: 'bar',
                height: 280,
                stacked: true,
                toolbar: {
                    show: false
                }
            },
            colors: ['#D9CCF7', '#6A6FEF'], // 색상 설정
            plotOptions: {
                bar: {
                    columnWidth: '50%', // 막대 폭 설정
                    borderRadius: 5
                }
            },
            xaxis: {
                categories: this.year,
            },  // x축 레이블

            yaxis: {
                tickAmount: 4, // y축 눈금 개수
            },

            grid: {
                show: true,
                borderColor: '#F0F0F0', // 격자 라인 색상
                strokeDashArray: 5 // 점선 스타일
            },

            legend: {
                position: 'top',
                align: 'end'
            },

        };

        let options = {
            series: [
                {
                    name: '외부',
                    data: this.placeCount[0]
                },
                {
                    name: '완충공간1',
                    data: this.placeCount[1]
                },
                {
                    name: '완충공간2',
                    data: this.placeCount[2]
                },
                {
                    name: '완충공간3',
                    data: this.placeCount[3]
                },
                {
                    name: '연도',
                    data: this.placeCount[4]
                },
                {
                    name: '묘실',
                    data: this.placeCount[5]
                },
            ],
            chart: {
                type: 'bar',
                height: 280,
                width: 500,
                // stacked: true,
                events: {
                    selection: function (chart, e) {
                        console.log(new Date(e.xaxis.min))
                    }
                },
                toolbar: {
                    show: false,
                    // offsetX: 0,
                    // offsetY: 0,
                    // tools: {
                    // download: true,
                    // zoom: false,
                    // zoomin: true,
                    // zoomout: true,
                    // pan: false,
                    // reset: true | '<img src="/static/icons/reset.png" width="20">',
                    // customIcons: []
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '70%',
                    endingShape: 'rounded',
                    borderRadius: 2,
                    dataLabels: {
                        position: 'top', // 데이터 레이블 위치 조정
                    },
                },
            },
            show: true,
            width: 2,
            colors: ['#008FFB', '#00E396', '#CED4DC', '#EFB99C', '#F7D8FB', '#6B6FEF'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1
                // gradient: {
                //     opacityFrom: 0.6,
                //     opacityTo: 0.8,
                // }
            },
            legend: {
                position: 'top',
                align: 'end'
            },
            xaxis: {
                categories: this.year,
            },
        };



        let options2 = {
            series: [{
                name: this.year[0],
                data: this.yearPlaceCount[0]
            }, {
                name: this.year[1],
                data: this.yearPlaceCount[1]
            }, {
                name: this.year[2],
                data: this.yearPlaceCount[2]
            }, {
                name: this.year[3],
                data: this.yearPlaceCount[3]
            },
            {
                name: this.year[4],
                data: this.yearPlaceCount[4]
            }
            ],
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 3,
                    borderRadiusApplication: 'end', // 'around', 'end'
                    borderRadiusWhenStacked: 'last', // 'all', 'last'
                    dataLabels: {
                        enabled: false // 데이터 레이블 비활성화

                        // total: {
                        //     enabled: true,
                        //     style: {
                        //         fontSize: '13px',
                        //         fontWeight: 900
                        //     }
                        // }
                    }
                },
            },
            colors: [
                '#2EA6FF', // Lavender Blush
                '#D9CCF7', // Pastel Purple
                '#B6FFFA', // Pastel Violet
                '#80B3FF', // Pale Turquoise
                '#687EFF', // Light Sky Blue
                '#6A6FEF', // Pastel Blue
                '#A7C6ED', // Light Blue
                '#F0E5E0'  // Light Blush
            ],
            xaxis: {
                categories: ['외부', '완충공간 1', '완충공간 2', '완충공간 3', '연도', '묘실'],
            },
            legend: {
                position: 'top',
                align: 'end'
                // offsetY: 40
            },
            fill: {
                opacity: 1
            }
        };



        this.chartInstance = new ApexCharts(document.getElementById('chart'), option);
        await this.chartInstance.render(); // 차트 렌더링 완료될 때까지 대기

        this.chartInstance2 = new ApexCharts(document.querySelector("#chart2"), options);
        await this.chartInstance2.render(); // 차트 렌더링 완료될 때까지 대기

        this.chartInstance3 = new ApexCharts(document.querySelector("#chart3"), options2);
        await this.chartInstance3.render(); // 차트 렌더링 완료될 때까지 대기

        ;

        // this.chartInstance4 = new ApexCharts(document.querySelector("#chart4"), options3);
        // await this.chartInstance4.render(); // 차트 렌더링 완료될 때까지 대기

    }



    public async oneRender() {
        // 차트 옵션 생성
        const yearOptions = Object.keys(this.dataByYear).map(year => {
            const yearData = this.dataByYear[year];
            const locations = Object.keys(yearData);
            this.locations = locations;

            const microbiomeSet = new Set<string>();

            for (let location of locations) {
                const microbiomesInLocation = Object.keys(yearData[location]); // 해당 location의 microbiomes

                // 각 microbiome을 Set에 추가 (Set은 중복을 자동으로 제거)
                for (let microbiome of microbiomesInLocation) {
                    microbiomeSet.add(microbiome);
                }
            }
            // 미생물 이름들에 대한 고유 색상을 매핑할 객체를 만듭니다
            const microbiomeColors = {};

            // `this.microbeLabels`에 있는 각 미생물명에 색상을 매핑합니다
            this.microbeLabels.forEach((microbiome, index) => {
                // 색상이 부족할 경우 순차적으로 반복하여 색상을 할당
                const colorIndex = index % this.chartColors.length;  // 색상 배열을 순차적으로 반복
                microbiomeColors[microbiome] = this.chartColors[colorIndex];
            });

            // Set을 배열로 변환하여 최종 microbiomes 배열에 저장
            this.microbiomes = Array.from(microbiomeSet);

            const microbiomeDetails = this.microbiomeDetails;

            const dt = locations.map(location => ({
                name: Object.keys(yearData[location]),
                data:
                    Object.keys(yearData[location]).map(microbiome => {
                        const microbiomeData = yearData[location][microbiome];
                        return Array.isArray(microbiomeData) ? microbiomeData.length : 0;
                    })
            })
            )

            // 각 장소별로 발견된 미생물 목록을 동적으로 구성
            const microbiomesMap = locations.reduce((acc, location) => {
                const microbiomes = Object.keys(yearData[location]);
                microbiomes.forEach(microbiome => {
                    if (microbiome !== "null" && microbiome !== "-") {
                        if (!acc[microbiome]) acc[microbiome] = Array(locations.length).fill(0);  // 초기값은 0으로 설정
                        // 해당 장소에서 발견된 미생물의 개수를 해당 위치에 저장
                        const microbiomeData = yearData[location][microbiome];
                        const locationIndex = locations.indexOf(location);  // 장소의 인덱스를 찾기
                        acc[microbiome][locationIndex] = Array.isArray(microbiomeData) ? microbiomeData.length : microbiomeData || 0;
                    }
                });
                return acc;
            }, {});

            // 각 미생물에 대해 발견된 개수를 배열로 정리
            const seriesData = Object.keys(microbiomesMap).map(microbiome => ({
                name: microbiome,
                data: microbiomesMap[microbiome],
                color: microbiomeColors[microbiome] || '#000000' // 미생물명에 맞는 색상 적용, 없으면 기본 색상
            }));

            this.microbiomeColors = {};
            const chartColors = ['#FFB3BA', '#FFDFBA', '#FFFFBA', '#BAFFC9', '#BAE1FF', '#FFC3A0', '#FF677D', '#D4A5A5', '#FFB3BA', '#FFDFBA', '#FFFFBA', '#BAFFC9', '#BAE1FF', '#FFC3A0', '#FF677D', '#D4A5A5'];

            // microbeLabels 배열을 순회하며 색상 할당
            this.microbeLabels.forEach((microbiome, index) => {
                const colorIndex = index % chartColors.length;  // 색상 배열을 순차적으로 반복
                this.microbiomeColors[microbiome] = chartColors[colorIndex];
            });

            return {
                year: year,
                options: {
                    series: seriesData,
                    // series: Object.values(this.microbeSeries),
                    chart: {
                        type: 'bar',
                        height: 500,
                        width: 300,
                        stacked: true,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: true
                        },
                        events: {

                            mounted: (chartContext) => {
                                // 'options'에 대한 접근은 chartContext.w.config로 해야 할 수도 있음
                                const total = chartContext.w.config.series.reduce((a, b) => a + b, 0);
                                this.allMicrobiomes = []; // 기존 항목 초기화
                                this.legendItems = []; // 클릭된 항목 초기화   


                                // 'options.series'와 'options.labels' 접근 방법 수정
                                chartContext.w.config.series.forEach((value, index) => {
                                    const percentage = (value / total * 100).toFixed(2);
                                    const label = chartContext.w.config.labels[index];
                                    const item = {
                                        name: label,
                                        value: `${value}`,
                                        // percentage: `${percentage}%`
                                    };
                                    this.allMicrobiomes.push(item); // 기존 리스트에 추가
                                });


                            },
                            dataPointSelection: (event, chartContext, config) => {
                                const seriesIndex = config.seriesIndex; // 클릭한 시리즈 인덱스

                                const dataPointIndex = config.dataPointIndex; // 클릭한 데이터 포인트 인덱스
                                const selectedMicrobiome = chartContext.w.config.series[seriesIndex].name;

                                // 해당 미생물의 값 가져오기
                                const value = microbiomeDetails[year][this.locations[dataPointIndex]][selectedMicrobiome];
                                // 하위 항목을 해당 균주 데이터로 바꾸기
                                const downItems = microbiomeDetails[selectedMicrobiome] || [];

                                this.legendItems = value;


                            }
                        }


                    },
                    labels: Object.keys(this.microbeSeries),
                    colors: this.chartColors,
                    xaxis: {
                        categories: locations
                    },
                    legend: {
                        show: false,
                        position: 'left',
                        align: 'center'
                    },
                    fill: {
                        opacity: 1
                    },
                    title: {
                        text: `${year}`,
                        position: 'bottom',
                        align: 'center',
                        offsetY: 20
                    }
                }
            };
        });

        // 각 년도별 옵션을 콘솔에 출력 (테스트용)
        yearOptions.forEach(option => {
            console.log(option.year, option.options);
        });


        let microbes = [];

        for (let microbe in this.dt.microbe_counts) {
            let counts = this.dt.microbe_counts[microbe];

            // 행을 생성
            let row = {
                name: microbe,
                외부: counts['외부'] || 0,
                '완충 공간 1': counts['완충 공간 1'] || 0,
                '완충 공간 2': counts['완충 공간 2'] || 0,
                '완충 공간 3': counts['완충 공간 3'] || 0,
                '연도': counts['연도'] || 0, // 연도를 추가할 수 있는 필드
                '묘실': counts['묘실'] || 0,
            };

            microbes.push(row);
        }
        let ctx = this.table.nativeElement;
        this.handsontable = new Handsontable(ctx, {
            data: microbes,
            height: '100%',
            colWidths: [180, 165, 165, 165, 165, 165, 165],
            rowHeights: 41,
            colHeaders: [
                '미생물명', '외부', '완충 공간 1', '완충 공간 2', '완충 공간3', '연도', '묘실'
            ],
            columns: [
                { data: 'name', type: "text", readOnly: true },
                { data: '외부', type: "numeric", readOnly: true },
                { data: '완충 공간 1', type: "numeric", readOnly: true },
                { data: '완충 공간 2', type: "numeric", readOnly: true },
                { data: '완충 공간 3', type: "numeric", readOnly: true },
                { data: '연도', type: "numeric", readOnly: true },
                { data: '묘실', type: "numeric", readOnly: true },
            ],
            dropdownMenu: true,
            hiddenColumns: {
                indicators: true,
            },
            contextMenu: true,
            multiColumnSorting: true,
            filters: true,
            rowHeaders: true,
            manualRowMove: true,
            minSpareRows: 1,
            licenseKey: "non-commercial-and-evaluation"
        });
        const chartPromises = yearOptions.map(async (option, index) => {
            // 1. 데이터 유효성 검사
            if (!option.year || !option.options) {
                // console.error(`Invalid option at index ${index}:`, option);
                return;  // 필수 데이터가 없으면 이 차트를 렌더링하지 않음
            }

            // 2. 차트 데이터 유효성 검사
            if (!option.options.series || !Array.isArray(option.options.series) || option.options.series.length === 0) {
                // console.error(`Missing or invalid 'series' data for chart with year ${option.year} at index ${index}`);
                return;  // 'series' 데이터가 없으면 이 차트를 렌더링하지 않음
            }

            // 3. xaxis.categories 유효성 검사
            if (!option.options.xaxis || !option.options.xaxis.categories || option.options.xaxis.categories.length === 0) {
                // console.error(`Missing or invalid 'xaxis.categories' for chart with year ${option.year} at index ${index}`);
                return;  // 'xaxis.categories' 데이터가 없으면 이 차트를 렌더링하지 않음
            }

            // 4. 차트 ID와 컨테이너 생성
            const chartId = `chart-${option.year}`;
            const chartContainer = document.createElement('div');
            chartContainer.id = chartId;
            chartContainer.style.height = "350px";  // 차트의 높이 설정
            document.body.appendChild(chartContainer);  // 차트 컨테이너를 DOM에 추가

            // 5. 차트 요소가 제대로 선택되었는지 확인
            const chartElement = document.querySelector(`#${chartId}`);
            if (!chartElement) {
                // console.error(`Failed to find chart container for ${chartId}`);
                return;  // 차트 요소가 없으면 렌더링하지 않음
            }

            // 6. ApexCharts 인스턴스 생성
            const chartInstance = new ApexCharts(chartElement, option.options);

            try {
                // 7. 차트 렌더링
                await chartInstance.render();
                return chartInstance; // 차트 인스턴스를 반환하여 렌더링 완료 후 작업할 수 있게 함
            } catch (error) {
                // console.error(`Error rendering chart for year ${option.year} at index ${index}:`, error);
            }
        });
        // Promise.all로 모든 렌더링이 완료될 때까지 기다림
        await Promise.all(chartPromises)
    }

    public async downloadExcel() {
        let totals = [];
        const p_list = ['외부', '완충 공간 1', '완충 공간 2', '완충 공간 3', '연도', '묘실']; // 장소 리스트

        // 첫 번째 행(열 제목)을 정의
        const headers = ['년도', '미생물명', '외부', '완충 공간 1', '완충 공간 2', '완충 공간 3', '연도', '묘실'];

        // totalCounts에서 데이터를 읽고 엑셀에 맞는 형식으로 변환
        for (let year in this.totalCounts) {
            let yearCounts = this.totalCounts[year]; // 해당 년도에 대한 데이터

            // 이미 등장한 미생물명을 기록할 집합(Set)
            let seenMicrobes = new Set();

            // 각 년도별로 미생물에 대해 반복
            for (let place in yearCounts) {
                let placeCounts = yearCounts[place]; // 해당 장소에 대한 미생물 카운트

                // 미생물별로 행을 생성
                for (let microbe in placeCounts) {
                    // 미생물명이 이미 등장했으면 skip
                    if (seenMicrobes.has(microbe)) {
                        continue;
                    }

                    // 미생물명이 처음 등장하면 Set에 추가
                    seenMicrobes.add(microbe);

                    // 각 장소의 카운트를 확인
                    let row = {
                        '년도': year,
                        '미생물명': microbe,
                        '외부': yearCounts['외부'] && yearCounts['외부'][microbe] ? yearCounts['외부'][microbe] : 0,
                        '완충 공간 1': yearCounts['완충 공간 1'] && yearCounts['완충 공간 1'][microbe] ? yearCounts['완충 공간 1'][microbe] : 0,
                        '완충 공간 2': yearCounts['완충 공간 2'] && yearCounts['완충 공간 2'][microbe] ? yearCounts['완충 공간 2'][microbe] : 0,
                        '완충 공간 3': yearCounts['완충 공간 3'] && yearCounts['완충 공간 3'][microbe] ? yearCounts['완충 공간 3'][microbe] : 0,
                        '연도': yearCounts['연도'] && yearCounts['연도'][microbe] ? yearCounts['연도'][microbe] : 0,
                        '묘실': yearCounts['묘실'] && yearCounts['묘실'][microbe] ? yearCounts['묘실'][microbe] : 0,
                    };

                    // 행을 totals 배열에 추가
                    totals.push(row);
                }
            }
        }

        console.log(totals, 'totals 데이터');

        // totals 배열을 엑셀 형식에 맞게 변환
        const dataWithYear = totals.map(row => [
            row['년도'],
            row['미생물명'],
            row['외부'],
            row['완충 공간 1'],
            row['완충 공간 2'],
            row['완충 공간 3'],
            row['연도'],
            row['묘실'],
        ]);

        // JSON 데이터를 엑셀 형식으로 변환
        const ws = XLSX.utils.aoa_to_sheet([headers, ...dataWithYear]);

        // 엑셀 워크북 생성
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Microbes');

        // 엑셀 파일로 저장
        XLSX.writeFile(wb, 'microbe_data.xlsx');
    }


    public async toggleDisplay() {
        this.showChart = !this.showChart;
        console.log(this.showChart, '쇼차트')
        this.labelText = this.isChecked ? this.dataOff : this.dataOn;
    }


    public async detail(name: string) {
        this.item = name;
        console.log(this.item, '아이템')
        await this.loadChange();
    }

    public async loadChange() {
        console.log('hi')
        this.loadUpdate.emit(this.item)
    }

    private navigateToPage(category: string, dataPointValue: number) {
        // 예시: 특정 카테고리에 따라 페이지 이동
        if (category === '세균') {
            window.location.href = '/main'; // 2021년 페이지로 이동
        } else if (category === '곰팡이') {
            window.location.href = '/search'; // 2022년 페이지로 이동
        }
        // 필요한 추가 페이지 이동 로직
    }
}

export default PortalMicrobeMainTimesComponent;