import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.widget.microbes');
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-microbeadmin-widget-microbes',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.widget.microbes/view.scss */
li .d-flex {
  align-items: center;
}

.container {
  max-width: 1187px;
  width: 95%;
  height: max-content;
  padding: 0;
}

.card-container {
  margin-top: 0;
  padding: 0;
  width: auto;
}

.cover-image {
  height: 240px;
  background: #666 no-repeat center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover-image .btn-container {
  display: none;
}
.cover-image:hover .btn-container {
  display: block;
}

.steps {
  width: 55%;
  margin: auto;
}

.v-line {
  border-left: thick solid #32a1ce;
  height: 18px;
}

.card-search {
  max-width: 1187px;
  width: 95%;
  height: 130px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  background: white;
  border-radius: 10px;
  border: none;
}
.card-search span {
  display: flex;
  align-items: center;
}
.card-search .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 786px;
  margin-bottom: 0;
}
.card-search h2 {
  width: 30%;
  height: 100%;
}

.dropdown .dropdown-toggle {
  padding-left: 0.5rem;
  padding-bottom: 0;
  border-radius: 7px;
  border: none;
  background: none;
  box-shadow: none;
  font: normal normal normal 16px/18px SUIT;
  gap: 15px;
}
.dropdown .dropdown-menu {
  width: 80px;
  min-width: 0;
}
.dropdown .dropdown-item {
  min-width: 0;
}
.dropdown ::after {
  margin-bottom: 0.4rem;
}

.text-secondary {
  padding: 0.5rem;
}

.page-header {
  margin: 0 auto !important;
  max-width: 1187px;
  width: 95%;
}
.page-header .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 280px;
  margin-bottom: 0;
}
.page-header .btn {
  font: normal normal normal 14px/18px SUIT;
  border-radius: 7px;
  height: 48px;
  padding: 1.5rem;
}

.blue-border {
  border: 2px solid #2547F4;
  border-radius: 20px;
}

.card-link {
  width: 274px;
  height: 398px;
  border-radius: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
  cursor: pointer;
}
.card-link .card-body {
  height: 126px;
  text-align: left;
  padding: 0.5rem;
}
.card-link .card-body .card-title {
  font: normal normal bold 18px/21px SUIT;
  padding-left: 0.5rem;
  margin-bottom: 0;
}
.card-link .check-box {
  height: 0px;
  z-index: 10;
}
.card-link .check-box img {
  filter: invert(33%) sepia(28%) saturate(6221%) hue-rotate(226deg) brightness(104%) contrast(101%);
}
.card-link .img-box {
  width: 33px;
  height: 32px;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  background: #FFFFFF;
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1490196078);
}
.card-link .empty-box {
  height: 0px;
  z-index: 10;
}
.card-link .empty-box img {
  filter: invert(44%) sepia(3%) saturate(0%) hue-rotate(65deg) brightness(91%) contrast(91%);
}

.red-color {
  color: #EF2B2A;
}

.red-color-img {
  filter: invert(25%) sepia(99%) saturate(1681%) hue-rotate(347deg) brightness(109%) contrast(88%);
}

.text-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.btn-detail {
  border: none;
  background: none;
}

.btn-detail img {
  transition: filter 0.3s ease;
}

.btn-detail img.red-color {
  filter: invert(46%) sepia(94%) saturate(5996%) hue-rotate(349deg) brightness(107%) contrast(89%);
}

.row-user {
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  margin: 0 auto;
  border-bottom: 1px solid #D1D1D1;
  height: 50px;
  display: flex;
  width: 95%;
  max-width: 1187px;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal normal 16px/18px SUIT;
}
.row-user .btn-delete {
  width: 83px;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background: none;
  border: none;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  box-shadow: none;
}
.row-user .btn-delete:hover {
  background: none;
}

.row-cards {
  max-width: 1187px;
  margin: 0 auto;
  gap: 30px;
}

.card-covered {
  height: 272px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #E4E4E4 no-repeat center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card-covered span {
  word-break: keep-all;
  color: #7B7B7B;
  padding-top: 1rem;
}

.card-table .table {
  position: absolute;
}
.card-table .table th {
  font-size: 14px;
  background: #EFF4FE;
  padding: 8px 12px;
}
.card-table .table thead {
  position: sticky;
  top: 0;
}
.card-table .table tbody {
  background: #fff;
}
.card-table .table tbody tr {
  cursor: pointer;
}
.card-table .table tbody tr:hover {
  background: var(--wc-background);
}
.card-table .table tbody td {
  white-space: nowrap;
}
.card-table .table tbody .user-status-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
  line-height: 1;
  padding: 4px 0;
  font-size: 12px;
  border-radius: 10px;
  background: var(--wc-blue);
  color: #fff;
}
.card-table .table tbody .btn i {
  width: 16px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 640px;
  background: #fff;
  border-left: 1px solid var(--wc-border);
  overflow: auto;
}
.sidebar .card {
  border-radius: 0;
  border: none;
}
.sidebar .card > * {
  border-radius: 0;
}

.menu-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px;
}

.box {
  display: flex;
  position: relative;
  margin: 0px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
}
.box span {
  margin-top: 10px;
}
.box h3 {
  font: normal normal bold 18px/22px Montserrat;
  margin: 0;
  padding: 24px 17px;
}
.box button {
  padding: 0px;
  width: 100px;
  height: 60px;
  border: none;
  border-radius: 15px;
}
.box button.active {
  background-color: #2547F4;
  color: white;
  cursor: pointer;
  border-radius: 15px;
}`],
})
export class PortalMicrobeadminWidgetMicrobesComponent implements OnInit {
    public sidebar: any = null;

    @Input() event: any;
    @Input() dataset = '';
    @Output() loadUpdate = new EventEmitter<boolean>();
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();
    }
    public sidebar = '';
    public listloaded: boolean = false;
    public list: any = [];
    public item_id = 'new';
    public microbe = '';
    public microbe_id = '';
    public logdata = {
        microbe: '',
        microbe_id: ''
    }
    public cache: any = {};

    public data: any = {
        growth_desc: []
    };

    public search: any = {
        page: 1,
        text: ''
    };
    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    public async loadChange() {
        this.loadUpdate.emit(true)
    }
    public async next(item: string) {
        this.microbe_id = item.id
        this.microbe = item.name;
        // this.logdata = item;
        // this.logdata.collect_date = moment(this.logdata.collect_date).format("YYYY-MM-DD");
        this.sidebar = 'log';
        await this.service.render();
    }
    public async load(page: number = 1) {
        this.listloaded = false;
        let { code, data } = await wiz.call("list", { page: page, text: this.search.text });
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.listloaded = true;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

}

export default PortalMicrobeadminWidgetMicrobesComponent;