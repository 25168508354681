import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.search');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-search',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.search/view.scss */
.search-container .input-group .form-control {
  height: 72px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 24px;
}
.search-container .input-group .btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 80px;
  font-size: 32px;
  padding: 12px;
}
.search-container .card-body {
  display: flex;
  align-items: center;
  height: 72px;
  font-size: 18px;
}
.search-container .card-body .title {
  font-family: "main-eb";
}

.row {
  margin: 0;
  width: 870px;
}
.row > * {
  padding: 0 16px;
}
.row > *:first-child {
  padding-left: 0;
}
.row > *:last-child {
  padding-right: 0;
}

.container {
  height: max-content;
}

.cover-image {
  height: 240px;
  background: #666 no-repeat center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover-image .btn-container {
  display: none;
}
.cover-image:hover .btn-container {
  display: block;
}

.card-covered {
  height: 180px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #666 no-repeat center/cover;
}`],
})
export class PageSearchComponent implements OnInit, DoCheck {
    public ITEM: any;

    constructor(@Inject( Service) public service: Service) {
        this.ITEM = WizRoute.segment.item;
    }

    public async ngDoCheck() {
        if (this.ITEM != WizRoute.segment.item) {
            this.ITEM = WizRoute.segment.item;
            await this.service.render();
        }
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        await this.service.render();
    }
}

export default PageSearchComponent;