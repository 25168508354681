import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbe.main.graph');
import { OnInit, ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ApexCharts from 'apexcharts';

@Component({
    selector: 'wiz-portal-microbe-main-graph',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbe.main.graph/view.scss */
.chart-sort {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg-one {
  background: #D9CCF7;
}

h3 {
  font: normal normal bold 18px/24px Source Han Sans K;
}

.bg-two {
  background: #2547F4;
}`],
})
export class PortalMicrobeMainGraphComponent implements OnInit {

    @ViewChild('chart')
    public chartInstance: any;

    public year: any = [];
    public mold: any = [];
    public germ: any = [];

    constructor(@Inject( Service) public service: Service,@Inject( ElementRef)  public element: ElementRef) { }

    public async ngOnInit() {
        await this.service.init();
        await this.dataCount();
        await this.renderChart();
        await this.service.render();
    }

    public async dataCount() {
        const { code, data } = await wiz.call("load");
        if (code != 200) return;
        this.mold = data.mold;
        this.germ = data.germ;
        this.year = data.year;
        await this.service.render();
    }

    public async renderChart() {
        let option = {
            series: [{
                name: '곰팡이',
                data: this.mold
            }, {
                name: '세균',
                data: this.germ
            }],

            chart: {
                type: 'bar',
                height: 220,
                width: 625,
                stacked: true,
                toolbar: {
                    show: false
                },
            events: {
                    dataPointSelection: (event, chartContext, config) => {
                        const seriesIndex = config.seriesIndex; // 클릭한 시리즈 인덱스
                        const dataPointIndex = config.dataPointIndex; // 클릭한 데이터 포인트 인덱스
                        console.log(dataPointIndex, '데포 인덱스')
                        console.log(seriesIndex, '시리즈 인덱스')
                        // 인덱스가 유효한지 체크
                        if (seriesIndex < option.series.length && dataPointIndex < this.year.length) {
                            const year = this.year[dataPointIndex]; // x축 레이블에서 카테고리 가져오기
                            console.log(year, '카테고리')
                            const seriesName = option.series[seriesIndex].name; // 시리즈 이름 가져오기
                            console.log(seriesName, '시리즈')
                            // const dataPointValue = config.series[seriesIndex][dataPointIndex]; // 데이터 포인트 값
                            // console.log(dataPointValue, '포인트 값')
                            // 페이지 이동 로직을 여기에 작성
                            this.navigateToPage(year,seriesName);
                        } else {
                            console.error('Invalid index:', { seriesIndex, dataPointIndex });
                        }
                    }
                }
            },
            
            colors: ['#D9CCF7', '#6A6FEF'], // 색상 설정
            plotOptions: {
                bar: {
                    columnWidth: '40%', // 막대 폭 설정
                    borderRadius: 10
                }
            },
            xaxis: {
                categories: this.year,
            },  // x축 레이블

            yaxis: {
                tickAmount: 4, // y축 눈금 개수
            },

            grid: {
                show: true,
                borderColor: '#F0F0F0', // 격자 라인 색상
                strokeDashArray: 5 // 점선 스타일
            },

            legend: {
                show: false,
            },

        };

        this.chartInstance = new ApexCharts(document.getElementById('widget-chart'), option);
        this.chartInstance.render();
    }

    private navigateToPage(year: string, seriesName: string) {
        // 예시: 특정 카테고리에 따라 페이지 이동
        console.log(year, '카테고리')
        // console.log(dataPointValue, '데이타 포인트 밸류')
        if (seriesName === '세균') {
            window.location.href = '/main'; // 2021년 페이지로 이동
        } else if (seriesName === '곰팡이') {
            window.location.href = '/search'; // 2022년 페이지로 이동
        }
        // 필요한 추가 페이지 이동 로직
    }
}

export default PortalMicrobeMainGraphComponent;