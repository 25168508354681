import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router } from '@angular/router';

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.main/view.scss */
.main-header {
  min-height: 360px;
  padding: 24px 0;
  display: flex;
  width: 1280px;
  flex-direction: column;
  justify-content: center;
}
.main-header .sub-title {
  font-size: 24px;
  font-family: "main-eb";
}
.main-header .title {
  font-size: 72px;
  font-family: "main-eb";
}
.main-header .description {
  margin-top: 24px;
  color: #5D5D64;
  font-size: 16px;
}

.popular-number {
  font: normal normal bold 18px/24px SUIT;
}

.popular-text {
  font: normal normal normal 18px/24px SUIT;
}

.notice-container {
  display: flex;
  font-size: 16px;
  align-items: center;
  height: 40px;
}
.notice-container .notice-title {
  font-family: "main-eb";
  margin-right: 24px;
}
.notice-container .notice-text {
  color: #69697E;
}

.statistic-container {
  width: 1280px;
}

.search-container {
  width: 1280px;
}
.search-container .input-group .form-control {
  height: 72px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font: normal normal normal 20px/24px SUIT;
  padding: 0 24px;
}
.search-container .input-group .btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 80px;
  font-size: 32px;
  padding: 12px;
}
.search-container .card-body {
  display: flex;
  align-items: center;
  height: 72px;
  font-size: 18px;
}
.search-container .card-body .title {
  font-family: "main-eb";
}

.row {
  margin: 0;
}
.row > * {
  padding: 0 16px;
}
.row > *:first-child {
  padding-left: 0;
}
.row > *:last-child {
  padding-right: 0;
}

.btn-popular {
  border: none;
  box-shadow: none;
  width: 75%;
  font-size: 16px;
  justify-content: left;
  padding: 0;
}`],
})
export class PageMainComponent implements OnInit {

    // public searchTerms: string[] = ['Mucor sp.', 'Subsessila turbinata', 'Phoma sp.', 'Arthrinium sp.', 'Penicillium sp.', 'Cladosporium sp.', 'Cochliobolus kusanoi', 'Engyodontium sp.']; // 예시 검색어 배열
    public serchTerms = [];
    // public popularSearch: string = this.searchTerms[0];
    public popularSearch: string = "";
    public loading = true;
    public currentTermIndex: number = 0;
    public currentTermID: string = "";
    public name: string = '';
    public search: any = {
        page: 1,
        text: ''
    };


    constructor(@Inject( Service) public service: Service,@Inject( Router)  private router: Router) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        await this.popular();

    }

    public async load() {
        this.search.text = this.name;
        console.log(this.search.text, '디스 서치 텍스트')
        this.loading = !this.loading;
        this.router.navigate(['/search'], { queryParams: { searchText: this.search.text } });

    }

    public async popular() {
        const { code, data } = await wiz.call("popular");
        if (code != 200) return;
        this.searchTerms = data;
        this.popularSearch = this.searchTerms[0].name;
        this.currentTermID = this.searchTerms[0].id;
        

        setInterval(async () => {
            // 다음 인덱스로 이동하거나 처음으로 리셋
            this.currentTermIndex = (this.currentTermIndex + 1) % this.searchTerms.length;
            // 현재 인덱스에 따라 검색어 업데이트
            this.popularSearch = this.searchTerms[this.currentTermIndex].name;
            this.currentTermID = this.searchTerms[this.currentTermIndex].id;

            await this.service.render();
        }, 3000); // 
        await this.service.render();
    }

    public async move(dataID) {
        // await this.service.href();
        this.router.navigateByUrl(`/detail/${dataID}`);
    }
}

export default PageMainComponent;