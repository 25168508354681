import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeadmin.admin.enter');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'wiz-portal-microbeadmin-admin-enter',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeadmin.admin.enter/view.scss */
.container {
  max-width: 1187px;
  height: 95%;
  background: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  opacity: 0.9;
}

.table-card {
  border-left: none;
  border-right: none;
  border-radius: 0;
  max-width: 1127px;
}
.table-card tr {
  font: normal normal bold 14px/18px SUIT;
}

.title {
  width: 25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn-outline-blue {
  border-radius: 7px;
  width: 147px;
  height: 48px;
}

.vertical {
  border: 0.5px solid #808080;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
}

.row-user {
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  margin: 0 auto;
  border-bottom: 1px solid #D1D1D1;
  height: 50px;
  display: flex;
  width: 95%;
  max-width: 1187px;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal normal 16px/18px SUIT;
}

select {
  width: 7%;
  height: 41px;
  box-shadow: none;
  border-radius: 10px !important;
  font: normal normal normal 16px/18px NanumSquareOTF;
  text-align: left !important;
}

select.form-select {
  width: 9%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background: url("/assets/main/down.png") no-repeat right 12px center;
  background-size: 14px;
  padding-right: 30px;
  border: none;
}

.dropdown .dropdown-toggle {
  border-radius: 7px;
  color: #707070;
  border: 1px solid #C7C7C7;
  font: normal normal bold 16px/20px SUIT;
}
.dropdown .dropdown-menu {
  max-width: 91px;
  min-width: 80%;
}
.dropdown .dropdown-item {
  min-width: 0;
}

.btn-files {
  width: 24px;
  height: 24px;
  background: #C2DCFF;
  border-radius: 3px;
  border: #5263FF 1px solid;
  display: flex;
  justify-content: center;
}
.btn-files img {
  width: 20px;
  max-width: none;
}

.btn-imgs {
  width: 24px;
  height: 24px;
  background: #E5FCE3;
  border-radius: 3px;
  border: #00C500 1px solid;
  display: flex;
  justify-content: center;
}
.btn-imgs img {
  width: 20px;
  max-width: none;
}

.page-header {
  margin: 0 auto !important;
  max-width: 1187px;
  width: 95%;
}
.page-header .form-control {
  border-radius: 7px;
  text-align: left;
  border: 1px solid #C7C7C7;
  height: 48px;
  width: 280px;
  margin-bottom: 0;
}
.page-header .btn {
  font: normal normal normal 14px/18px SUIT;
  border-radius: 7px;
  height: 48px;
  padding: 1.5rem;
}

.position-absolute {
  display: flex;
  align-items: center;
}

.btn-common.active {
  color: white;
  background-color: #6184D6;
}

.table-responsive {
  overflow-y: auto;
  border-radius: 0;
}

.table-last {
  max-width: 1127px;
  margin: 0 auto;
  text-align: left;
}
.table-last thead {
  text-align: left;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  height: 32px;
}
.table-last thead th {
  font: normal normal bold 14px/18px SUIT;
  background: none;
  vertical-align: middle;
  color: #707070;
}
.table-last tbody tr {
  width: 1127px;
  height: 72px;
  border-bottom: solid 0.5px #D1D1D1;
  vertical-align: middle;
}
.table-last tbody tr td {
  height: 30px;
  font: normal normal normal 16px/20px SUIT;
  color: #707070 !important;
}`],
})
export class PortalMicrobeadminAdminEnterComponent implements OnInit {
    public search: any = {
        page: 1,
        text: ''
    };
    public pagenation: any = {
        end: -1,
        start: -1
    };
    public list = [];
    public selectedValue: string | null = null;

    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    private mode = "default";

    constructor(@Inject( Service) public service: Service,@Inject( HttpClient)  private http: HttpClient) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/main");
        await this.load();
    }

    public async load(page = 1) {
        const { code, data } = await wiz.call("list", { page: page, text: this.search.text, mode: this.mode });
        if (code !== 200) {
            await this.alert("로드 실패! 시스템 관리자에게 문의하세요.");
            return;
        }
        const { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.list.forEach(item => {
            item.files = JSON.parse(item.files.replace(/'/g, '"')); // 작은따옴표를 큰따옴표로 변환 후 파싱
            item.images = JSON.parse(item.images.replace(/'/g, '"')); // 작은따옴표를 큰따옴표로 변환 후 파싱
        });
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public selectValue(status: string, role: string, index: number): void {
        this.list[index].status = status;
        this.list[index].role = role;
    }

    public async download(itemId) {
        // 아이템을 list에서 찾음
        const item = this.list.find(i => i.id === itemId);
        let files = item.files;
        console.log(files, '파일스')
        // 각 파일에 대해 다운로드 URL 생성 및 새 탭에서 다운로드 시작
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            console.log(file, '파일')
            const url = `/file/download/enter/${item.id}/${file}/${file}?attatch=false`;
            // window.open(url, '_blank');
            // <a> 태그를 생성하여 다운로드
            const link = document.createElement('a');
            link.href = url;
            link.download = file; // 파일 이름 설정
            document.body.appendChild(link); // 링크를 DOM에 추가
            link.click(); // 다운로드 시작
            document.body.removeChild(link); // 링크 제거
        }
    }
    public async downloadImage(itemId) {
        // 아이템을 list에서 찾음
        const item = this.list.find(i => i.id === itemId);
        let images = item.images;
        console.log(images, '파일스')
        // 각 파일에 대해 다운로드 URL 생성 및 새 탭에서 다운로드 시작
        for (let i = 0; i < images.length; i++) {
            const file = images[i];
            console.log(file, '파일')
            const url = `/file/download/enter/${item.id}/${file}/${file}?attatch=false`;
            // window.open(url, '_blank');
            // <a> 태그를 생성하여 다운로드
            const link = document.createElement('a');
            link.href = url;
            link.download = file; // 파일 이름 설정
            document.body.appendChild(link); // 링크를 DOM에 추가
            link.click(); // 다운로드 시작
            document.body.removeChild(link); // 링크 제거
        }
    }
    private async downloadFile(url: string) {
        try {
            const response = await this.http.get(url, { responseType: 'blob', withCredentials: true }).toPromise();

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response);
            link.download = url.split('/').pop(); // 파일 이름 설정
            link.click(); // 다운로드 시작
            window.URL.revokeObjectURL(link.href); // URL 해제
        } catch (error) {
            console.error('다운로드 중 오류 발생:', error);
        }
    }


    public downloadFiles(item) {
        item.files.forEach(file => {
            const url = `/file/download/apply/${item.id}/${file}/${file}?attatch=false`;
            window.open(url, '_blank');
        });
    }

    public downloadFile(itemId: string, file: string) {
        const url = `/file/download/apply/${itemId}/${file}/${file}?attatch=false`;
        window.open(url, '_blank');
    }
    public onDropdownClick(event: MouseEvent, id: string) {
        event.stopPropagation();
        this.download(id);
    }
    public onImagedownClick(event: MouseEvent, id: string) {
        event.stopPropagation();
        this.downloadImage(id);
    }
    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }


    public async changeAdmin(row) {
        console.log(this.service.auth.session);
        if (this.service.auth.session.id === row.id) {
            await this.alert("본인의 권한은 수정할 수 없습니다.");
            return;
        }
        const text = row.role === "admin" ? "관리자 -> 유저" : "유저 -> 관리자";
        const res = await this.alert(`"${row.name}"의 권한을 ${text} 로 변경하시겠습니까?`, {
            cancel: "취소",
        });
        if (!res) return;
        const { code } = await wiz.call("change_admin", {
            user_id: row.id,
            role: row.role,
        });
        if (code !== 200) {
            await this.alert("변경 실패! 시스템 관리자에게 문의하세요.");
            return;
        }
        await this.alert(`"${row.name}"의 권한을 변경하였습니다.`);
        await this.load();
    }
}

export default PortalMicrobeadminAdminEnterComponent;