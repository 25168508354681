import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeuser.graph');
import { OnInit, DoCheck, Component, ViewChild, TemplateRef, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ApexCharts from 'apexcharts';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import moment from "moment";


@Component({
    selector: 'wiz-portal-microbeuser-graph',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeuser.graph/view.scss */
@charset "UTF-8";
.popover-container {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
  text-align: center;
}

.graph-div {
  background: white;
  width: 1280px;
  border-radius: 7px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1490196078);
}

h2 {
  font: normal normal bold 18px/21px Source Han Sans K;
}

input {
  width: 250px;
  height: 50px;
}

.content-container {
  max-width: 1280px;
}

.content {
  width: 1280px;
}

.text-div {
  padding: 1rem;
}

.date {
  position: absolute;
  top: 2%;
  left: 1%;
}

.year-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.foreground-img {
  position: absolute;
  top: 5%;
  left: 0;
  pointer-events: none;
}

.form-select {
  width: 130px;
  border-radius: 7px;
}

.popover-content {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1490196078);
  position: absolute;
  border: 1px solid;
  font-size: 14px;
  z-index: 1000;
  width: 133px;
  background-color: white;
  color: #333;
  border-radius: 20px;
  display: block;
  background: white;
  text-align: center;
  /* 빨간 선 추가 */
  /* 아이콘 추가 */
}
.popover-content::before {
  content: "";
  position: absolute;
  width: 2px;
  /* 선 높이 */
  top: calc(-1 * var(--line-height, 40px));
  /* 선의 높이에 맞춰 top 위치 계산 */
  /* 팝오버 위에 선 위치 */
  left: 50%;
  transform: translateX(-50%);
}
.popover-content::after {
  content: "";
  position: absolute;
  width: 12px;
  /* 원하는 크기로 조정 */
  height: 12px;
  /* 원하는 크기로 조정 */
  background-image: url("/assets/icon/color_img_green.svg");
  /* 아이콘 이미지 경로 */
  background-size: contain;
  /* 이미지의 원래 비율을 유지 */
  background-position: center;
  background-repeat: no-repeat;
  /* 이미지 반복을 막음 */
  top: -60px;
  /* 팝오버 위에 위치 */
  left: 50%;
  transform: translateX(-50%);
  /* border-radius는 제거 */
}
.popover-content .popover-header {
  padding: 3px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.popover-content .popover-body {
  padding: 10px;
  font: normal normal bold 18px/21px Source Han Sans K;
}
.popover-content .popover-body span {
  padding: 0.5rem;
}

.custom-popover-content {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1490196078);
  position: absolute;
  bottom: 70px;
  left: 20px;
  border: 1px solid #363636;
  font-size: 14px;
  z-index: 1500;
  width: 325px;
  height: 150px;
  background-color: white;
  color: #333;
  padding: 10px;
  border-radius: 10px;
  display: none;
  vertical-align: middle;
}
.custom-popover-content h4 {
  margin-top: 10px;
  margin-left: 0.5rem;
  font: normal normal bold 18px/21px SUIT;
}
.custom-popover-content p {
  margin: 0.2rem;
  font: normal normal bold 15px/18px SUIT;
}

.custom-popover-content.show {
  display: block;
}

.custom-list {
  padding-left: 0.5rem;
}

.btn-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 38px;
  height: 38px;
  border: none;
  cursor: pointer;
  background: #363636;
  border-radius: 50%;
  font-size: 14px;
  z-index: 1;
}

.btn-location1 {
  position: absolute;
  top: 15.5rem;
  right: 54rem;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location1-text {
  position: absolute;
  top: 14rem;
  right: 54.2rem;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font: normal normal bold 18px/21px Source Han Sans K;
  z-index: 1;
  box-shadow: none;
}

.btn-location2 {
  position: absolute;
  top: 15.5rem;
  right: 38rem;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location2-text {
  position: absolute;
  top: 14rem;
  right: 36.5rem;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font: normal normal bold 18px/21px Source Han Sans K;
  z-index: 1;
  box-shadow: none;
}

.btn-location3 {
  position: absolute;
  top: 18rem;
  right: 24.8rem;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location3-text {
  position: absolute;
  top: 16.5rem;
  right: 23.5rem;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font: normal normal bold 18px/21px Source Han Sans K;
  z-index: 1;
  box-shadow: none;
}

.btn-location4 {
  position: absolute;
  top: 19.5rem;
  right: 18rem;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location4-text {
  position: absolute;
  top: 18rem;
  right: 16.5rem;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font: normal normal bold 18px/21px Source Han Sans K;
  z-index: 1;
  box-shadow: none;
}

.btn-location5 {
  position: absolute;
  top: 19.5rem;
  right: 13rem;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location5-text {
  position: absolute;
  top: 18rem;
  right: 13.3rem;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font: normal normal bold 18px/21px Source Han Sans K;
  z-index: 1;
  box-shadow: none;
}

.btn-location6 {
  position: absolute;
  top: 19rem;
  right: 4.5rem;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.btn-location6-text {
  position: absolute;
  top: 17.5rem;
  right: 4.3rem;
  border: none;
  padding: 8px 12px;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  box-shadow: none;
}

.popover-red {
  border-color: #EB003B;
  color: #EB003B;
}
.popover-red .popover-header {
  background-color: #EB003B;
  color: white;
}
.popover-red::before {
  background-color: #EB003B;
}
.popover-red::after {
  background-image: url("/assets/icon/color_img_red.svg");
}

.popover-yellow {
  border-color: #FFB724;
  color: #FFB724;
}
.popover-yellow .popover-header {
  background-color: #FFB724;
  color: white;
}
.popover-yellow::before {
  background-color: #FFB724;
}
.popover-yellow::after {
  background-image: url("/assets/icon/color_img_yellow.svg");
}

.popover-green {
  border-color: #00C400;
  color: #00C400;
}
.popover-green .popover-header {
  background-color: #00C400;
  color: white;
}
.popover-green::before {
  background-color: #00C400;
}
.popover-green::after {
  background-image: url("/assets/icon/color_img_green.svg");
}

.popover-content1 {
  bottom: 33%;
  left: 30%;
  transform: translateX(-50%);
}
.popover-content1::before {
  height: 23px;
  top: -23px;
}
.popover-content1::after {
  top: -32px;
}

.popover-content2 {
  bottom: 33%;
  left: 50%;
  transform: translateX(-50%);
}
.popover-content2::before {
  height: 23px;
  top: -23px;
}
.popover-content2::after {
  top: -32px;
}

.popover-content3 {
  bottom: 23%;
  left: 66.5%;
  transform: translateX(-50%);
}
.popover-content3::before {
  height: 42px;
  top: -42px;
}
.popover-content3::after {
  top: -53px;
}

.popover-content4 {
  bottom: 7%;
  left: 75%;
  transform: translateX(-50%);
}
.popover-content4::before {
  height: 130px;
  top: -130px;
}
.popover-content4::after {
  top: -136px;
}

.popover-content5 {
  bottom: 24%;
  left: 81%;
  transform: translateX(-50%);
}
.popover-content5::before {
  height: 13px;
  top: -13px;
}
.popover-content5::after {
  top: -26px;
}

.popover-content6 {
  bottom: 24%;
  left: 92%;
  transform: translateX(-50%);
}
.popover-content6::before {
  height: 20px;
  top: -20px;
}
.popover-content6::after {
  top: -32px;
}

.location-icon {
  width: 30px;
  height: 30px;
  background-color: #d32f2f;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
  border: none;
}

.table-last {
  width: 98%;
  margin: auto;
  border-collapse: collapse;
  background-color: white;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 9px;
  text-align: center;
  background-color: white !important;
}

.table th {
  background-color: #f2f2f2;
}

tbody td {
  width: 14rem;
  vertical-align: middle;
}

.img-container {
  position: relative;
  display: inline-block;
  background: white;
  background-size: cover;
  border-radius: 10px;
  width: 1280px;
  height: 628px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
}`],
})
export class PortalMicrobeuserGraphComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) {
    }
    public data: any = {};
    public humid = [];
    public humid_avg = {};
    public temp_avg = {};
    public temp = [];
    public toDate = '';
    public log: any = {};
    public cache: any = {};
    public activeTab: string = 'microbe';
    public logs: any;
    public years: any = [];
    public activeYear: any;
    public activeSections: any = [];
    public selectedYearInfo: any;
    public monthly_rows = []
    public year_list = [];
    public showModal: boolean = false;
    public yearLocations: any = [];
    public cardPosition = { right: '0px', top: '0px' };
    public selectedArea: any = null;
    public showPopover = false; // 팝오버 보임 여부
    public today = '';
    public year = moment().format("YYYY");
    public today_format = '';
    public todayList = [];
    public tempList: number[];
    public year = null;
    public list = [];
    public maxDate = '';
    public locations = ['외부', '완충공간1', '완충공간2', '완충공간3', '연도', '묘실'];
    title = 'angular-pug-popover';
    public isPopoverVisible: boolean[] = [true, true, true, true, true, true];
    public last = {};
    @ViewChild('chart1')
    public chartInstance: any;
    @ViewChild('chart2')
    public chartInstance2: any;
    public popoverClasses: string[] = [];
    public iconSources: string[] = [];
    public async ngOnInit() {
        await this.service.init();
        await this.tempData();

    }

    public async setActiveTab(tabName: string) {
        this.activeTab = tabName;
        await this.service.render();
    }
    public async renderChart() {

        if (this.chartInstance) {
            this.chartInstance.destroy();
        }
        if (this.chartInstance2) {
            this.chartInstance2.destroy();
        }
        let options = {
            series: [
                {
                    name: '외부',
                    data: this.temp.temperature6
                },
                {
                    name: '완충공간1',
                    data: this.temp.temperature5
                },
                {
                    name: '완충공간2',
                    data: this.temp.temperature4
                },
                {
                    name: '완충공간3',
                    data: this.temp.temperature3
                },
                {
                    name: '연도',
                    data: this.temp.temperature2
                },
                {
                    name: '묘실',
                    data: this.temp.temperature1
                },
            ].filter(series => series.data.some(value => value !== 0)),
            chart: {
                type: 'line',
                height: 300,
                width: 1265,
                stacked: false,
                events: {
                    selection: function (chart, e) {
                        console.log(new Date(e.xaxis.min))
                    }
                },
                toolbar: {
                    show: false,
                    // offsetX: 0,
                    // offsetY: 0,
                    // tools: {
                    // download: true,
                    // zoom: false,
                    // zoomin: true,
                    // zoomout: true,
                    // pan: false,
                    // reset: true | '<img src="/static/icons/reset.png" width="20">',
                    // customIcons: []
                },
                zoom: {
                    enabled: false
                },
            },
            colors: ['#008FFB', '#00E396', '#CED4DC', '#EFB99C', '#F7D8FB', '#6B6FEF'],
            // dataLabels: {
            //     enabled: true
            // },
            stroke: {
                curve: 'smooth',
                width: 1
            },
            title: {
                text: '온도(℃) ',
                align: 'left',
                style: {
                    fontSize: '16px', // 원하는 폰트 크기로 설정
                    fontWeight: 'bold', // 필요 시 굵기 조절
                    color: '#333' // 제목 색상 조절 (선택적)
                }
            },
            grid: {
                show: true
            },
            markers: {
                size: 4,
                strokeWidth: 0
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.6,
                    opacityTo: 0.8,
                }
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: this.todayList,
                labels: {
                    style: {
                        fontSize: '14px', // x축 레이블 크기 조정
                        color: '#333' // 필요 시 색상 조정
                    }
                }
                // min: 00: 00,
                // max: 24: 00
                // min: new Date('YYYY-MM-DD 00:00').getTime(), // 시작 시간
                // max: new Date('YYYY-MM-DD 23:59').getTime()  // 종료 시간
            },
            yaxis: {
                min: -20, // y축 최소값
                max: 50,  // y축 최대값
                tickAmount: 10, // y축 눈금 개수
                labels: {
                    formatter: function (val) {
                        return val; // 소수점 두 자리로 표시
                    },
                    style: {
                        fontSize: '14px', // y축 레이블 크기 조정
                        color: '#333' // 필요 시 색상 조정
                    }
                }

            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val.toFixed(1); // 소수점 첫째 자리까지 표시
                    }
                }
            }
        };
        let options2 = {
            series: [
                {
                    name: '외부',
                    data: this.humid.humidity6
                },
                {
                    name: '완충공간1',
                    data: this.humid.humidity5
                },
                {
                    name: '완충공간2',
                    data: this.humid.humidity4
                },
                {
                    name: '완충공간3',
                    data: this.humid.humidity3
                },
                {
                    name: '연도',
                    data: this.humid.humidity2
                },
                {
                    name: '묘실',
                    data: this.humid.humidity1
                },
            ].filter(series => series.data.some(value => value !== 0)),
            chart: {
                type: 'line',
                height: 300,
                width: 1265,
                stacked: false,
                events: {
                    selection: function (chart, e) {
                        console.log(new Date(e.xaxis.min))
                    }
                },
                toolbar: {
                    show: false,
                    // offsetX: 0,
                    // offsetY: 0,
                    // tools: {
                    // download: true,
                    // zoom: false,
                    // zoomin: true,
                    // zoomout: true,
                    // pan: false,
                    // reset: true | '<img src="/static/icons/reset.png" width="20">',
                    // customIcons: []
                },
                zoom: {
                    enabled: false
                },
            },
            colors: ['#008FFB', '#00E396', '#CED4DC', '#EFB99C', '#F7D8FB', '#6B6FEF'],
            // dataLabels: {
            //     enabled: true
            // },
            stroke: {
                curve: 'smooth',
                width: 1
            },
            title: {
                text: '습도(%)',
                align: 'left',
                style: {
                    fontSize: '16px', // 원하는 폰트 크기로 설정
                    fontWeight: 'bold', // 필요 시 굵기 조절
                    color: '#333' // 제목 색상 조절 (선택적)
                }
            },
            grid: {
                show: true
            },
            markers: {
                size: 4,
                strokeWidth: 0
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.6,
                    opacityTo: 0.8,
                }
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'right'
            },
            xaxis: {
                categories: this.todayList,
                labels: {
                    style: {
                        fontSize: '14px', // x축 레이블 크기 조정
                        color: '#333' // 필요 시 색상 조정
                    }
                    // min: 00: 00,
                    // max: 24: 00
                    // min: new Date('YYYY-MM-DD 00:00').getTime(), // 시작 시간
                    // max: new Date('YYYY-MM-DD 23:59').getTime()  // 종료 시간
                }
            },
            yaxis: {
                min: 0, // y축 최소값
                max: 100,  // y축 최대값
                tickAmount: 10, // y축 눈금 개수
                labels: {
                    formatter: function (val) {
                        return val; // 소수점 두 자리로 표시
                    },
                    style: {
                        fontSize: '14px', // y축 레이블 크기 조정
                        color: '#333' // 필요 시 색상 조정
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val.toFixed(1); // 소수점 첫째 자리까지 표시
                    }
                }
                }
        };
        this.chartInstance = new ApexCharts(document.getElementById('chart1'), options);
        await this.chartInstance.render();
        this.chartInstance2 = new ApexCharts(document.getElementById('chart2'), options2);
        await this.chartInstance2.render();
    }
    public generateTodayList(): string[] {
        for (let hour = 0; hour < 24; hour++) {
            const formattedHour = String(hour).padStart(2, '0'); // 두 자리 수로 포맷
            this.todayList.push(`${formattedHour}:00`); // 'HH:00' 형식으로 추가
        }

    }
    public async tempData() {
        let { code, data } = await wiz.call("load", { date: this.today_format });
        if (code != 200) return;
        this.data = data.filtered_rows;
        this.humid = data.result_dict.humid;
        this.temp = data.result_dict.temp;
        this.year_list = data.year_list;
        this.year = Math.max(...this.year_list);
        this.tempList = this.temp.temperature1;
        this.humid_avg = data.humid_avg;
        this.temp_avg = data.temp_avg;
        this.today = moment(data.date).format("YYYY-MM-DD");
        this.toDate = moment(data.last_date, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm");
        this.last = data.last;
        this.generateTodayList();

        this.popoverClasses = this.last.temperature.map(temp => this.getPopoverClass(temp));
        this.iconSources = this.popoverClasses.map(className => this.getIconSource(className));

        await this.renderChart();
        await this.service.render();
    }
    public onTodayChange() {
        let dateParts = this.today.split('-'); // 문자열을 '-' 기준으로 나누기
        console.log(this.today, '투데이')
        this.today_format = dateParts[0] + dateParts[1].padStart(2, '0') + dateParts[2].padStart(2, '0'); // 'YYYYMMDD' 형식으로 조합
        console.log(this.today_format)
        this.tempData()
    }


    public getPopoverClass(temp: string): string {
        const temperature = parseFloat(temp); // 문자열을 숫자로 변환
        console.log(temperature, '온도')
        if (temperature <= 15) {
            return 'popover-green'; // 안전 기간
        } else if (temperature > 15 && temperature < 20) {
            return 'popover-yellow'; // 경계 기간
        } else {
            return 'popover-red'; // 위험 기간
        }
    }
    public getIconSource(popoverClass: string): string {
        console.log(popoverClass, '팝오버 클래스')
        if (popoverClass === 'popover-red') {
            return '/assets/location/location_icon_red.svg';
        } else if (popoverClass === 'popover-yellow') {
            return '/assets/location/location_icon_yellow.svg';
        } else {
            return '/assets/location/location_icon_green.svg';
        }
    }
    public async showPop(location: number) {
        this.isPopoverVisible = this.isPopoverVisible.map((_, index) => index === location - 1);
    }


    public async hidePop(location: number) {

        this.isPopoverVisible[location - 1] = false;
    }

    public async togglePopover() {
        this.showPopover = !this.showPopover;
    }


}

export default PortalMicrobeuserGraphComponent;