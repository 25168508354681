import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.data.image');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-page-admin-data-image',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.admin.data.image/view.scss */
/* src/app/page.admin.data.image/view.scss: no such file or directory */`],
})
export class PageAdminDataImageComponent implements OnInit {
    @Input() title: any;

    public async ngOnInit() {
    }
}

export default PageAdminDataImageComponent;