import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.microbeuser.microbe');
import { OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'wiz-portal-microbeuser-microbe',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.microbeuser.microbe/view.scss */
@charset "UTF-8";
.input-group {
  display: flex;
  /* 인풋과 버튼을 가로로 배치 */
}

.input-group .form-control {
  flex: 1;
  font: normal normal normal 20px/24px SUIT;
  /* 인풋이 남은 공간을 차지하게 함 */
}

.input-group .btn {
  margin-left: -1px;
  /* 버튼과 인풋 간의 경계선을 연결하기 위해 */
}

.search-container {
  padding-left: 0;
}
.search-container .input-group {
  width: 1280px;
}
.search-container .input-group .form-control {
  height: 80px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 24px;
}
.search-container .input-group .btn-outline-blue {
  border-radius: 20px;
  position: absolute;
  width: 104px;
  height: 40px;
  left: 84%;
  top: 21%;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  font: normal normal normal 16px/24px SUIT;
  padding: 12px;
}
.search-container .input-group .btn-blue {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 80px;
  font-size: 32px;
  padding: 12px;
}
.search-container .card-body {
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 18px;
}
.search-container .card-body .title {
  font-family: "main-eb";
}

::-webkit-scrollbar {
  display: none;
  /* 스크롤바 숨기기 */
}

.popover {
  background-color: #fff;
  border: 1px solid #E62C2C;
  border-radius: 10px;
  max-width: 200px;
  /* 필요에 따라 조정 */
  padding: 0;
}

.popover .popover-header {
  background-color: #E62C2C;
  color: white;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}

.popover .popover-body {
  color: black;
  padding: 10px;
  text-align: center;
}

.popover .popover-body span {
  display: block;
  margin-bottom: 5px;
}

.popover .arrow::before {
  border-bottom-color: #E62C2C;
}

.popover .arrow::after {
  border-bottom-color: #fff;
}

/* 팝오버 화살표 스타일 */
.popover .arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #E62C2C;
  position: absolute;
  bottom: -10px;
  /* 조정 필요 */
  left: calc(50% - 10px);
  margin-left: -10px;
}

.row-child {
  margin: 0;
  width: 1280px;
}

.container {
  height: max-content;
}
.container .card {
  border: none;
  background: none;
}
.container .card .card-link {
  border-radius: 0 !important;
}
.container .card:hover {
  opacity: 0.5;
  box-shadow: none !important;
}

.cover-image {
  height: 240px;
  background: #666 no-repeat center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover-image .btn-container {
  display: none;
}
.cover-image:hover .btn-container {
  display: block;
}

.card-covered {
  height: 180px;
  border-radius: 0;
  background: #E4E4E4 no-repeat center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card-covered span {
  word-break: keep-all;
  color: #7B7B7B;
  padding-top: 1rem;
}

.form-label {
  font-size: 16px;
  font-family: "MAIN-M";
}

.filter-area {
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
  width: 280px;
  height: 48px;
  align-items: center;
}

.filter-area.active {
  background: #E7EEF8 0% 0% no-repeat padding-box;
  border: 1px solid #2547F4;
  color: #2547F4;
  border-radius: 10px;
  opacity: 1;
  align-items: center;
}

.form-check-label {
  font-size: 16px;
  font-family: "MAIN-N";
  cursor: pointer;
}

.form-check-input {
  border: 1.5px solid #5B5D6B;
  border-radius: 2px;
  margin-left: 10px;
}

input[type=checkbox]:checked {
  background-color: #4F40F1;
}

input[type=checkbox]:checked + span.form-check-label {
  color: #4F40F1;
}

input[type=checkbox]:checked + span.form-check-label + span.ml-auto.mr-2 {
  color: #4F40F1;
}

input[type=radio] {
  width: 20px;
  /* 원 크기 */
  height: 20px;
  border-radius: 50%;
  /* 원형 */
  border: 2px solid #9A9A9A;
  /* 테두리 색상 */
  background-color: white;
  /* 체크됐을 때의 스타일 */
}
input[type=radio]:checked {
  border: 2px solid #4F40F1;
  background-color: #4F40F1;
  /* 선택된 색상 */
}

.btn-cate {
  border: none;
  margin-left: auto;
  position: relative;
}

.d-flex {
  margin-top: 10px;
}

.bottom-line {
  border-bottom: 1px solid #BBCCEA;
}

select.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background: url("/assets/main/down.png") no-repeat right 12px center;
  background-size: 14px;
  padding-right: 30px;
  border: none;
}

.nav-tabs {
  list-style-type: none;
  padding: 0;
  display: flex;
  position: relative;
  top: 36px;
}

.nav-item {
  width: 100%;
  font-size: 16px;
  border-bottom: 1px solid #707070;
}

.nav-item.active {
  color: #2547F4;
  background-color: white;
  font: normal normal bold 18px/24px SUIT;
  /* 활성화된 탭의 배경색 */
  border-bottom: 3px solid #2547F4;
}

.nav-link {
  display: block;
  text-align: center;
  text-decoration: none;
  border: none;
  font: normal normal normal 18px/24px SUIT;
}

.search-btn {
  width: 100%;
  flex: 0 0 auto;
}
.search-btn .btn {
  font-family: "main-eb";
  cursor: pointer !important;
}
.search-btn .btn.btn-search {
  background: var(--wc-blue);
  border: 0;
  width: 50%;
  height: 60px;
  color: white;
  font-size: 20px;
  border-radius: 0px 5px 5px 0px;
}
.search-btn .btn.btn-search:hover {
  background: var(--wc-blue-hover);
}
.search-btn .btn.btn-reset {
  background: black;
  border: 0;
  width: 50%;
  height: 60px;
  color: white;
  font-size: 20px;
  border-radius: 5px 0px 0px 5px;
}
.search-btn .btn.btn-reset:hover {
  background: gray;
}

.slider {
  width: 100%;
  /* 후에 padding: 1.5rem 2rem 으로 수정합니다. 참고바랍니다. */
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.input {
  width: calc(100% - 2rem);
  top: 1rem;
  left: 1rem;
  position: absolute;
  border: none;
  pointer-events: none;
  z-index: 10;
  opacity: 0;
}

.input.one-way {
  width: calc(100% - 2rem);
  top: 1rem;
  left: 1rem !important;
  position: absolute;
  border: none;
  pointer-events: none;
  z-index: 10;
  opacity: 1;
}

.input::-webkit-slider-thumb {
  pointer-events: all;
  /* appearance, background-color는 지워도 됨 */
  width: 2.5rem;
  height: 1.5rem;
}

.track {
  position: relative;
  width: 100%;
  height: 0.5rem;
  background-color: #bdc3c7;
  border-radius: 0.5rem;
}

.tempRange {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0175FF;
  border-radius: 0.5rem;
}

.tempthumb {
  position: absolute;
  top: 2px;
  margin-top: -3px;
  transform: translateY(-0.25rem);
  width: 1rem;
  height: 1rem;
  background-color: #0175FF;
  border-radius: 50%;
}

.phRange {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0175FF;
  border-radius: 0.5rem;
}

.phthumb {
  position: absolute;
  top: 2px;
  margin-top: -3px;
  transform: translateY(-0.25rem);
  width: 1rem;
  height: 1rem;
  background-color: #0175FF;
  border-radius: 50%;
}

.left {
  left: 0;
  margin-left: -8px;
}

.right {
  right: 0;
  margin-right: -8px;
}

.bubble {
  background: white;
  color: black;
  padding: 4px 7px;
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
}

.bubble::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  background: white;
  color: black;
  top: -1px;
  left: 50%;
}`],
})
export class PortalMicrobeuserMicrobeComponent implements OnInit, AfterViewInit {

    public tempRange: ElementRef;
    public tempLeft: ElementRef;
    public tempthumbLeft: ElementRef;
    public tempLeftBubble: ElementRef;
    public tempRight: ElementRef;
    public tempthumbRight: ElementRef;
    public tempRightBubble: ElementRef;

    public phRange: ElementRef
    public phLeft: ElementRef;
    public phthumbLeft: ElementRef;
    public phLeftBubble: ElementRef;
    public phRight: ElementRef;
    public phthumbRight: ElementRef;
    public phRightBubble: ElementRef;

    public culRange: ElementRef;
    public culRangeBubble: ElementRef;

    public allMicrobe: any = [];
    public searchLoad: boolean = false;
    public listloaded: boolean = false;
    public loading = false;
    public list: any = [];
    public log: any = [];
    public microbe: any = [];
    public acate: any = [];
    public activeTab: string = 'isolationInfo';
    public activeMcate: any = [];
    public activeTcate: string = '';
    public activeAcate: string = '';
    public activeLocation: any = [];
    public activeHeritage: string = '';
    public activeType: string = '';
    public activeGrowth: any = [];
    public activeOxygen: string = '';
    public activeCultivation: string = '';
    public activeBio: number = 0;
    public activeCollect: any = [];
    public categories: { [key: number]: boolean } = {};
    public growthCate: boolean = false;

    @Input() item: string = '';
    public searchText: string = '';
    public text: string = '';

    public filter: any = {
        microbe: {
            mcategory: {},
            tcategory: "",
            acategory: {
                ascomycota: "",
                classes: "",
                order: "",
                family: "",
                genus: "",
                species: ""
            },
            growth_desc: {},
            oxygen: "",
            safty: 0,
            phRange: {
                min: 1,
                max: 10
            },
            tempRange: {
                min: 1,
                max: 100
            }
        },
        log: {
            heritage: "",
            type_detail_copy: '',
            // location: "",
            cultivate: "",
            culDate: 30,
            culTemp: 40,
            type_location: {},
            collectDate: {}
        }
    };

    public previousFilter: any = JSON.parse(JSON.stringify(this.filter));

    public previousTempLeftValue: number = 1;
    public previousTempRightValue: number = 100;

    public previousphLeftValue: number = 1;
    public previousphRightValue: number = 10;

    public previousculRightValue: number = 30;

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public search: any = {
        page: 1,
        text: this.item
    };

    private mode = "default";
    constructor(@Inject( Service) public service: Service,@Inject( ActivatedRoute)  private route: ActivatedRoute,@Inject( Router)  private router: Router) { }

    public async ngOnInit() {
        await this.service.init();
        // await this.service.auth.allow();
        this.route.queryParams.subscribe(params => {
            if (params['searchText']) {
                this.searchText = params['searchText']
                this.removeSearchText();
            }
        });

        await this.load();
        // await this.pageLog();
        await this.logData();
        await this.microbData();
        await this.service.render();
    }

    public async reset() {
        this.filter = {
            microbe: {
                mcategory: {},
                tcategory: "",
                acategory: {
                    ascomycota: "",
                    classes: "",
                    order: "",
                    family: "",
                    genus: "",
                    species: ""
                },
                growth_desc: {},
                oxygen: "",
                safty: 0,
                phRange: {
                    min: 1,
                    max: 10
                },
                tempRange: {
                    min: 1,
                    max: 100
                }
            },
            log: {
                heritage: "",
                cultivate: "",
                culDate: 30,
                culTemp: 40,
                type_detail_copy: '',
                collectDate: {},
                type_location: {},
            }
        };
        this.activeTab = 'isolationInfo';
        this.activeMcate = [];
        this.activeTcate = '';
        this.activeAcate = '';
        this.activeLocation = [];
        this.activeHeritage = '';
        this.activeGrowth = [];
        this.activeOxygen = '';
        this.activeCultivation = '';
        this.activeBio = 0;
        this.activeCollect = [];

        await this.load();
        await this.pageLog();
        await this.microbData();
        await this.logData();
    }

    public async setActiveMcate(mcate: string) {
        const index = this.activeMcate.indexOf(mcate);
        if (index !== -1) {
            this.activeMcate.splice(index, 1);
        } else {
            this.activeMcate.push(mcate);
        }
        this.filter.microbe.mcategory[mcate] = !this.filter.microbe.mcategory[mcate];
        if (!this.filter.microbe.mcategory[mcate]) {
            delete this.filter.microbe.mcategory[mcate];
        }
        await this.service.render();
    }
    public async setActiveTcate(tcate: string) {
        this.activeTcate = tcate;
        this.filter.microbe.tcategory = tcate;
        await this.service.render();
    }
    public async setActiveAcate(acate: string, index: number) {
        this.activeAcate = acate;
        // this.filter.microbe.acategory[index === 0 ? 'division' : index === 1 ? 'classes' : index === 2 ? 'order' : index === 3 ? 'genus' : 'species'] = acate;
        this.filter.microbe.acategory[index === 0 ? 'ascomycota' : index === 1 ? 'classes' : index === 2 ? 'order' : index === 3 ? 'family' : index === 4 ? 'genus' : 'species'] = acate;
        // index보다 큰 모든 하위 항목들을 초기화
        const categoryKeys = ['ascomycota', 'classes', 'order', 'family', 'genus', 'species'];
        for (let i = index + 1; i < categoryKeys.length; i++) {
            this.filter.microbe.acategory[categoryKeys[i]] = '';
        }
        await this.service.render();
    }

    public async setActiveHeritage(heritage: string) {
        this.activeHeritage = heritage;
        this.filter.log.heritage = heritage;
        await this.service.render();
    }

    public async setActiveType(type_detail_copy: string) {
        this.activeType = type_detail_copy;
        this.filter.log.type_detail_copy = type_detail_copy;
        await this.service.render();
    }


    public async setSearch() {
        this.searchLoad = !this.searchLoad;
        await this.service.render();
    }

    public async setActiveLocation(location: string) {

        const index = this.activeLocation.indexOf(location);

        if (index !== -1) {
            this.activeLocation.splice(index, 1);
        } else {
            this.activeLocation.push(location);
        }

        this.filter.log.type_location[location] = !this.filter.log.type_location[location]; // 여러 위치를 쉼표로 구분하여 저장

        await this.service.render();
    }
    public async setActiveGrowth(growth: string) {
        const index = this.activeGrowth.indexOf(growth);
        if (index !== -1) {
            this.activeGrowth.splice(index, 1);
        } else {
            this.activeGrowth.push(growth);
        }

        this.filter.microbe.growth_desc[growth] = !this.filter.microbe.growth_desc[growth];
        await this.service.render();
    }
    public async setActiveOxygen(oxygen: string) {
        this.activeOxygen = oxygen;
        this.filter.microbe.oxygen = oxygen;
        await this.service.render();
    }
    public async setActiveCultivation(cultivation: string) {
        this.activeCultivation = cultivation;
        this.filter.log.cultivate = cultivation;
        await this.service.render();
    }

    public async setActiveBio(safty: number) {
        this.activeBio = safty;
        this.filter.microbe.safty = safty;
        await this.service.render();
    }
    public async setActiveCollect(collect: string) {
        const index = this.activeCollect.indexOf(collect);
        if (index !== -1) {
            this.activeCollect.splice(index, 1);
        } else {
            this.activeCollect.push(collect);
        }

        this.filter.log.collectDate[collect] = !this.filter.log.collectDate[collect];
        if (!this.filter.log.collectDate[collect]) {
            delete this.filter.log.collectDate[collect];
        }
        await this.service.render();
    }

    private removeSearchText() {
        this.router.navigate([], {
            queryParams: { searchText: null },
            queryParamsHandling: 'merge'
        });
    }
    public async twoWayRange() {
        this.tempRange = document.getElementById('tempRange');

        this.tempLeft = document.getElementById('tempLeft');
        this.tempthumbLeft = document.getElementById('tempthumbLeft');
        this.tempLeftBubble = document.getElementById('tempLeftBubble')

        this.tempRight = document.getElementById('tempRight');
        this.tempthumbRight = document.getElementById('tempthumbRight');
        this.tempRightBubble = document.getElementById('tempRightBubble')


        this.phRange = document.getElementById('phRange');

        this.phLeft = document.getElementById('phLeft');
        this.phthumbLeft = document.getElementById('phthumbLeft');
        this.phLeftBubble = document.getElementById('phLeftBubble');

        this.phRight = document.getElementById('phRight');
        this.phthumbRight = document.getElementById('phthumbRight');
        this.phRightBubble = document.getElementById('phRightBubble');

        if (this.tempLeft) {
            this.tempLeft.addEventListener('input', this.tempLeftValue.bind(this));
            this.tempLeft.addEventListener('input', () => {
                this.setBubble(this.tempLeft, this.tempLeftBubble);
            });
        }
        if (this.tempRight) {
            this.tempRight.addEventListener('input', this.tempRightValue.bind(this));
            this.tempRight.addEventListener('input', () => {
                this.setBubble(this.tempRight, this.tempRightBubble);
            });
        }
        if (this.tempLeft && this.tempRight) {
            this.tempLeft.value = this.previousTempLeftValue;
            this.tempRight.value = this.previousTempRightValue;

            const tempLeftPercent = ((+this.tempLeft.value - +this.tempLeft.min) / (+this.tempLeft.max - +this.tempLeft.min)) * 100;
            this.tempthumbLeft.style.left = `${tempLeftPercent}%`;
            this.tempRange.style.left = `${tempLeftPercent}%`;

            const tempRightPercent = ((+this.tempRight.value - +this.tempRight.min) / (+this.tempRight.max - +this.tempRight.min)) * 100;
            this.tempthumbRight.style.right = `${100 - tempRightPercent}%`;
            this.tempRange.style.right = `${100 - tempRightPercent}%`;

            this.setBubble(this.tempLeft, this.tempLeftBubble); // Bubble 업데이트
            this.setBubble(this.tempRight, this.tempRightBubble); // Bubble 업데이트
        }

        if (this.phLeft) {
            this.phLeft.addEventListener('input', this.phLeftValue.bind(this));
            this.phLeft.addEventListener('input', () => {
                this.setBubble(this.phLeft, this.phLeftBubble);
            });
        }
        if (this.phRight) {
            this.phRight.addEventListener('input', this.phRightValue.bind(this));
            this.phRight.addEventListener('input', () => {
                this.setBubble(this.phRight, this.phRightBubble);
            })
        }

        if (this.phLeft && this.phRight) {
            this.phLeft.value = this.previousphLeftValue;
            this.phRight.value = this.previousphRightValue;

            const phLeftPercent = ((+this.phLeft.value - +this.phLeft.min) / (+this.phLeft.max - +this.phLeft.min)) * 100;
            this.phthumbLeft.style.left = `${phLeftPercent}%`;
            this.phRange.style.left = `${phLeftPercent}%`;

            const phRightPercent = ((+this.phRight.value - +this.phRight.min) / (+this.phRight.max - +this.phRight.min)) * 100;
            this.phthumbRight.style.right = `${100 - phRightPercent}%`;
            this.phRange.style.right = `${100 - phRightPercent}%`;

            this.setBubble(this.phLeft, this.phLeftBubble); // Bubble 업데이트
            this.setBubble(this.phRight, this.phRightBubble); // Bubble 업데이트
        }
        await this.service.render();
    }
    public async detail(id: string) {
        this.item = id;
        this.loading = !this.loading;
    }
    public async oneWayRange() {
        this.culRange = document.getElementById('culRange');
        this.culRangeBubble = document.getElementById('culRangeBubble');
        if (this.culRange) {
            this.culRange.addEventListener('input', () => {
                this.setBubble(this.culRange, this.culRangeBubble);
            })
            this.culRange.value = this.previousculRightValue;
            const culLeftPercent = ((+this.culRange.value - +this.culRange.min) / (+this.culRange.max - +this.culRange.min)) * 100;
            this.culRange.style.left = `${culLeftPercent}%`;
            this.setBubble(this.culRange, this.culRangeBubble);
        }
    }
    public async setActiveTab(tabName: string) {
        this.activeTab = tabName;
        await this.service.render();

        if (tabName === "optimalGrowth") {
            if (this.tempLeft && this.tempRight) {
                this.tempLeft.value = this.previousTempLeftValue;
                this.tempRight.value = this.previousTempRightValue;
            }
            if (this.phLeft && this.phRight) {
                this.phLeft.value = this.previousphLeftValue;
                this.phRight.value = this.previousphRightValue;
            }
            await this.twoWayRange();
        }
        if (tabName === "cultivationInfo") {
            if (this.culRange) {
                this.culRange.value = this.previousculRightValue;
            }
            await this.oneWayRange();
        }
        await this.service.render();
    }
    public async setBubble(range, bubble) {
        const val = range.value;
        bubble.innerHTML = val;
        if (range.id === 'tempLeft') {
            bubble.style.left = '0%'; // 왼쪽 끝에 고정
        } else if (range.id === 'tempRight') {
            bubble.style.left = '100%'; // 오른쪽 끝에 고정
        }

        if (range.id === 'phLeft') {
            bubble.style.left = '0%'; // 왼쪽 끝에 고정
        } else if (range.id === 'phRight') {
            bubble.style.left = '100%'; // 오른쪽 끝에 고정
        }

        if (range.id === 'culRange') {
            bubble.style.left = '100%';
        }
    }

    public async phLeftValue(e) {
        const value = e.target.value;
        const min = e.target.min;
        const max = e.target.max;
        const rightValue = this.phRight.value;
        this.previousphLeftValue = this.phLeft.value;
        if (+rightValue - +value < 1) {
            e.target.value = +rightValue - 1;
        }

        const percent = ((+e.target.value - +min) / (+max - +min)) * 100;
        this.phthumbLeft.style.left = `${percent}%`;
        this.phRange.style.left = `${percent}%`;
        await this.service.render();
    }
    public async phRightValue(e) {
        const value = e.target.value;
        const min = e.target.min;
        const max = e.target.max;
        const leftValue = this.phLeft.value;
        this.previousphRightValue = this.phRight.value;
        if (+value - +leftValue < 1) {
            e.target.value = +leftValue + 1;
        }
        const percent = ((+e.target.value - +min) / (+max - +min)) * 100;
        this.phthumbRight.style.right = `${100 - percent}%`;
        this.phRange.style.right = `${100 - percent}%`;
        await this.service.render();
    }

    public async tempLeftValue(e) {
        const value = e.target.value;
        const min = e.target.min;
        const max = e.target.max;
        const rightValue = this.tempRight.value;
        this.previousTempLeftValue = this.tempLeft.value;
        if (+rightValue - +value < 10) {
            e.target.value = +rightValue - 10;
        }

        const percent = ((+e.target.value - +min) / (+max - +min)) * 100;
        this.tempthumbLeft.style.left = `${percent}%`;
        this.tempRange.style.left = `${percent}%`;
        await this.service.render();
    }

    public async tempRightValue(e) {
        const value = e.target.value;
        const min = e.target.min;
        const max = e.target.max;
        const leftValue = this.tempLeft.value;
        this.previousTempRightValue = this.tempRight.value;
        if (+value - +leftValue < 10) {
            e.target.value = +leftValue + 10;
        }

        const percent = ((+e.target.value - +min) / (+max - +min)) * 100;
        this.tempthumbRight.style.right = `${100 - percent}%`;
        this.tempRange.style.right = `${100 - percent}%`;
        await this.service.render();
    }

    public async onAreaClick(event: MouseEvent, index: number, item: string): void {
        if (event.target instanceof HTMLInputElement) {
            // input 요소를 직접 클릭한 경우 아무것도 하지 않음
            return;
        }

        await this.setActiveAcate(item, index);
        await this.onCategoryChange(index, item);
    }
    public async pageLoad(p: number) {
        Object.keys(this.filter.microbe.growth_desc).forEach(key => {
            if (!this.filter.microbe.growth_desc[key]) {
                delete this.filter.microbe.growth_desc[key];
            }
        });
        this.load(p);
    }

    public async loadChange(item: boolean) {
        this.loading = !this.loading;

        if (item != true) {
            const microbe = this.allMicrobe.find(m => m.name === item);

            if (microbe) {
                this.item = microbe.id;
            }
        }

        await this.service.render();
    }

    public async pageLog(page: number = 1) {
        const { code, data } = await wiz.call("logData", {
            page: page,
            checked: JSON.stringify(this.filter.log)
        });

        if (code != 200) return
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.listloaded = true;
        await this.service.render();
    }

    public async load(page: number = 1) {
        this.listloaded = false;

        let { code, data } = await wiz.call("load", {
            page: page,
            text: this.searchText,
            checked: JSON.stringify(this.filter.microbe),
            mode: this.mode
        });
        if (code != 200) return;
        let { rows, lastpage, all_microbe } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.text = this.searchText;
        this.allMicrobe = all_microbe;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.listloaded = true;
        await this.service.render();
    }

    public getCategoryKey(index: number): string {
        if (this.filter.microbe.acategory[['ascomycota', 'classes', 'order', 'family', 'genus', 'species'][index] || null] == null) {
            return ['ascomycota', 'class', 'order', 'family', 'genus', 'species'][index] || null;
        }
        else {
            return (['문(Ascomycota)', '강(Class)', '목(Order)', '과(Family)', '속(Genus)', '종(Species)'][index] || null);
        }
    }

    public async onCategoryChange(index: number, item: string) {
        this.filter.microbe.acategory[this.getCategoryKey(index)] = item;

        const categoryKeys = Object.keys(this.categories);
        for (let key of categoryKeys) {
            const keyIndex = parseInt(key);
            if (keyIndex > index) {
                this.filter.microbe.acategory[this.getCategoryKey(keyIndex)] = "";
            }
        }


        this.categories[index] = false;
        if (index + 1 < this.categories.length) {
            this.categories[index + 1] = true;
        }
        if (index === 0) { // 1차 항목이 변경되었을 때
            this.loadCategory('a_cate_1', item, index);
        } else if (index === 1) { // 2차 항목이 변경되었을 때
            this.loadCategory('a_cate_2', item, index);
        } else if (index === 2) {
            this.loadCategory('a_cate_3', item, index);
        } else if (index === 3) {
            this.loadCategory('a_cate_4', item, index);
        } else if (index === 4) {
            this.loadCategory('a_cate_5', item, index);
        } else if (index === 5) {
            this.loadCategory('a_cate_6', item, index);
        }


        await this.toggleCategory(index + 1);
        await this.service.render();
    }

    public async loadCategory(category: string, value: string, index: number) {
        const { code, data } = await wiz.call("loadCategoryData", {
            category: category,
            value: value
        });
        if (code === 200 && Array.isArray(data)) {
            if (data.length != 0) {
                this.microbe.a_cate[index + 1] = data; // 받은 데이터를 다음 카테고리 목록에 할당
                this.microbe.a_cate[index + 1].sort();
            }
        }
        await this.service.render(); // UI 업데이트
    }
    public shouldShowCategory(index: number): boolean {
        return this.categories[index];
    }

    public getCategoryLabel(): string {
        const categoryKeys = ['ascomycota', 'classes', 'order', 'family', 'genus', 'species'];

        let label = '';

        for (let i = 0; i < categoryKeys.length; i++) {
            if (this.filter.microbe.acategory[categoryKeys[i]]) {
                if (i > 0) label += ' > ';
                label += this.filter.microbe.acategory[categoryKeys[i]];
            } else {
                break;
            }
        }

        return label || 'ascomycota';
    }

    public async toggleCategory(index: number) {
        if (this.filter.microbe.acategory[this.getCategoryKey(index)]) {
            this.categories[index] = !this.categories[index];
        } else {
            this.categories[index] = !this.categories[index];
        }

        for (let i = 0; i < this.categories.length; i++) {
            if (i !== index) {
                this.categories[i] = false;
            }
        }

        await this.service.render();
    }

    public async showGrowth() {
        this.growthCate = !this.growthCate;
        await this.service.render();
    }

    public async checked() {
        await this.service.render();
    }

    // data_microbe 테이블에서 필터링 필드 값 추출
    public async microbData() {
        const { code, data } = await wiz.call("microbData");
        if (code != 200) return;
        this.microbe = data;
        let fieldValues: { [key: string]: string[][] } = {};

        const fieldsWithSubFields = {
            'a_cate': 6,
            'm_cate': 2,
            't_cate': 2,
        };

        const otherFields = [
            'growth_desc',
            'growth_oxygen',
            'feature_biosafty'
        ];

        const growthSet = new Set();

        otherFields.forEach(field => {
            fieldValues[field] = [];
        });

        Object.keys(fieldsWithSubFields).forEach(field => {
            fieldValues[field] = [];
            for (let i = 1; i <= fieldsWithSubFields[field]; i++) {
                fieldValues[field].push([]);
            }
        });

        this.microbe.forEach(item => {
            Object.entries(fieldsWithSubFields).forEach(([field, subFieldCount]) => {
                for (let i = 1; i <= subFieldCount; i++) {
                    const key = `${field}_${i}`;
                    if (item[key] && !fieldValues[field][i - 1].includes(item[key])) {
                        fieldValues[field][i - 1].push(item[key]);
                    }
                }
            });
            if (item.growth_desc) {
                item.growth_desc.forEach(growth => {
                    growthSet.add(growth);
                });
            }
            otherFields.forEach(field => {
                if (item[field] && !fieldValues[field].includes(item[field])) {
                    fieldValues[field].push(item[field]);
                }
            });
        });
        if (fieldValues['a_cate']) {
            fieldValues['a_cate'].forEach(subFieldArray => {
                subFieldArray.sort((a, b) => a.trim().localeCompare(b.trim()));
            });
        }
        this.microbe = fieldValues;
        this.microbe.growth_desc = Array.from(growthSet);
        this.microbe.growth_desc.sort();
        if (this.microbe.m_cate.length === 2 &&
            Array.isArray(this.microbe.m_cate[0]) &&
            Array.isArray(this.microbe.m_cate[1])) {
            const parents = this.microbe.m_cate[0];
            const children = this.microbe.m_cate[1];
            const newMCateStructure = parents.map(parent => {
                return { parent: parent, children: [...children] };
            });

            this.microbe.m_cate = newMCateStructure;
        }
        if (this.microbe.t_cate.length === 2 &&
            Array.isArray(this.microbe.t_cate[0]) &&
            Array.isArray(this.microbe.t_cate[1])) {
            const parents = this.microbe.t_cate[0];
            const children = this.microbe.t_cate[1];
            const newMCateStructure = parents.map(parent => {
                return { parent: parent, children: [...children] };
            });

            this.microbe.t_cate = newMCateStructure;
        }
        await this.service.render();
    }

    // data_log 데이터에서 필터링 필드값 추출
    public async logData() {
        const { code, data } = await wiz.call("logData");
        if (code != 200) return;
        this.log = data
        let fieldValues: { [key: string]: string[][] } = {};
        const fields = [
            'type_heritage',
            'type_location',
            'cultivate_desc',
            'collect_date',
            'type_detail_copy'
        ]
        const locationSet = new Set();

        fields.forEach(field => {
            fieldValues[field] = [];
        })

        this.log.forEach(item => {
            if (item.type_location) {
                item.type_location.forEach(location => {
                    locationSet.add(location);
                });
            }
            fields.forEach(field => {
                if (item[field] && !fieldValues[field].includes(item[field])) {
                    fieldValues[field].push(item[field]);
                }
            });
        });

        this.log = fieldValues;
        this.log.collect_date = this.log.collect_date.map(dateString => {
            return dateString.split(' ')[0]; // 공백으로 분리하고 첫 번째 부분(날짜)을 반환
        });

        this.log.type_location = ['외부', '완충 공간 1', '완충 공간 2', '완충 공간 3', '연도', '묘실'];
        await this.service.render();
    }

    public async update() {
        if (JSON.stringify(this.previousFilter.microbe) !== JSON.stringify(this.filter.microbe)) {
            await this.load();
        }
        if (JSON.stringify(this.previousFilter.log) !== JSON.stringify(this.filter.log)) {
            await this.pageLog();
        }
        this.previousFilter = JSON.parse(JSON.stringify(this.filter));
    }

    public async pageMove(page: number) {
        // await this.load(page);
        await this.pageLog(page);
    }
}

export default PortalMicrobeuserMicrobeComponent;