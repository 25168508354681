import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('search');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-search',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/search/view.scss */
@charset "UTF-8";
.search-container .input-group .form-control {
  height: 72px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 24px;
}
.search-container .input-group .btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 80px;
  font-size: 32px;
  padding: 12px;
}
.search-container .card-body {
  display: flex;
  align-items: center;
  height: 72px;
  font-size: 18px;
}
.search-container .card-body .title {
  font-family: "main-eb";
}

.row-child {
  margin: 0;
}
.row-child > * {
  padding: 0 16px;
}
.row-child > *:first-child {
  padding-left: 0;
}
.row-child > *:last-child {
  padding-right: 0;
}

.container {
  height: max-content;
}
.container .card {
  border: none;
  background: none;
}
.container .card .card-link {
  border-radius: 0 !important;
}
.container .card:hover {
  opacity: 0.5;
  box-shadow: none !important;
}

.cover-image {
  height: 240px;
  background: #666 no-repeat center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover-image .btn-container {
  display: none;
}
.cover-image:hover .btn-container {
  display: block;
}

.card-covered {
  height: 180px;
  border-radius: 0;
  background: #666 no-repeat center/cover;
}

.form-label {
  font-size: 16px;
  font-family: "MAIN-M";
}

.filter-area {
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
  width: auto;
  height: 40px;
  align-items: center;
}

.filter-area.active {
  background: #E7EEF8 0% 0% no-repeat padding-box;
  border: 1px solid #2547F4;
  border-radius: 10px;
  opacity: 1;
  width: auto;
  height: 40px;
  align-items: center;
}

.form-check-label {
  font-size: 16px;
  font-family: "MAIN-N";
  color: #5B5D6B;
  cursor: pointer;
}

.form-check-input {
  border: 1.5px solid #5B5D6B;
  border-radius: 2px;
  margin-left: 10px;
}

input[type=checkbox]:checked {
  background-color: #4F40F1;
}

input[type=checkbox]:checked + span.form-check-label {
  color: #4F40F1;
}

input[type=checkbox]:checked + span.form-check-label + span.ml-auto.mr-2 {
  color: #4F40F1;
}

input[type=radio] {
  width: 20px;
  /* 원 크기 */
  height: 20px;
  border-radius: 50%;
  /* 원형 */
  border: 2px solid #9A9A9A;
  /* 테두리 색상 */
  background-color: white;
  /* 체크됐을 때의 스타일 */
}
input[type=radio]:checked {
  border: 2px solid #4F40F1;
  background-color: #4F40F1;
  /* 선택된 색상 */
}

.btn-cate {
  border: none;
  margin-left: auto;
  position: relative;
}

.d-flex {
  margin-top: 10px;
}

.bottom-line {
  border-bottom: 1px solid #BBCCEA;
}

select.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background: url("/assets/main/down.png") no-repeat right 12px center;
  background-size: 14px;
  padding-right: 30px;
  border: none;
}

.nav-tabs {
  list-style-type: none;
  padding: 0;
  display: flex;
  position: relative;
  top: 36px;
}

.nav-item {
  width: 100%;
  font-size: 16px;
  border-bottom: 1px solid #707070;
}

.nav-item.active {
  color: #2547F4;
  background-color: white;
  /* 활성화된 탭의 배경색 */
  border-bottom: 3px solid #2547F4;
}

.nav-link {
  display: block;
  text-align: center;
  text-decoration: none;
  border: none;
}

.search-btn {
  width: 100%;
  flex: 0 0 auto;
}
.search-btn .btn {
  font-family: "main-eb";
  cursor: pointer !important;
}
.search-btn .btn.btn-search {
  background: var(--wc-blue);
  border: 0;
  width: 50%;
  height: 60px;
  color: white;
  font-size: 20px;
  border-radius: 0px 5px 5px 0px;
}
.search-btn .btn.btn-search:hover {
  background: var(--wc-blue-hover);
}
.search-btn .btn.btn-reset {
  background: black;
  border: 0;
  width: 50%;
  height: 60px;
  color: white;
  font-size: 20px;
  border-radius: 5px 0px 0px 5px;
}
.search-btn .btn.btn-reset:hover {
  background: gray;
}

.slider {
  width: 100%;
  height: 100%;
  /* 후에 padding: 1.5rem 2rem 으로 수정합니다. 참고바랍니다. */
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.input {
  width: calc(100% - 2rem);
  top: 1rem;
  left: 1rem;
  position: absolute;
  border: none;
  pointer-events: none;
  z-index: 10;
  appearance: none;
  opacity: 0.5;
}

.input::-webkit-slider-thumb {
  pointer-events: all;
  /* appearance, background-color는 지워도 됨 */
  width: 2.5rem;
  height: 1.5rem;
}`],
})
export class SearchComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        await this.load();
        await this.logData();
        await this.microbData();
        await this.service.render();
    }

    public listloaded: boolean = false;
    public list: any = [];
    public log: any = [];
    public microbe: any = [];
    public acate: any = [];
    public activeTab: string = 'isolation';
    public categories: { [key: number]: boolean } = {};
    public filter: any = {
        microbe: {
            mcategory: "",
            tcategory: "",
            acategory: {
                one: "",
                two: "",
                three: "",
                four: "",
                five: "",
                six: ""
            },
            growth_desc: {},
            oxygen: "",
            phRange: {
                min: 0,
                max: 10
            },
            tempRange: {
                min: 0,
                max: 100
            }
        },
        log: {
            heritage: "",
            location: "",
            cultivate: "",
            culDate: {
                min: 0,
                max: 10
            },
        }
    };

    public previousFilter: any = JSON.parse(JSON.stringify(this.filter));


    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public search: any = {
        page: 1,
        text: ''
    };

    private mode = "default";

    public async reset() {
        this.filter = {
            microbe: {
                mcategory: "",
                tcategory: "",
                acategory: {
                    one: "",
                    two: "",
                    three: "",
                    four: "",
                    five: "",
                    six: ""
                },
                growth_desc: {},
                oxygen: "",
                phRange: {
                    min: 0,
                    max: 10
                },
                tempRange: {
                    min: 0,
                    max: 100
                }
            },
            log: {
                heritage: "",
                location: "",
                cultivate: "",
                culDate: {
                    min: 0,
                    max: 10
                },
            }
        };

        await this.update();
    }
    public async update() {
        console.log(this.filter);
        if (JSON.stringify(this.previousFilter.microbe) !== JSON.stringify(this.filter.microbe)) {
            await this.pageLoad();
        }
        if (JSON.stringify(this.previousFilter.log) !== JSON.stringify(this.filter.log)) {
            await this.pageLog();
        }
        this.previousFilter = JSON.parse(JSON.stringify(this.filter));
    }

    public onAreaClick(event: MouseEvent, index: number, item: string): void {
        if (event.target instanceof HTMLInputElement) {
            // input 요소를 직접 클릭한 경우 아무것도 하지 않음
            return;
        }
        
        this.onCategoryChange(index, item);
    }
    public async pageLoad(p: number) {
        Object.keys(this.filter.microbe.growth_desc).forEach(key => {
            if (!this.filter.microbe.growth_desc[key]) {
                delete this.filter.microbe.growth_desc[key];
            }
        });
        this.load(p);
    }
    public async pageLog(page: number = 1) {
        const { code, data } = await wiz.call("logData", {
            page: page,
            checked: JSON.stringify(this.filter.log)
        });

        if (code != 200) return
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.listloaded = true;
        await this.service.render();
    }

    public async load(page: number = 1) {
        this.listloaded = false;
        let { code, data } = await wiz.call("load", {
            page: page,
            text: this.search.text,
            checked: JSON.stringify(this.filter.microbe),
            mode: this.mode
        });
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.listloaded = true;
        await this.service.render();
    }

    public getCategoryKey(index: number): string {
        return ['one', 'two', 'three', 'four', 'five', 'six'][index] || null;
    }

    public async onCategoryChange(index: number, item: string) {
        this.filter.microbe.acategory[this.getCategoryKey(index)] = item;
        
        const categoryKeys = Object.keys(this.categories);
        for (let key of categoryKeys) {
            const keyIndex = parseInt(key);
            if (keyIndex > index) {
                this.filter.microbe.acategory[this.getCategoryKey(keyIndex)] = "";
            }
        }


        this.categories[index] = false;
        if (index + 1 < this.categories.length) {
            this.categories[index + 1] = true;
        }
        if (index === 0) { // 1차 항목이 변경되었을 때
            this.loadCategory('a_cate_1', item, index);
        } else if (index === 1) { // 2차 항목이 변경되었을 때
            this.loadCategory('a_cate_2', item, index);
        } else if (index === 2) {
            this.loadCategory('a_cate_3', item, index);
        } else if (index === 3) {
            this.loadCategory('a_cate_4', item, index);
        } else if (index === 4) {
            this.loadCategory('a_cate_5', item, index);
        } else if (index === 5) {
            this.loadCategory('a_cate_6', item, index);
        }

        await this.service.render();
    }

    public async loadCategory(category: string, value: string, index: number) {
        const { code, data } = await wiz.call("loadCategoryData", {
            category: category,
            value: value
        });
        if (code === 200 && Array.isArray(data)) {
            this.microbe.a_cate[index + 1] = data; // 받은 데이터를 다음 카테고리 목록에 할당
        }
        await this.service.render(); // UI 업데이트
    }
    public shouldShowCategory(index: number): boolean {
        return this.categories[index];
    }

    public getCategoryLabel(): string {
        const categoryKeys = ['one', 'two', 'three', 'four', 'five', 'six'];
        let label = '';

        for (let i = 0; i < categoryKeys.length; i++) {
            if (this.filter.microbe.acategory[categoryKeys[i]]) {
                if (i > 0) label += ' > ';
                label += this.filter.microbe.acategory[categoryKeys[i]];
            } else {
                break;
            }
        }

        return label || '1차 항목';
    }

    public async toggleCategory(index: number) {
        if (this.filter.microbe.acategory[this.getCategoryKey(index)]) {
            this.categories[index] = !this.categories[index];
        } else {
            this.categories[index] = !this.categories[index];
        }

        for (let i = 0; i < this.categories.length; i++) {
            if (i !== index) {
                this.categories[i] = false;
            }
        }

        await this.service.render();
    }
    public async checked() {
        await this.service.render();
    }

    // data_microbe 테이블에서 필터링 필드 값 추출
    public async microbData() {
        const { code, data } = await wiz.call("microbData");
        if (code != 200) return;
        this.microbe = data;
        let fieldValues: { [key: string]: string[][] } = {};

        const fieldsWithSubFields = {
            'a_cate': 6,
            'm_cate': 2,
            't_cate': 2,
        };

        const otherFields = [
            'growth_desc',
            'growth_oxygen',
            'feature_biosafty'
        ];

        const growthSet = new Set();

        otherFields.forEach(field => {
            fieldValues[field] = [];
        });

        Object.keys(fieldsWithSubFields).forEach(field => {
            fieldValues[field] = [];
            for (let i = 1; i <= fieldsWithSubFields[field]; i++) {
                fieldValues[field].push([]);
            }
        });

        this.microbe.forEach(item => {
            Object.entries(fieldsWithSubFields).forEach(([field, subFieldCount]) => {
                for (let i = 1; i <= subFieldCount; i++) {
                    const key = `${field}_${i}`;
                    if (item[key] && !fieldValues[field][i - 1].includes(item[key])) {
                        fieldValues[field][i - 1].push(item[key]);
                    }
                }
            });
            if (item.growth_desc) {
                item.growth_desc.forEach(growth => {
                    growthSet.add(growth);
                });
            }
            otherFields.forEach(field => {
                if (item[field] && !fieldValues[field].includes(item[field])) {
                    fieldValues[field].push(item[field]);
                }
            });
        });
        this.microbe = fieldValues;
        this.microbe.growth_desc = Array.from(growthSet);
        if (this.microbe.m_cate.length === 2 &&
            Array.isArray(this.microbe.m_cate[0]) &&
            Array.isArray(this.microbe.m_cate[1])) {
            const parents = this.microbe.m_cate[0];
            const children = this.microbe.m_cate[1];
            const newMCateStructure = parents.map(parent => {
                return { parent: parent, children: [...children] };
            });

            this.microbe.m_cate = newMCateStructure;
        }
        if (this.microbe.t_cate.length === 2 &&
            Array.isArray(this.microbe.t_cate[0]) &&
            Array.isArray(this.microbe.t_cate[1])) {
            const parents = this.microbe.t_cate[0];
            const children = this.microbe.t_cate[1];
            const newMCateStructure = parents.map(parent => {
                return { parent: parent, children: [...children] };
            });

            this.microbe.t_cate = newMCateStructure;
        }
        await this.service.render();
    }

    public async setActiveTab(tabName: string) {
        this.activeTab = tabName;
        await this.service.render();
    }

    // data_log 데이터에서 필터링 필드값 추출
    public async logData() {
        const { code, data } = await wiz.call("logData");
        if (code != 200) return;
        this.log = data
        let fieldValues: { [key: string]: string[][] } = {};
        const fields = [
            'type_heritage',
            'type_location',
            'cultivate_desc'
        ]
        const locationSet = new Set();

        fields.forEach(field => {
            fieldValues[field] = [];
        })

        this.log.forEach(item => {
            if (item.type_location) {
                item.type_location.forEach(location => {
                    locationSet.add(location);
                });
            }
            fields.forEach(field => {
                if (item[field] && !fieldValues[field].includes(item[field])) {
                    fieldValues[field].push(item[field]);
                }
            });
        });

        this.log = fieldValues;
        this.log.type_location = Array.from(locationSet);
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }
}

export default SearchComponent;